import React from "react";
import {
  Jumbotron,
  JumbotronContainer,
} from "../Scribi-Components/ScribiComponents";
import AuthBlock from "../AuthenticationBlock/AuthBlock";

const JumboSplash = () => {

  return (
    <JumbotronContainer>
      <Jumbotron>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/scribi-backend.appspot.com/o/public%2Fsplash-logo.png?alt=media&token=f47bc348-5f10-4b8e-b4b8-6bbc801b135f"
          alt="Scribi Logo"
          className="logo"
        />
        <img
          src="https://firebasestorage.googleapis.com/v0/b/scribi-backend.appspot.com/o/public%2Fsplash-overlay.png?alt=media&token=1796ab9b-42ea-45f1-9448-022f8345c4ce"
          alt="Scribi Logo"
          className="overlay"
        />
        <AuthBlock />
      </Jumbotron>
    </JumbotronContainer>
  );
};

export default JumboSplash;
