import React from "react";
import { Scribi } from "../../utilities/api/store/store";
import { IconButton } from "../../Scribi-Components/ScribiComponents";
import Icons from "../../Icons/Icons";

const MSNavFooter = () => {
  const { location } = React.useContext(Scribi);
  return (
    <div
      style={{
        padding: "5px",
        display: "flex",
        width: "100%",
        boxSizing: "border-box",
      }}
    >
      <IconButton onClick={() => console.log(location)} size={15}>
        <Icons.AddIcon />
      </IconButton>
    </div>
  );
};

export default MSNavFooter;
