import React, { useContext } from "react";
import Icons from "../Icons/Icons";
import { BodyContainer, NavItem } from "../Scribi-Components/ScribiComponents";
import Catalog from "./Catalog/CatalogNav";
import { Route, Routes, useNavigate, useSearchParams } from "react-router-dom";
import { Scribi } from "../utilities/api/store/store";
import MSNav from "../Project/Nav/MSNav";
import MSNavFooter from "../Project/Nav/MSNavFooter";

const NavMap = [
  {
    title: "Calendar",
    nav: "/",
    icon: Icons.CalendarIcon,
    expand: false,
    contextClick: null,
  },
  {
    title: "Projects",
    nav: "/projects",
    icon: Icons.ProjectsIcon,
    expand: false,
    contextClick: null,
  },
  {
    title: "Ideas",
    nav: null,
    icon: Icons.IdeaIcon,
    expand: true,
    contextClick: null,
  },
  {
    title: "Catalog",
    nav: null,
    icon: Icons.CatalogIcon,
    expand: true,
    contextClick: null,
  },
  {
    title: "Export",
    nav: "/export",
    icon: Icons.ExportIcon,
    expand: false,
    contextClick: null,
  },
  {
    title: "Templates",
    nav: null,
    icon: Icons.TemplatesIcon,
    expand: true,
    contextClick: null,
  },
  {
    title: "Social",
    nav: "/social",
    icon: Icons.SocialIcon,
    expand: true,
    contextClick: null,
  },
];

const ListComponents = {
  Catalog: Catalog,
};

const NavLeft = () => {
  const { navigate, location } = useContext(Scribi);
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  return (
    <>
      <BodyContainer dark>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            padding: "10px",
            gap: location.pathname === "/project" ? "0px" : "5px",
          }}
        >
          <Routes>
            <Route path="/project" element={<MSNav />} />
            <Route path="*" element={<NavItems />} />
          </Routes>
        </div>
      </BodyContainer>
      <Routes>
        <Route path="/project" element={<MSNavFooter />} />
      </Routes>
    </>
  );
};

export default NavLeft;

function NavItems() {
  return (
    <>
      {NavMap.map((item, index) => {
        return <RenderNavItem item={item} key={index} />;
      })}
    </>
  );
}

function RenderNavItem({ item }) {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  function RenderIcon() {
    if (item.icon) {
      return <item.icon />;
    }
  }

  function RenderListComponent() {
    let Component = ListComponents[item.title];
    return <Component />;
  }
  return (
    <>
      <NavItem>
        {item.expand && (
          <button className="toggle" onClick={() => setOpen(!open)}>
            {open ? (
              <Icons.ToggleOpenIconOpened />
            ) : (
              <Icons.ToggleOpenIconClosed open={false} />
            )}
          </button>
        )}
        <div className="icon">
          <RenderIcon />
        </div>
        <label
          className="title"
          onClick={() => {
            if (item.nav) {
              navigate(item.nav);
            } else {
              setOpen(!open);
            }
          }}
        >
          {item.title}
        </label>
      </NavItem>
      {open && RenderListComponent()}
    </>
  );
}
