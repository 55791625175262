import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/pro-duotone-svg-icons";

export const SearchContainer = styled.div`
  border-radius: 5px;
  border: 1px solid gray;
  background-color: white;
  height: 100%;
  margin-top: auto;
  margin-bottom: auto;
  grid-column: 2;
  padding: 5px;
  width: 100%;
  display: flex;
  flex-wrap: no-wrap;
`;

export const SearchInput = styled.input`
  padding: 5px;
  box-sizing: border-box;
  border: none;
  background-color: transparent;
  padding: 0px;
  width: 100%;

  &:focus {
    outline: none;
  }
`;

export const SearchButton = styled.button`
  border: none;
  background-color: transparent;
  padding: 0px;
  svg {
    fill: black;
    color: black;
  }
`;

const Search = () => {
  const [search, setSearch] = React.useState("");

  const handleSearch = () => {};
  return (
    <SearchContainer>
      <SearchInput
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        placeholder="Search"
      />
      <SearchButton onClick={handleSearch}>
        <FontAwesomeIcon icon={faMagnifyingGlass} />
      </SearchButton>
    </SearchContainer>
  );
};

export default Search;
