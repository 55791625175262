/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  ActionButton,
  BodyContainer,
  CancelButton,
  ConfirmButton,
  FormContainer,
  FormInput,
  FormLabel,
  FormRow,
  FormSection,
  FormTitle,
  HrDivider,
} from "../Scribi-Components/ScribiComponents";
import { Scribi } from "../utilities/api/store/store";
import ScribiRestService from "../utilities/firestore/ScribiRestService";
import { debounce } from "lodash";

const UserAccount = () => {
  const { user, settings } = useContext(Scribi);
  const [newDisplayName, setNewDisplayName] = useState(settings.displayName);
  const [updateDisplayName, setUpdateDisplayName] = useState(false);
  const [displayNameValid, setDisplayNameValid] = useState(false);
  const [displayNameMessage, setDisplayNameMessage] = useState(null);
  const [changingDisplayName, setChangingDisplayName] = useState(false);

  useEffect(() => {
    setNewDisplayName(settings.displayName);
  }, [settings.displayName]);

  const debouncedCheckDisplayName = useRef(
    debounce(async (nameToCheck, originalName) => {
      if (
        nameToCheck.trim().toLowerCase() !== originalName.trim().toLowerCase()
      ) {
        try {
          setDisplayNameMessage("Checking...");
          const isAvailable = await ScribiRestService.checkDisplayName(
            nameToCheck
          );
          if (isAvailable) {
            setDisplayNameMessage("This display name is available!");
            setDisplayNameValid(true);
          } else {
            setDisplayNameMessage("This display name is not available.");
            setDisplayNameValid(false);
          }
        } catch (error) {
          // Handle error
          console.error("Error checking display name:", error);
          setDisplayNameMessage("Error occurred");
        }
      } else {
        setDisplayNameMessage("This is your current display name.");
        setDisplayNameValid(true);
      }
    }, 2000)
  ).current;

  useEffect(() => {
    if (changingDisplayName && settings.displayName && newDisplayName) {
      debouncedCheckDisplayName(newDisplayName, settings.displayName);
    }
  }, [newDisplayName, settings.displayName, debouncedCheckDisplayName]);

  const handleSaveDisplayName = async (newDisplayName) => {
    const res = await ScribiRestService.updateDocument("users", {
      ...settings,
      displayName: newDisplayName,
      displayNameLowerCase: newDisplayName.toLowerCase(),
    });
    if (res.isUpdated) {
      setChangingDisplayName(false);
      setUpdateDisplayName(false);
      setDisplayNameMessage(null);
    } else {
      setDisplayNameMessage("An error ocurred, please try again.");
    }
  };

  return (
    <BodyContainer>
      <FormContainer>
        <FormTitle>Manage Account:</FormTitle>
        <HrDivider />
        <FormSection>
          <FormRow
            style={{ alignItems: updateDisplayName ? "flex-start" : "center" }}
          >
            <FormLabel style={{ padding: updateDisplayName ? "5px" : "0px" }}>
              Display Name:
            </FormLabel>
            {updateDisplayName ? (
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "300px 100px 100px",
                  gap: "10px",
                }}
              >
                <FormInput
                  type="text"
                  value={newDisplayName}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    const filteredValue = newValue.replace(
                      /[^a-zA-Z0-9-_]/g,
                      ""
                    ); // Remove special characters except - and _
                    setDisplayNameValid(false);
                    setChangingDisplayName(true);
                    setDisplayNameMessage("Typing...");
                    setNewDisplayName(filteredValue);
                  }}
                />

                <ConfirmButton
                  onClick={() => {
                    setUpdateDisplayName(false);
                    setDisplayNameValid(false);
                    handleSaveDisplayName(newDisplayName);
                  }}
                  disabled={!displayNameValid}
                  style={{ opacity: displayNameValid ? 1 : 0.5 }}
                >
                  Save
                </ConfirmButton>
                <CancelButton
                  onClick={() => {
                    setUpdateDisplayName(false);
                    setNewDisplayName(settings.displayName);
                  }}
                >
                  Cancel
                </CancelButton>
                {displayNameMessage && (
                  <label
                    style={{
                      gridColumn: "1 / span 3",
                      color: displayNameValid ? "aquamarine" : "orange",
                    }}
                  >
                    {displayNameMessage}
                  </label>
                )}
              </div>
            ) : (
              <>
                <FormLabel>{settings.displayName}</FormLabel>
                <ActionButton onClick={() => setUpdateDisplayName(true)}>
                  Edit
                </ActionButton>
              </>
            )}
          </FormRow>
        </FormSection>
        <FormRow justifyContent="flex-end">
          <ConfirmButton>Save</ConfirmButton>
          <CancelButton>Cancel</CancelButton>
        </FormRow>
      </FormContainer>
    </BodyContainer>
  );
};

export default UserAccount;
