import React, { useEffect } from "react";
import { ActionButton } from "../../../Scribi-Components/ScribiComponents";

const initGoogleAPI = () => {
  // Initialize the Google API client
  window.gapi.load("client:auth2", () => {
    window.gapi.client.init({
      apiKey: process.env.REACT_APP_API_KEY,
      clientId: process.env.REACT_APP_GAPI_CLIENT_ID,
      scope: "https://www.googleapis.com/auth/calendar",
    });
  });
};

const requestCalendarAccess = () => {
  const authInstance = window.gapi.auth2.getAuthInstance();

  authInstance
    .signIn()
    .then((res) => {
      // User gave permission
      // You can now access Google Calendar API
    })
    .catch((err) => {
      // User denied permission
      console.error(err);
    });
};

const CalendarAccessButton = () => {
  useEffect(() => {
    initGoogleAPI();
  }, []);

  return (
    <ActionButton onClick={requestCalendarAccess}>
      Grant Calendar Access
    </ActionButton>
  );
};

export default CalendarAccessButton;
