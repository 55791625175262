/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import styled from "styled-components";
import Icons from "../Icons/Icons";
import { Scribi } from "../utilities/api/store/store";
import {
  ActionButton,
  CalendarButton,
  CalendarContainer,
  CalendarDayDay,
  CalendarDayLabel,
  CalendarDaysContainer,
  CalendarEvent,
  CalendarHeaderContainer,
  CalendarMonthDay,
  CalendarWeekDay,
  ConfirmButton,
  MonthColumn,
  MonthColumnDayColumn,
  MonthColumnWeekRow,
  MonthGrid,
  TimeSlot,
} from "../Scribi-Components/ScribiComponents";
import { useNavigate } from "react-router-dom";
import CalendarAccessButton from "../utilities/api/google api/calendarAccess";

const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const fullDays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const user = { timePref: "am/pm" };
function createTimeArray(timePref) {
  const timeArray = [""];
  for (let hour = 0; hour <= 23; hour++) {
    for (let minute = 0; minute < 60; minute += 15) {
      let formattedHour = hour;
      let suffix = "";

      if (timePref === "am/pm" || timePref == null) {
        if (hour === 0) {
          formattedHour = 12;
          suffix = "am";
        } else if (hour < 12) {
          formattedHour = hour;
          suffix = "am";
        } else if (hour === 12) {
          formattedHour = hour;
          suffix = "pm";
        } else {
          formattedHour = hour - 12;
          suffix = "pm";
        }
      } else {
        formattedHour = hour.toString().padStart(2, "0");
      }

      const minuteString = minute.toString().padStart(2, "0");
      const timeString = `${formattedHour}:${minuteString}${suffix}`;
      timeArray.push(timeString);
    }
  }
  return timeArray;
}

const rows = createTimeArray(user.timePref);

const TaskGrid = styled.div`
  display: grid;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  grid-template-rows: 50px repeat(96, 30px);
  width: 100%;
`;

const Home = () => {
  const { calendarView } = React.useContext(Scribi);
  return (
    <CalendarContainer>
      <CalendarToolbar />
      <CalendarDays />
      {calendarView === "month" && <RenderMonth />}
      {calendarView === "week" && <RenderWeek />}
      {calendarView === "day" && <RenderDay />}
    </CalendarContainer>
  );
};

export default Home;

const CalendarToolbar = () => {
  const navigate = useNavigate();

  const {
    calendarDate,
    setCalendarDate,
    today,
    setCalendarView,
    calendarView,
  } = React.useContext(Scribi);

  return (
    <CalendarHeaderContainer>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "20px",
          width: "100%",
        }}
      >
        <button
          onClick={() => {
            const newDate = new Date(calendarDate);
            if (calendarView === "month") {
              newDate.setMonth(newDate.getMonth() - 1);
            } else if (calendarView === "week") {
              newDate.setDate(newDate.getDate() - 7);
            } else if (calendarView === "day") {
              newDate.setDate(newDate.getDate() - 1);
            }
            setCalendarDate(newDate);
          }}
        >
          <Icons.ChevronLeftIcon />
        </button>

        {calendarView === "month" && (
          <label style={{ minWidth: "320px", textAlign: "center" }}>{`${
            months[calendarDate?.getMonth()]
          } ${calendarDate?.getFullYear()}`}</label>
        )}
        {calendarView === "week" && (
          <label style={{ minWidth: "320px", textAlign: "center" }}>
            {(() => {
              const startOfWeek = new Date(calendarDate);
              startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());
              const endOfWeek = new Date(startOfWeek);
              endOfWeek.setDate(endOfWeek.getDate() + 6);

              const monthStart = months[startOfWeek.getMonth()];
              const dateStart = startOfWeek.getDate();
              const monthEnd = months[endOfWeek.getMonth()];
              const dateEnd = endOfWeek.getDate();
              const year = endOfWeek.getFullYear();

              return `${monthStart} ${dateStart} - ${monthEnd} ${dateEnd} ${year}`;
            })()}
          </label>
        )}
        {calendarView === "day" && (
          <label style={{ minWidth: "320px", textAlign: "center" }}>
            {(() => {
              const day = calendarDate.getDate();
              const month = months[calendarDate.getMonth()];
              const year = calendarDate.getFullYear();
              let suffix = "th";
              if (day === 1 || day === 21 || day === 31) suffix = "st";
              else if (day === 2 || day === 22) suffix = "nd";
              else if (day === 3 || day === 23) suffix = "rd";

              return `${month} ${day}${suffix}, ${year}`;
            })()}
          </label>
        )}

        <button
          onClick={() => {
            const newDate = new Date(calendarDate);
            if (calendarView === "month") {
              newDate.setMonth(newDate.getMonth() + 1);
            } else if (calendarView === "week") {
              newDate.setDate(newDate.getDate() + 7);
            } else if (calendarView === "day") {
              newDate.setDate(newDate.getDate() + 1);
            }
            setCalendarDate(newDate);
          }}
        >
          <Icons.ChevronRightIcon />
        </button>

        <div
          style={{
            backgroundColor: "white",
            borderRadius: "2px",
            width: "2px",
          }}
        />
        <CalendarButton
          onClick={() => {
            setCalendarDate(today);
            setCalendarView("day");
          }}
        >
          <Icons.CalendarTodayIcon />
          <label>Today</label>
        </CalendarButton>
        <div
          style={{
            backgroundColor: "white",
            borderRadius: "2px",
            width: "2px",
          }}
        />
        <CalendarButton onClick={() => setCalendarView("day")}>
          <Icons.CalendarDayIcon />
          <label>Day View</label>
        </CalendarButton>
        <CalendarButton onClick={() => setCalendarView("week")}>
          <Icons.CalendarWeekIcon />
          <label>Week View</label>
        </CalendarButton>
        <CalendarButton onClick={() => setCalendarView("month")}>
          <Icons.CalendarMonthIcon />
          <label>Month View</label>
        </CalendarButton>
        {/* <CalendarAccessButton /> */}
        <ActionButton
          style={{ marginLeft: "auto" }}
          onClick={() => navigate("/calendar-event")}
        >
          Add Event
        </ActionButton>
        <ActionButton onClick={() => navigate("/calendar-series")}>
          Add Series
        </ActionButton>
      </div>
    </CalendarHeaderContainer>
  );
};

const CalendarDays = () => {
  const { today, calendarView, calendarDate, theme, settings } =
    React.useContext(Scribi);
  return (
    <CalendarDaysContainer
      style={{
        minHeight: "25px",
        overflowY: calendarView === "week" ? "scroll" : "hidden",
      }}
    >
      {(calendarView === "month" || calendarView === "week") &&
        days.map((day, index) => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              borderRight: index > 5 ? "none" : "1px solid darkgray",
            }}
            key={index}
            className="day-row"
          >
            <CalendarDayLabel isToday={index === today.getDay()}>
              {day}
            </CalendarDayLabel>
          </div>
        ))}
      {calendarView === "day" && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
          className="day-row"
        >
          <label
            style={{
              backgroundColor: today.getDay() === 0 ? "salmon" : "transparent",
            }}
          >
            {fullDays[calendarDate.getDay()]}
          </label>
        </div>
      )}
    </CalendarDaysContainer>
  );
};

function RenderMonth() {
  const monthGridRef = React.useRef(null);
  const [rowHeight, setRowHeight] = React.useState(0);

  const {
    calendarDate,
    today,
    setCalendarView,
    setCalendarDate,
    calendarEvents,
    setCalendarEvents,
  } = React.useContext(Scribi); // Adjust this line to match how you import your Scribi context

  const currentMonth = calendarDate?.getMonth();
  const currentYear = calendarDate?.getFullYear();
  const currentDay = calendarDate?.getDate();

  const [dayList, setDayList] = React.useState([]);
  const [weekList, setWeekList] = React.useState([]);

  // useEffect(() => {
  //   const calculateRowHeight = () => {
  //     if (monthGridRef.current) {
  //       console.log("resized");
  //       const containerHeight =
  //         monthGridRef.current.getBoundingClientRect().height - 5;
  //       let rows = 0;
  //       if (dayList.length === 28) {
  //         rows = 4;
  //       } else if (dayList.length === 35) {
  //         rows = 5;
  //       } else if (dayList.length === 42) {
  //         rows = 6;
  //       }
  //       const calculatedRowHeight = containerHeight / rows;
  //       setRowHeight(calculatedRowHeight);
  //     }
  //   };

  //   window.addEventListener("resize", calculateRowHeight);

  //   return () => {
  //     window.removeEventListener("resize", calculateRowHeight);
  //   };
  // }, [dayList.length]);

  useEffect(() => {
    // Your existing getDaysInMonth logic
    const getDaysInMonth = (month, year, day) => {
      const date = new Date(year, month, 1);
      const result = [];

      while (date.getMonth() === month) {
        result.push(new Date(date));
        date.setDate(date.getDate() + 1);
      }

      while (result[0].getDay() !== 0) {
        const previousDate = new Date(result[0]);
        previousDate.setDate(result[0].getDate() - 1);
        result.unshift(previousDate);
      }

      while (result[result.length - 1].getDay() !== 6) {
        const nextDate = new Date(result[result.length - 1]);
        nextDate.setDate(result[result.length - 1].getDate() + 1);
        result.push(nextDate);
      }

      return result;
    };

    const calendarDays = getDaysInMonth(currentMonth, currentYear, currentDay);
    setDayList(calendarDays);
    //Push days into weekList to make multiple arrays 7 days at a time:
    let week = [];
    let weeks = [];
    for (let i = 0; i < calendarDays.length; i++) {
      week.push(calendarDays[i]);
      if (week.length === 7) {
        weeks.push(week);
        week = [];
      }
    }
    setWeekList(weeks);
  }, [currentMonth, currentYear, currentDay]);

  return (
    <MonthColumn>
      {weekList.map((days, index) => (
        <RenderWeekRow days={days} today={today} key={index} />
      ))}
    </MonthColumn>
  );
}

function RenderWeekRow({ days, today }) {
  return (
    <MonthColumnWeekRow>
      {days.map((day, index) => (
        <RenderDayColumn day={day} index={index} today={today} key={index} />
      ))}
    </MonthColumnWeekRow>
  );
}

function RenderDayColumn({ day, index, today }) {
  const { calendarDate, setCalendarView, setCalendarDate, calendarEvents } =
    React.useContext(Scribi);
  const events = calendarEvents.filter(
    (event) =>
      new Date(event.start).getDate() === day.getDate() &&
      new Date(event.start).getMonth() === day.getMonth() &&
      new Date(event.start).getFullYear() === day.getFullYear()
  );
  return (
    <MonthColumnDayColumn
      key={index}
      calendarBoxColor={
        day.getDate() === today.getDate() &&
        day.getMonth() === today.getMonth() &&
        day.getFullYear() === today.getFullYear()
          ? "today"
          : day.getMonth() === today.getMonth()
          ? "thisMonth"
          : "otherMonth"
      }
    >
      {day.getDate()}
      <div className="event-container">
        {events.map((event, index) => (
          <RenderEvent event={event} key={index} />
        ))}
      </div>
    </MonthColumnDayColumn>
  );
}

function RenderWeek() {
  const { calendarDate, setCalendarView, setCalendarDate, today } =
    React.useContext(Scribi);
  const currentMonth = calendarDate?.getMonth();
  const currentYear = calendarDate?.getFullYear();
  const currentDay = calendarDate?.getDate();
  const [dayList, setDayList] = React.useState([]);

  useEffect(() => {
    const getDaysInMonth = (month, year, day) => {
      const date = new Date(year, month, day);
      date.setHours(0, 0, 0, 0);
      const result = [];

      result.push(date);
      while (result[0].getDay() !== 0) {
        const previousDate = new Date(result[0]);
        previousDate.setDate(result[0].getDate() - 1);
        result.unshift(previousDate);
      }

      while (result[result.length - 1].getDay() !== 6) {
        const nextDate = new Date(result[result.length - 1]);
        nextDate.setDate(result[result.length - 1].getDate() + 1);
        result.push(nextDate);
      }

      return result;
    };

    let targetMonth = currentMonth;
    let targetYear = currentYear;
    let targetDay = currentDay;

    const calendarDays = getDaysInMonth(targetMonth, targetYear, targetDay);

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    setDayList(calendarDays);
  }, [currentMonth, currentYear, currentDay]);

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(7, 1fr)",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        overflow: "auto",
        padding: "0px",
      }}
    >
      {dayList.map((day, index) => (
        <CalendarWeekDay
          onDoubleClick={() => {
            setCalendarDate(new Date(day));
            setCalendarView("day");
          }}
          style={{ borderRight: index > 5 && "none" }}
          calendarBoxColor={
            day.getDate() === today.getDate() &&
            day.getMonth() === today.getMonth() &&
            day.getFullYear() === today.getFullYear()
              ? "today"
              : day.getMonth() === currentMonth
              ? "thisMonth"
              : "otherMonth"
          }
          key={index}
        >
          <label
            style={{
              position: "absolute",
              top: "5px",
              left: "5px",
            }}
          >
            {day.getDate()}
          </label>
          <TaskGrid>
            {rows.map((row, timeIndex) => (
              <TimeSlot
                key={timeIndex}
                style={{
                  gridTemplateColumns: `${timeIndex + 1}`,
                  visibility: `${timeIndex === 0 ? "hidden" : "visible"}`,
                }}
              >
                <label
                  style={{ opacity: "0.5", userSelect: "none", width: "100%" }}
                >
                  {index === 0 ? `${row}` : ""}
                </label>
              </TimeSlot>
            ))}
          </TaskGrid>
        </CalendarWeekDay>
      ))}
    </div>
  );
}

function RenderDay() {
  const { calendarDate } = React.useContext(Scribi);

  return (
    <CalendarDayDay>
      <TaskGrid>
        {rows.map((row, timeIndex) => (
          <TimeSlot
            key={timeIndex}
            style={{
              gridTemplateColumns: `${timeIndex + 1}`,
              visibility: `${timeIndex === 0 ? "hidden" : "visible"}`,
            }}
          >
            <label style={{ opacity: "1", userSelect: "none" }}>{row}</label>
          </TimeSlot>
        ))}
      </TaskGrid>
    </CalendarDayDay>
  );
}

function RenderEvent({ event }) {
  const { setSidebar, navigate } = React.useContext(Scribi);
  return (
    <CalendarEvent
      event="workEvent"
      onClick={() => {
        navigate(`/`, { state: { event } });
        setSidebar({
          visible: true,
          width: 300,
        });
      }}
    >
      <label>{event.title}</label>
    </CalendarEvent>
  );
}
