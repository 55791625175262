/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { ReactRenderer } from "@tiptap/react";
import tippy from "tippy.js";

import { MentionList } from "./MentionList.jsx";
import { Scribi } from "../../utilities/api/store/store";
import {
  BodyContainer,
  CustomEditorContent,
  DocumentHeading,
  FormRow,
  FormSectionTitle,
  ProjectAreaHeader,
} from "../../Scribi-Components/ScribiComponents";
import { useEditor } from "@tiptap/react";
import Placeholder from "@tiptap/extension-placeholder";
import Typography from "@tiptap/extension-typography";
import TextAlign from "@tiptap/extension-text-align";
import Underline from "@tiptap/extension-underline";
import StarterKit from "@tiptap/starter-kit";
import { useDebouncedCallback } from "use-debounce";
import {
  getScribiDocument,
  updateScribiDocument,
} from "../../utilities/firestore/db";
import styled from "styled-components";
import Icons from "../../Icons/Icons";
import { useDndContext, useDraggable } from "@dnd-kit/core";
import "./MentionList.css";
import { Mention } from "./Mention.ts";

const MSContext = React.createContext();

const getPlaceholder = () => {
  const placeholderLines = [
    "Unleash your imagination...",
    "Pen the story only you can tell...",
    "Your words are your power. Use them...",
    "A blank page is a field of possibilities...",
    "Write something that makes you smile...",
    "Begin the adventure with a single word...",
    "Dive into your creativity...",
    "Who knows where this story will take you...",
    "You're the author of your own destiny...",
    "Every masterpiece starts with a single word...",
    "Embrace the blank page...",
    "Your story is waiting to be told...",
    "Write like no one is reading...",
    "The world needs your story...",
    "Don't think, just write...",
    "Imagine, dream, write...",
    "A story is a dream put to paper...",
    "Let your creativity flow...",
    "Put your heart into words...",
    "Every word is a brushstroke...",
    "Fill this space with magic...",
    "Dream big, write often...",
    "What's your story going to be?",
    "Today's blank page is tomorrow's bestseller...",
    "Give life to characters, one word at a time...",
    "Don't hold back, write your truth...",
    "Writing is dreaming with your eyes open...",
    "Start writing, no matter what...",
    "You write, therefore you are...",
    "Every character you create is someone's hero...",
    "In your hands, words can change the world...",
    "Bring your story to life...",
    "What will you create today?",
    "There's a world within you, write it down...",
    "Every writer starts with 'what if?'...",
    "Let the words flow, and the rest will follow...",
    "Create something unforgettable...",
    "The world is waiting for your story...",
    "Writers are makers. What will you make today?",
    "Go ahead, surprise yourself...",
    "Paint with words, sculpt with sentences...",
    "Words have wings. Let them fly...",
    "Your words can move mountains...",
    "Turn your thoughts into a masterpiece...",
    "There's an entire universe in your mind. Explore it...",
    "You have the pen. Write your own adventure...",
    "Your next great idea starts here...",
    "The first word is always the hardest. Go on...",
    "A blank page is a canvas for creativity...",
    "Write like the wind, dream like a child...",
    "Think of this draft as a best seller's ancestor...",
    "Write the book you want to read...",
    "You are the architect of worlds. Start building...",
    "Words are the seeds. Now, grow a garden...",
    "Your keyboard is a wand. Make magic happen...",
    "Writers don't get writer's block, just creative pauses...",
    "Start with a word, end with a masterpiece...",
    "Who needs a muse when you've got talent?",
    "First drafts don't have to be perfect, just written...",
    "A novel begins with a single keystroke...",
    "Today's scribbles are tomorrow's classics...",
    "Give your characters a voice. Let them speak...",
    "Write now, edit later...",
    "Your pen is mightier than your inner critic...",
    "Procrastination is the thief of novels...",
    "Don't be afraid of the delete key. It's your friend...",
    "Plot twist: you finish this chapter today...",
    "Every sentence is a step closer to 'The End'...",
    "Want to meet new people? Create characters...",
    "Writer's rule #1: There are no rules...",
    "Embrace typos, they're just excited to be written...",
    "Every book is a journey. Where will you go?",
    "You're one word closer to a standing ovation...",
    "Got writer's block? Break it down with creativity...",
    "Writing is 1% inspiration and 99% refusing to quit...",
    "In a battle of you vs. the blank page, you will win...",
    "Imagine if your characters could read this draft...",
    "Be the writer your bookshelf thinks you are...",
    "Writer's block? More like a creative detour...",
    "Words waiting to be written are words worth writing...",
    "You're the captain of this literary ship...",
    "Think big, write bigger...",
    "Your characters called. They want a plot twist...",
    "A bestseller starts with belief (and a lot of writing)...",
    "You don't have to be great to start, but you have to start to be great...",
    "Words are your paint. The page is your canvas...",
    "Your imagination knows no bounds. Neither should your story...",
    "Great novels are written one word at a time...",
    "Even Hemingway had to start somewhere...",
    "Ever tried. Ever failed. No matter. Write again. Write better...",
    "You have stories within you that the world needs to read...",
    "Write it, even if it's bad. Edit it, make it good...",
    "Today you write. Tomorrow you revise. Always you progress...",
    "Who needs a time machine when you have a pen?",
    "Your story might just change someone's life...",
    "Write the words you wish existed...",
    "The pen is your sword, the paper your shield...",
    "You're not just writing a story. You're building a legacy...",
    "Each word is a stepping stone to greatness...",
    "Let the story in your heart flow through your fingertips...",
  ];

  const randomIndex = Math.floor(Math.random() * placeholderLines.length);
  return placeholderLines[randomIndex];
};

const MSContextProvider = ({ children }) => {
  const {
    location,
    books,
    setBooks,
    elements,
    user,
    settings,
    projectNavState,
    setProjectNavState,
    project,
    setProject,
    flattenedProjectContents,
    currentDoc,
    setCurrentDoc,
    currentChapterHeading,
    currentPartHeading,
    currentDocumentHeadings,
    setCurrentChapterHeading,
    setCurrentDocumentHeadings,
    setCurrentPartHeading,
  } = useContext(Scribi);
  const [currentWords, setCurrentWords] = React.useState(0);
  const [totalWords, setTotalWords] = React.useState(0);

  useEffect(() => {
    if (currentDoc) {
      setCurrentWords(currentDoc.words);
    }
  }, [currentDoc]);

  useEffect(() => {
    if (flattenedProjectContents && currentDoc) {
      let total = currentWords;
      flattenedProjectContents.forEach((item) => {
        if (item.type === "document" && item.id !== currentDoc.id) {
          total += item.words;
        }
      });
      setTotalWords(total);
    }
  }, [flattenedProjectContents, currentWords]);

  function updateWordCount(bookId, newWordCount) {
    setBooks((prevBooks) => {
      return prevBooks.map((book) => {
        if (book.id === bookId) {
          return { ...book, currentWords: newWordCount };
        }
        return book;
      });
    });
  }

  useEffect(() => {
    if (totalWords !== project.currentWords) {
      updateWordCount(project.id, totalWords);
    }
  }, [totalWords]);

  return (
    <MSContext.Provider
      value={{
        location,
        books,
        elements,
        user,
        settings,
        projectNavState,
        setProjectNavState,
        flattenedProjectContents,
        project,
        currentDoc,
        setCurrentDoc,
        totalWords,
        setTotalWords,
        currentWords,
        setCurrentWords,
        setProject,
        currentChapterHeading,
        currentPartHeading,
        currentDocumentHeadings,
        setCurrentChapterHeading,
        setCurrentDocumentHeadings,
        setCurrentPartHeading,
      }}
    >
      {children}
    </MSContext.Provider>
  );
};

const Manuscript = () => {
  return (
    <MSContextProvider>
      <MSHeader />
      <MSContainer />
      <MSFooter />
    </MSContextProvider>
  );
};

export default Manuscript;

const ToolbarButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.toolbarButton};
  min-height: 20px;
  min-width: 20px;
  svg {
    width: 15px;
    height: 15px;
  }
`;

const MSHeader = () => {
  return (
    <ProjectAreaHeader style={{ borderTop: "1px solid darkgray" }}>
      <div className="toolbar-section-one">
        <ToolbarButton>
          <Icons.Bold />
        </ToolbarButton>
        <ToolbarButton>
          <Icons.Italic />
        </ToolbarButton>
        <ToolbarButton>
          <Icons.Underline />
        </ToolbarButton>
        <div className="spacer" />
        <ToolbarButton>
          <Icons.AlignLeft />
        </ToolbarButton>
        <ToolbarButton>
          <Icons.AlignCenter />
        </ToolbarButton>
        <ToolbarButton>
          <Icons.AlignRight />
        </ToolbarButton>
        <ToolbarButton>
          <Icons.AlignJustify />
        </ToolbarButton>
      </div>
      <div className="toolbar-section-two">Area Two</div>
      <div className="toolbar-section-three"> Area Three</div>
    </ProjectAreaHeader>
  );
};

const MSContainer = () => {
  const {
    settings,
    user,
    projectNavState,
    project,
    setProject,
    flattenedProjectContents,
    currentDoc,
    setCurrentDoc,
    setCurrentWords,
    totalWords,
    currentChapterHeading,
    currentPartHeading,
    currentDocumentHeadings,
    setCurrentChapterHeading,
    setCurrentDocumentHeadings,
    setCurrentPartHeading,
  } = useContext(MSContext);
  const { currentDocParent, currentDocGrandparent, note, setNote, elements } =
    useContext(Scribi);

  const [newDocumentHeading, setNewDocumentHeading] = React.useState([""]);
  const [newChapterHeading, setNewChapterHeading] = React.useState([""]);
  const [newPartHeading, setNewPartHeading] = React.useState([""]);

  const dndContext = useDndContext();

  useEffect(() => {
    setNewDocumentHeading(currentDocumentHeadings);
    setNewChapterHeading(currentChapterHeading);
    setNewPartHeading(currentPartHeading);
  }, [currentChapterHeading, currentPartHeading, currentDocumentHeadings]);

  const updateWordCount = (contents, id, count) => {
    for (let i = 0; i < contents.length; i++) {
      const item = contents[i];
      if (item.id === id) {
        item.words = count;
        return true;
      }
      if (item.children && item.children.length > 0) {
        const found = updateWordCount(item.children, id, count);
        if (found) return true;
      }
    }
    return false;
  };

  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Placeholder.configure({
        emptyEditorClass: "is-editor-empty",
        placeholder: getPlaceholder(),
      }),
      Typography.configure({
        leftArrow: false,
        rightArrow: false,
        oneHalf: false,
        oneQuarter: false,
        threeQuarters: false,
        superscriptThree: false,
        superscriptTwo: false,
        multiplication: false,
        plusMinus: false,
        notEqual: false,
      }),
      TextAlign.configure({
        types: ["paragraph", "heading", "blockquote"],
        align: ["left", "center", "right", "justify"],
      }),
      Mention.configure({
        HTMLAttributes: {
          class: "mention",
        },
        suggestion: {
          items: ({ query }) => {
            const list = elements.filter((e) => e.world === project.world);
            let filteredList = [];

            console.log(filteredList);
            
            list.forEach((item) => {
              const isNameMatch = item.name.toLowerCase().startsWith(query.toLowerCase());
              const isAliasMatch = item.aliases?.some(alias => alias.toLowerCase().startsWith(query.toLowerCase()));
            
              if (isNameMatch || isAliasMatch) {
                filteredList.push(item);
              }
            
              if (item.aliases) {
                item.aliases.forEach((alias) => {
                  if (alias.toLowerCase().startsWith(query.toLowerCase())) {
                    const newItem = { ...item, name: alias, nameType: "alias" };
                    filteredList.push(newItem);
                  }
                });
              }
            });
            

            filteredList = filteredList.slice(0, 5);

            return filteredList.length
              ? filteredList
              : [
                  {
                    name: query,
                    world: project.world,
                    ownerId: user.uid,
                    images: [],
                    notes: [],
                    editors: [],
                    viewers: [],
                    doctype: "elements",
                    description: "",
                    type: "quick-element",
                    subtype: null,
                    pinned: null,
                    tags: [],
                    attributes: [],
                  },
                ];
          },

          render: () => {
            let component;
            let popup;

            return {
              onStart: (props) => {
                component = new ReactRenderer(MentionList, {
                  props,
                  editor: props.editor,
                });

                if (!props.clientRect) {
                  return;
                }

                popup = tippy("body", {
                  getReferenceClientRect: props.clientRect,
                  appendTo: () => document.body,
                  content: component.element,
                  showOnCreate: true,
                  interactive: true,
                  trigger: "manual",
                  placement: "bottom-start",
                });
              },

              onUpdate(props) {
                component.updateProps(props);

                if (!props.clientRect) {
                  return;
                }

                popup[0].setProps({
                  getReferenceClientRect: props.clientRect,
                });
              },

              onKeyDown(props) {
                if (props.event.key === "Escape") {
                  popup[0].hide();

                  return true;
                }

                return component.ref?.onKeyDown(props);
              },

              onExit() {
                popup[0].destroy();
                component.destroy();
              },
            };
          },
        },
      }),
    ],
    content: null,
    onUpdate({ editor }) {
      let newWordCount = editor
        .getText()
        .split(/\s+/) // This regex will split on any type of whitespace
        .filter((word) => word.trim() !== "").length;

      setCurrentWords(newWordCount);
      debouncedSave(editor.getJSON(), newWordCount);
    },
  });

  const debouncedSave = useDebouncedCallback(
    async (content, count) => {
      let newBook = JSON.parse(JSON.stringify(project));
      updateWordCount(newBook.contents, currentDoc.id, count);
      newBook.currentWords = totalWords;
      let newDocument = JSON.parse(JSON.stringify(currentDoc));
      newDocument.content = content;
      newDocument.words = count;
      try {
        await updateScribiDocument(newBook);
        await updateScribiDocument(newDocument);
      } catch (error) {
        console.log(error);
      }
    },
    2000,
    { maxWait: 100 * 2000 }
  );

  const getDocument = async (id) => {
    let doc = await getScribiDocument(id);
    return doc;
  };

  useEffect(() => {
    if (editor && editor.view) {
      const handleDragStart = (event) => {
        console.log(editor.view.dragging.getText());
        const selectedText = window.getSelection().toString();
        setNote(selectedText);
      };

      const handleDragEnd = (event) => {
        setNote(null);
      };

      const viewDom = editor.view.dom;
      viewDom.addEventListener("dragstart", handleDragStart);
      viewDom.addEventListener("dragend", handleDragEnd);

      // Cleanup
      return () => {
        viewDom.removeEventListener("dragstart", handleDragStart);
        viewDom.removeEventListener("dragend", handleDragEnd);
      };
    }
  }, [editor]);

  useEffect(() => {
    if (editor && currentDoc) {
      editor.commands.setContent(currentDoc.content);
    }
  }, [editor, currentDoc]);

  useEffect(() => {
    if (projectNavState.selected) {
      let selected = flattenedProjectContents.find(
        (item) => item.id === projectNavState.selected
      );
      if (selected && selected.type === "document") {
        getDocument(selected.id).then((doc) => {
          setCurrentDoc(doc);
        });
      } else {
        return;
      }
    } else {
    }
  }, [projectNavState.selected]);

  const headingRef = React.useRef(null);
  const parentHeadingRef = React.useRef(null);
  const grandparentHeadingRef = React.useRef(null);

  return (
    <BodyContainer style={{ overflowY: "scroll" }}>
      <div
        className="editor-box"
        style={{
          width: "100%",
          maxWidth: "1000px",
          margin: "0 auto",
          flexGrow: 1,
          display: "grid",
          gridTemplateRows: "min-content min-content min-content 1fr",
        }}
      >
        {editor && currentDoc && (
          <>
            <FormRow justifyContent={"center"}>
              {currentDocGrandparent && (
                <DocumentHeading
                  ref={grandparentHeadingRef}
                  type="text"
                  placeholder="Optional Part Heading"
                  value={newPartHeading}
                  onChange={(e) => {
                    setNewPartHeading(e.target.value);
                  }}
                  onBlur={() => {
                    setNewPartHeading(currentPartHeading);
                  }}
                  onKeyDown={async (e) => {
                    if (e.key === "Enter") {
                      console.log(e.target.value);
                      e.preventDefault();
                      e.stopPropagation();
                      let newProject = JSON.parse(JSON.stringify(project));
                      newProject.contents.map((item) => {
                        if (item.id === currentDocGrandparent) {
                          item.heading = e.target.value;
                        }
                      });
                      setCurrentPartHeading(e.target.value);
                      await updateScribiDocument(newProject);
                      //blue headingRef.current:
                      grandparentHeadingRef.current.blur();
                    } else if (e.key === "Escape") {
                      e.preventDefault();
                      e.stopPropagation();
                      setNewPartHeading(currentPartHeading);
                      grandparentHeadingRef.current.blur();
                    }
                  }}
                />
              )}
            </FormRow>
            <FormRow justifyContent={"center"}>
              {currentDocParent && (
                <DocumentHeading
                  ref={parentHeadingRef}
                  type="text"
                  placeholder="Optional Chapter Heading"
                  value={newChapterHeading}
                  onChange={(e) => {
                    setNewChapterHeading(e.target.value);
                  }}
                  onBlur={() => {
                    setNewChapterHeading(currentChapterHeading);
                  }}
                  onKeyDown={async (e) => {
                    if (e.key === "Enter") {
                      console.log(e.target.value);
                      e.preventDefault();
                      e.stopPropagation();
                      let newProject = JSON.parse(JSON.stringify(project));
                      newProject.contents.map((item) => {
                        if (item.id === currentDocParent) {
                          item.heading = e.target.value;
                        } else {
                          item.children.map((child) => {
                            if (child.id === currentDocParent) {
                              child.heading = e.target.value;
                            }
                          });
                        }
                      });
                      setCurrentChapterHeading(e.target.value);
                      await updateScribiDocument(newProject);
                      //blue headingRef.current:
                      parentHeadingRef.current.blur();
                    } else if (e.key === "Escape") {
                      e.preventDefault();
                      e.stopPropagation();
                      setNewChapterHeading(currentChapterHeading);
                      parentHeadingRef.current.blur();
                    }
                  }}
                />
              )}
            </FormRow>
            <FormRow justifyContent={"center"}>
              <DocumentHeading
                ref={headingRef}
                type="text"
                placeholder="Optional Document Heading"
                value={newDocumentHeading[0]}
                onChange={(e) => {
                  setNewDocumentHeading([e.target.value]);
                }}
                onBlur={() => {
                  setNewDocumentHeading(currentDocumentHeadings);
                }}
                onKeyDown={async (e) => {
                  if (e.key === "Enter") {
                    console.log(e.target.value);
                    e.preventDefault();
                    e.stopPropagation();
                    let newProject = JSON.parse(JSON.stringify(project));
                    newProject.contents.map((item) => {
                      if (item.id === currentDoc.id) {
                        item.heading = e.target.value;
                      } else {
                        item.children.map((child) => {
                          if (child.id === currentDoc.id) {
                            child.heading = e.target.value;
                          } else {
                            item.children.map((grandchild) => {
                              if (grandchild.id === currentDoc.id) {
                                grandchild.heading = e.target.value;
                              }
                            });
                          }
                        });
                      }
                    });
                    setCurrentDocumentHeadings([e.target.value]);
                    await updateScribiDocument(newProject);
                    //blue headingRef.current:
                    headingRef.current.blur();
                  } else if (e.key === "Escape") {
                    e.preventDefault();
                    e.stopPropagation();
                    setNewDocumentHeading(currentDocumentHeadings);
                    headingRef.current.blur();
                  }
                }}
              />
            </FormRow>
            <CustomEditorContent
              editor={editor}
              font={user?.settings?.msFont ? user.settings.msFont : ""}
              lineheight={
                user?.settings?.msLineHeight ? user.settings.msLineHeight : ""
              }
              paraspacing={
                user?.settings?.msParaSpacing ? user.settings.msParaSpacing : ""
              }
            />
          </>
        )}
      </div>
    </BodyContainer>
  );
};

const MSFooter = () => {
  const { currentWords, totalWords, project } = useContext(MSContext);
  return (
    <ProjectAreaHeader>
      <div className="section-one">Area One</div>
      <div className="section-two">Wordcount: {currentWords || 0}</div>
      <div className="section-three">
        Total: {totalWords || 0}/{project?.targetWords}
      </div>
    </ProjectAreaHeader>
  );
};
