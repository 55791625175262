import React, { useEffect, useState } from "react";
import { BodyContainer } from "../../Scribi-Components/ScribiComponents";
import ProjectHeader from "./ProjectHeader";
import { Scribi } from "../../utilities/api/store/store";
import Manuscript from "../Manuscript/Manuscript";
import Storybase from "../Storybase/Storybase";
import Brainstorm from "../Brainstorm/Brainstorm";
import Beatsheet from "../Beatsheet/Beatsheet";
import Outline from "../Outline/Outline";
import { onSnapshot, query, collection } from "firebase/firestore";
import { db } from "../../utilities/firestore/cloud-configs";

const Pages = {
  "#storybase": Storybase,
  "#brainstorm": Brainstorm,
  "#beatsheet": Beatsheet,
  "#outline": Outline,
  "#manuscript": Manuscript,
};

const OpenProject = () => {
  const { location, setSidebar } = React.useContext(Scribi);
  const [page, setPage] = useState(location.hash);

  useEffect(() => {
    let sideBarState = JSON.parse(localStorage.getItem("projectSidebarState"));
    if (sideBarState) {
      setSidebar(sideBarState);
    } else {
      setSidebar({ visible: true, width: 300 });
    }
  }, []);

  useEffect(() => {
    if (location.hash) {
      setPage(location.hash);
    }
  }, [location.hash]);

  const PageComponent = Pages[page];
  return (
    <BodyContainer style={{ overflow: "hidden" }}>
      <ProjectHeader />
      {page ? <PageComponent /> : <Storybase />}
    </BodyContainer>
  );
};

export default OpenProject;
