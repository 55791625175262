import React from "react";
import {
  IconButton,
  ProjectAreaHeader,
} from "../../Scribi-Components/ScribiComponents";
import Icons from "../../Icons/Icons";
import { Scribi } from "../../utilities/api/store/store";

const ProjectHeader = () => {
  const { navigate, location, setSidebar, sidebar } = React.useContext(Scribi);

  const handleNavigate = (page) => {
    navigate({
      pathname: `${location.pathname}`,
      search: `${location.search}`,
      hash: `#${page}`,
    });
  };

  return (
    <ProjectAreaHeader>
      <div className="section-one"></div>
      <div className="section-two">
        <IconButton onClick={() => handleNavigate("storybase")}>
          <Icons.StorybaseIcon />
        </IconButton>
        <IconButton onClick={() => handleNavigate("brainstorm")}>
          <Icons.BrainstormIcon />
        </IconButton>
        <IconButton onClick={() => handleNavigate("beatsheet")}>
          <Icons.BeatsheetIcon />
        </IconButton>
        <IconButton onClick={() => handleNavigate("outline")}>
          <Icons.OutlineIcon />
        </IconButton>
        <IconButton onClick={() => handleNavigate("manuscript")}>
          <Icons.ManuscriptIcon />
        </IconButton>
      </div>
      <div className="section-three">
        {!sidebar.visible && (
          <IconButton
            onClick={() => {
              setSidebar({ visible: true, width: 300 });
              localStorage.setItem(
                "projectSidebarState",
                JSON.stringify({ visible: true, width: 300 })
              );
            }}
            title="Show sidebar"
          >
            <Icons.ArrowHideLeft />
          </IconButton>
        )}
      </div>
    </ProjectAreaHeader>
  );
};

export default ProjectHeader;
