import React from "react";
import {
  ActionButton,
  BodyContainer,
  CancelButton,
  ConfirmButton,
  FormContainer,
  FormGrid,
  FormGridHeading,
  FormGridTitle,
  FormInput,
  FormLabel,
  FormLabeledCheckBox,
  FormRow,
  FormSection,
  FormSectionTitle,
  FormTitle,
  HrDivider,
} from "../Scribi-Components/ScribiComponents";
import { Scribi } from "../utilities/api/store/store";
import ScribiRestService from "../utilities/firestore/ScribiRestService";

const UserSettings = () => {
  const { settings, setSettings, user } = React.useContext(Scribi);
  const [newSettings, setNewSettings] = React.useState({ ...settings });

  React.useEffect(() => {
    setNewSettings({ ...settings });
  }, [settings]);

  const saveSettings = async () => {
    let res = await ScribiRestService.updateDocument(`users`, newSettings);
    if (res.isUpdated) {
      alert("Settings saved!");
    }
  };
  const resetSettings = () => {
    setNewSettings(settings);
  };
  return (
    <BodyContainer>
      <FormContainer>
        <FormTitle>User Settings:</FormTitle>
        <HrDivider />
        <FormSection>
          <FormLabeledCheckBox>
            <input
              type="checkbox"
              checked={settings.theme === "dark" || false}
              onChange={() => {
                setSettings({
                  ...newSettings,
                  theme: settings.theme === "dark" ? "scribi" : "dark",
                });
                setNewSettings({
                  ...newSettings,
                  theme: newSettings.theme === "dark" ? "scribi" : "dark",
                });
              }}
            />
            <label>Enable Dark Mode</label>
          </FormLabeledCheckBox>
        </FormSection>
        <FormSection>
          <FormLabeledCheckBox>
            <input
              type="checkbox"
              checked={newSettings.notifications || false}
              onChange={() => {
                setNewSettings({
                  ...newSettings,
                  notifications: !newSettings.notifications,
                });
              }}
            />
            <label>Enable Notifications</label>
          </FormLabeledCheckBox>
        </FormSection>
        <HrDivider />
        <FormSectionTitle>Office Hours</FormSectionTitle>
        {newSettings?.hours && (
          <HoursGrid
            newSettings={newSettings}
            setNewSettings={setNewSettings}
          />
        )}
        <FormGrid columns={"min-content 1fr"}>
          <HrDivider gridColumn={"1/span 2"} />
          <FormSectionTitle gridColumn={"1 / span 2"}>Targets</FormSectionTitle>
          <FormLabel>Daily Words:</FormLabel>
          <FormInput
            type="number"
            min={0}
            value={newSettings.dailywords || 0}
            onChange={(e) => {
              setNewSettings({
                ...newSettings,
                dailywords: parseInt(e.target.value),
              });
            }}
          />
          <FormLabel>Books Per Year:</FormLabel>
          <FormInput
            type="number"
            min={0}
            value={newSettings.booksperyear || 0}
            onChange={(e) => {
              setNewSettings({
                ...newSettings,
                booksperyear: parseInt(e.target.value),
              });
            }}
          />
        </FormGrid>
        <FormSection style={{ display: "none" }}>
          <label>Integrations?</label>
          <p>
            Could be a place to offer integrations and let users log in to
            different accounts. Facebook, instagram, mailchimp, etc? Book
            funnel, and so on.
          </p>
        </FormSection>
        <FormSection style={{ display: "none" }}>
          <label>Export Data</label>
          <p>
            Maybe export all data to a formatted word doc or a zip file with
            word docs.
          </p>
        </FormSection>
        <FormSection style={{ display: "none" }}>
          <label>Custom theme</label>
          <p>
            Maybe a form with an 'example page' set up so users can color-pick
            to set custom theme? Would need to record this maybe in browser, or
            save to user document, and then have a setting in themes for
            'custom' and if using that, locate and implement custom theme.
          </p>
        </FormSection>
        <FormRow justifyContent="flex-end">
          <ConfirmButton type="button" onClick={saveSettings}>
            Save
          </ConfirmButton>
          <CancelButton type="button" onClick={resetSettings}>
            Reset
          </CancelButton>
        </FormRow>
      </FormContainer>
    </BodyContainer>
  );
};

export default UserSettings;

// const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const fullDays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

function OfficeHours({ newSettings, setNewSettings }) {
  const [selectedDay, setSelectedDay] = React.useState(null);

  React.useEffect(() => {
    if (newSettings) {
      if (!newSettings.workMap) {
        let workMap = [];
        for (let i = 0; i < 7; i++) {
          workMap.push({
            day: fullDays[i],
            hours: [],
            active: false,
          });
        }
        setNewSettings({ ...newSettings, workMap: workMap });
        setSelectedDay(0);
      }
    }
  }, [newSettings, setNewSettings]);

  return (
    <FormSection>
      <HrDivider />
      <FormSectionTitle>Office Hours</FormSectionTitle>
      <FormGrid columns="min-content 1fr">
        <FormSection>
          <Day
            label="Sunday"
            day={newSettings.hours.Sunday}
            newSettings={newSettings}
            setNewSettings={setNewSettings}
            setSelectedDay={setSelectedDay}
            selectedDay={selectedDay}
          />
          <Day
            label="Monday"
            day={newSettings.hours.Monday}
            newSettings={newSettings}
            setNewSettings={setNewSettings}
            setSelectedDay={setSelectedDay}
            selectedDay={selectedDay}
          />
          <Day
            label="Tuesday"
            day={newSettings.hours.Tuesday}
            newSettings={newSettings}
            setNewSettings={setNewSettings}
            setSelectedDay={setSelectedDay}
            selectedDay={selectedDay}
          />
          <Day
            label="Wednesday"
            day={newSettings.hours.Wednesday}
            newSettings={newSettings}
            setNewSettings={setNewSettings}
            setSelectedDay={setSelectedDay}
            selectedDay={selectedDay}
          />
          <Day
            label="Thursday"
            day={newSettings.hours.Thursday}
            newSettings={newSettings}
            setNewSettings={setNewSettings}
            setSelectedDay={setSelectedDay}
            selectedDay={selectedDay}
          />
          <Day
            label="Friday"
            day={newSettings.hours.Friday}
            newSettings={newSettings}
            setNewSettings={setNewSettings}
            setSelectedDay={setSelectedDay}
            selectedDay={selectedDay}
          />
          <Day
            label="Saturday"
            day={newSettings.hours.Saturday}
            newSettings={newSettings}
            setNewSettings={setNewSettings}
            setSelectedDay={setSelectedDay}
            selectedDay={selectedDay}
          />
        </FormSection>
        <FormSection>
          {newSettings && newSettings.hours && (
            <HoursGrid
              index={selectedDay}
              day={newSettings.hours[selectedDay]}
              newSettings={newSettings}
              setNewSettings={setNewSettings}
            />
          )}
        </FormSection>
      </FormGrid>
    </FormSection>
  );
}

function Day({
  label,
  day,
  index,
  newSettings,
  setNewSettings,
  setSelectedDay,
  selectedDay,
}) {
  return (
    <FormLabeledCheckBox>
      <input
        type="checkbox"
        checked={day.active}
        onChange={() => {
          let newWorkMap = [...newSettings.hours];
          newWorkMap[index].active = !newWorkMap[index].active;
          if (!newWorkMap[index].active) {
            newWorkMap[index].hours = [];
          } else {
            newWorkMap[index].hours = [
              {
                start: "09:00",
                end: "17:00",
              },
            ];
          }
          setNewSettings({ ...newSettings, workMap: newWorkMap });
          setSelectedDay(index);
        }}
      />
      <label
        style={{
          background: selectedDay === index ? "lightblue" : "transparent",
          padding: "5px",
        }}
        onClick={() => {
          setSelectedDay(index);
        }}
      >
        {label}
      </label>
    </FormLabeledCheckBox>
  );
}

function HoursGrid({ newSettings, setNewSettings }) {
  const addMinutesToTime = (time, minutesToAdd) => {
    const [hours, minutes] = time.split(":").map(Number);
    let newMinutes = minutes + minutesToAdd;
    let newHours = hours;

    if (newMinutes >= 60) {
      newHours += Math.floor(newMinutes / 60);
      newMinutes %= 60;
    }

    return `${newHours.toString().padStart(2, "0")}:${newMinutes
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <FormGrid
      columns={"50px 1fr 30px 1fr min-content min-content"}
      style={{ width: "min-content" }}
    >
      {fullDays.map((day, index) => (
        <React.Fragment key={day}>
          <FormLabeledCheckBox style={{ gridColumn: "1 / span 5" }}>
            <input
              type="checkbox"
              checked={newSettings.hours[day].active}
              onChange={() => {
                let newWorkMap = { ...newSettings.hours };
                newWorkMap[day].active = !newWorkMap[day].active;
                setNewSettings({ ...newSettings, hours: newWorkMap });
              }}
            />
            <FormGridHeading>{day}</FormGridHeading>
          </FormLabeledCheckBox>
          <ActionButton
            style={{ visibility: index > 0 ? "visible" : "hidden" }}
            onClick={() => {
              let newWorkMap = { ...newSettings.hours };
              let foundActiveDay = false;

              // Start from the day immediately before the current one and go backward
              for (let i = index - 1; i >= 0; i--) {
                const prevDay = fullDays[i];
                if (newWorkMap[prevDay].active) {
                  newWorkMap[day].hours = [...newWorkMap[prevDay].hours];
                  foundActiveDay = true;
                  break;
                }
              }

              if (foundActiveDay) {
                setNewSettings({ ...newSettings, hours: newWorkMap });
              }
            }}
          >
            Duplicate Previous
          </ActionButton>

          {newSettings.hours[day].active && (
            <HoursBlock
              day={newSettings.hours[day]}
              label={day}
              newSettings={newSettings}
              setNewSettings={setNewSettings}
              addMinutesToTime={addMinutesToTime}
            />
          )}
          {index < 6 && <HrDivider gridColumn={" 1 / span 6 "} />}
        </React.Fragment>
      ))}
    </FormGrid>
  );
}

function HoursBlock({
  day,
  label,
  addMinutesToTime,
  newSettings,
  setNewSettings,
}) {
  return day.hours.map((dayHours, index) => (
    <React.Fragment key={index}>
      <label>From:</label>
      <TimeSelect
        time={dayHours.start}
        setTime={(time) => {
          let newWorkMap = JSON.parse(JSON.stringify(newSettings.hours)); // Deep clone
          newWorkMap[label].hours[index].start = time;
          setNewSettings({ ...newSettings, hours: newWorkMap });
        }}
        minTime={
          index === 0 ? "00:00" : newSettings.hours[label].hours[index - 1].end
        }
      />
      <label>To:</label>
      <TimeSelect
        time={dayHours.end}
        setTime={(time) => {
          let newWorkMap = JSON.parse(JSON.stringify(newSettings.hours)); // Deep clone
          newWorkMap[label].hours[index].end = time;
          setNewSettings({ ...newSettings, hours: newWorkMap });
        }}
        minTime={addMinutesToTime(
          newSettings.hours[label].hours[index].start,
          15
        )}
      />
      <ActionButton
        style={{ padding: "2px 5px" }}
        onClick={() => {
          let newWorkMap = JSON.parse(JSON.stringify(newSettings.hours)); // Deep clone
          newWorkMap[label].hours.splice(index + 1, 0, {
            start: addMinutesToTime(newWorkMap[label].hours[index].end, 15),
            end: addMinutesToTime(newWorkMap[label].hours[index].end, 30),
          });
          setNewSettings({ ...newSettings, hours: newWorkMap });
        }}
      >
        Add Block
      </ActionButton>
      <ActionButton
        style={{
          visibility: index > 0 ? "visible" : "hidden",
          padding: "2px 5px",
        }}
        onClick={() => {
          let newWorkMap = JSON.parse(JSON.stringify(newSettings.hours)); // Deep clone
          newWorkMap[label].hours.splice(index, 1);
          setNewSettings({ ...newSettings, hours: newWorkMap });
        }}
      >
        Remove Block
      </ActionButton>
    </React.Fragment>
  ));
}

function TimeSelect({ setTime, minTime, time }) {
  const generateTimeOptions = () => {
    const options = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        const displayHour = hour % 12 === 0 ? 12 : hour % 12;
        const ampm = hour < 12 ? "am" : "pm";
        const hourStr = displayHour.toString().padStart(2, "0");
        const minuteStr = minute.toString().padStart(2, "0");
        const timeStr = `${hourStr}:${minuteStr} ${ampm}`;
        const militaryStr = `${hour.toString().padStart(2, "0")}:${minuteStr}`;
        options.push({ display: timeStr, value: militaryStr });
      }
    }
    return options;
  };

  // Get filtered options based on minTime
  const filteredOptions = React.useMemo(
    () => generateTimeOptions().filter((option) => option.value >= minTime),
    [minTime]
  );

  return (
    <select
      value={time}
      onChange={(e) => {
        setTime(e.target.value);
      }}
    >
      {filteredOptions.map((option, index) => (
        <option key={index} value={option.value}>
          {option.display}
        </option>
      ))}
    </select>
  );
}
