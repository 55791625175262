import React, { useContext, useEffect, useState } from "react";
import { Scribi } from "../../utilities/api/store/store";
import { useSearchParams } from "react-router-dom";
import Icons from "../../Icons/Icons";
import { updateScribiDocument } from "../../utilities/firestore/db";
import {
  FormInput,
  HrDivider,
  IconButton,
  NavItem,
} from "../../Scribi-Components/ScribiComponents";
import {
  useDroppable,
  useDraggable,
  useDndContext,
  useDndMonitor,
} from "@dnd-kit/core";
import {
  ChapterPreviewItem,
  DocumentPreviewItem,
  PartPreviewItem,
} from "../../utilities/DragPreview/DragPreview";

const MsNavContext = React.createContext();

const MSNavProvider = ({ children }) => {
  const {
    navigate,
    location,
    books,
    worlds,
    series,
    pens,
    projectNavState,
    setProjectNavState,
    searchParams,
    id,
    project,
    setProject,
    flattenedProjectContents,
    setFlattenedProjectContents,
    setContextMenu,
    contextMenu,
  } = useContext(Scribi);

  useEffect(() => {
    if (id && books.length > 0) {
      setProject(books.find((b) => b.id === id));
      //Flatten the project.contents array and setFlattenedProjectContents:
      let flattened = [];
      function flattenContents(contents) {
        contents.forEach((item) => {
          flattened.push(item);
          if (item.children) {
            flattenContents(item.children);
          }
        });
      }
      flattenContents(books.find((b) => b.id === id).contents);
      setFlattenedProjectContents(flattened);
      let savedState = JSON.parse(localStorage.getItem(id));
      if (savedState) {
        setProjectNavState(savedState);
      } else {
        let newProjectNavState = {
          selected: flattened.find((item) => item.type === "document").id,
          page: "#storybase",
        };
        setProjectNavState(newProjectNavState);
        localStorage.setItem(id, JSON.stringify(newProjectNavState));
        navigate({
          pathname: `${location.pathname}`,
          hash: "#storybase",
          search: `?id=${id}`,
        });
      }
    }
  }, [id, books, project]);

  useEffect(() => {
    if (Object.keys(projectNavState).length > 0) {
      //Check to see if projectNavState === localStorage.getItem(id), if so do nothing, if not, update localStorage:
      let savedState = localStorage.getItem(id)
        ? JSON.parse(localStorage.getItem(id))
        : {};
      if (JSON.stringify(projectNavState) !== JSON.stringify(savedState)) {
        localStorage.setItem(id, JSON.stringify(projectNavState));
      }
    }
  }, [projectNavState]);

  useEffect(() => {
    if (location.hash) {
      let savedState = localStorage.getItem(id)
        ? JSON.parse(localStorage.getItem(id))
        : {};
      let newNavState = { ...savedState, page: location.hash };
      if (JSON.stringify(newNavState) !== JSON.stringify(savedState)) {
        localStorage.setItem(id, JSON.stringify(newNavState));
      }
    }
  }, [location.hash]);

  return (
    <MsNavContext.Provider
      value={{
        project,
        books,
        worlds,
        series,
        pens,
        navigate,
        location,
        flattenedProjectContents,
        projectNavState,
        setProjectNavState,
        setContextMenu,
        contextMenu,
      }}
    >
      {children}
    </MsNavContext.Provider>
  );
};

const MSNav = () => {
  return (
    <MSNavProvider>
      <MSNavContainer />
    </MSNavProvider>
  );
};

const MSNavContainer = () => {
  const { project, navigate, location, setContextMenu, contextMenu } =
    useContext(MsNavContext);

  const msMenuButtonRef = React.useRef(null);

  const [editingTitle, setEditingTitle] = useState(false);
  const [newTitle, setNewTitle] = useState(project?.title || "");

  const updateTitle = async (title) => {
    const newBook = { ...project, title: title };
    await updateScribiDocument(newBook);
  };

  useEffect(() => {
    if (project) {
      setNewTitle(project.title);
    }
  }, [project]);

  return (
    project && (
      <>
        <div
          style={{
            display: "flex",
            gap: "5px",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Icons.BookIcon />
          {!editingTitle ? (
            <label
              onClick={() => setEditingTitle(true)}
              style={{
                fontSize: "1.2rem",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "90%",
              }}
            >
              {project.title}
            </label>
          ) : (
            <input
              value={newTitle}
              onChange={(e) => setNewTitle(e.target.value)}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  updateTitle(e.target.value);
                  setEditingTitle(false);
                }
                if (e.key === "Escape") {
                  setEditingTitle(false);
                }
              }}
            />
          )}
          <IconButton
            ref={msMenuButtonRef}
            size="15"
            style={{ marginLeft: "auto" }}
            onClick={() => {
              if (msMenuButtonRef.current) {
                const rect = msMenuButtonRef.current.getBoundingClientRect();
                setContextMenu({
                  visible: !contextMenu.visible,
                  x: rect.left,
                  y: rect.bottom, // top edge positioned immediately below the button
                  menu: "msNavMenu",
                });
              }
            }}
          >
            <Icons.AddIcon />
          </IconButton>
        </div>
        <HrDivider />
        <RenderManuscriptNav />
      </>
    )
  );
};

export default MSNav;

const RenderManuscriptNav = () => {
  const { project, navigate } = useContext(MsNavContext);

  return project?.contents?.map((item, index) => {
    return (
      <React.Fragment key={item.id}>
        {index > 0 ? null : (
          <DroppableDivider
            id={`before:${item.id}`}
            reject={[item.id]}
            indexMap={[index]}
            indent={0}
          />
        )}
        <RenderMsItem item={item} indexMap={[index]} indent={0} />
        <DroppableDivider
          id={`after:${item.id}`}
          reject={[item.id, project.contents[index + 1]?.id]}
          indexMap={[index + 1]}
          indent={0}
        />
      </React.Fragment>
    );
  });
};

const DroppableDivider = ({
  id,
  type = "drop-divider",
  reject,
  indexMap,
  indent,
}) => {
  const { setNodeRef: setDropRef, isOver } = useDroppable({
    id: id,
    data: {
      type: type,
      indexMap: indexMap,
    },
  });

  const { active } = useDndContext();

  const renderPlaceholderElement = () => {
    if (active?.data?.current?.type === "document") {
      return <DocumentPreviewItem indent={indent} />;
    }
    if (active?.data?.current?.type === "chapter") {
      return <ChapterPreviewItem indent={indent} />;
    }
    if (active?.data?.current?.type === "part") {
      return <PartPreviewItem />;
    }
  };

  return (
    <div
      ref={setDropRef}
      style={{
        visibility:
          isOver && !reject.includes(active.id) ? "visible" : "hidden",
        width: "100%",
        minHeight: "5px",
        display: "flex",
        alignItems: "center",
        backgroundColor:
          isOver && !reject.includes(active.id) ? "slategray" : "transparent",
      }}
    >
      {isOver && !reject.includes(active.id) && renderPlaceholderElement()}
    </div>
  );
};

const RenderMsItem = ({ item, indexMap, indent }) => {
  function RenderType() {
    if (item.type === "document") {
      return <DocumentType item={item} indexMap={indexMap} indent={indent} />;
    }
    if (item.type === "chapter") {
      return <ChapterType item={item} indexMap={indexMap} indent={indent} />;
    }
    if (item.type === "part") {
      return <PartType item={item} indexMap={indexMap} indent={indent} />;
    }
  }

  return <>{RenderType()}</>;
};

const DocumentType = ({ item, indexMap, indent }) => {
  const {
    project,
    navigate,
    location,
    flattenedProjectContents,
    projectNavState,
    setProjectNavState,
  } = useContext(MsNavContext);
  const { currentDoc, setCurrentDocumentHeadings } = useContext(Scribi);

  const inputRef = React.useRef(null);

  const { setNodeRef, attributes, listeners, isDragging, transform } =
    useDraggable({
      id: item.id,
      data: {
        type: item.type,
        heading: renderDefaultHeading(),
        indexMap: indexMap,
        indent: indent,
        context: "manuscript-draggable",
      },
    });

  const [editing, setEditing] = useState(false);
  const [newHeading, setNewHeading] = useState(item.heading || "");
  useEffect(() => {
    if (editing && inputRef.current) {
      inputRef.current.focus(); // Focus on the input when editing is true
      inputRef.current.select(); // Selects all text in the input field

      // Function to handle loss of focus
      const handleBlur = () => {
        setEditing(false);
      };

      // Add blur event listener to input element
      inputRef.current.addEventListener("blur", handleBlur);

      // Cleanup: remove event listener when component unmounts or when editing becomes false
      return () => {
        if (inputRef.current) {
          inputRef.current.removeEventListener("blur", handleBlur);
        }
      };
    }
  }, [editing]); // Dependency array: re-run the effect if 'editing' changes

  function renderDefaultHeading() {
    if (item.heading.trim().length === 0) {
      return `Document ${
        flattenedProjectContents
          .filter((i) => i.type === "document")
          .findIndex((i) => i.id === item.id) + 1
      }`;
    } else {
      return item.heading;
    }
  }

  return (
    <NavItem
      onClick={() => {
        let newProjectNavState = JSON.parse(JSON.stringify(projectNavState));
        newProjectNavState.selected = item.id;
        setProjectNavState(newProjectNavState);
      }}
      selected={projectNavState.selected === item.id}
      ref={setNodeRef}
      isDragging={isDragging}
      {...attributes}
      {...listeners}
      style={{
        opacity: isDragging ? 0 : 1,
        paddingLeft: indent ? `${indent}px` : "0px",
      }}
    >
      <div className="icon">
        <Icons.DocumentIcon />
      </div>
      {editing ? (
        <FormInput
          ref={inputRef}
          type="text"
          className="title"
          value={newHeading}
          onChange={(e) => setNewHeading(e.target.value)}
          onKeyUp={async (e) => {
            if (e.key === "Enter") {
              setEditing(false);
              let newProject = JSON.parse(JSON.stringify(project));
              //Find the document in the project.contents using item.id, the document may be nested inside other folders:
              const updateHeading = (contents) => {
                for (let i = 0; i < contents.length; i++) {
                  const content = contents[i];
                  if (content.id === item.id) {
                    content.heading = newHeading;
                  }
                  if (content.children && content.children.length > 0) {
                    updateHeading(content.children);
                  }
                }
              };
              updateHeading(newProject.contents);
              if (currentDoc.id === item.id) {
                setCurrentDocumentHeadings([newHeading]);
              }
              await updateScribiDocument(newProject);
            }
            if (e.key === "Escape") {
              setEditing(false);
              setNewHeading(item.heading || "");
            }
          }}
        />
      ) : (
        <label className="title" onDoubleClick={() => setEditing(true)}>
          {renderDefaultHeading()}
        </label>
      )}
    </NavItem>
  );
};

const ChapterType = ({ item, indexMap, indent }) => {
  const {
    project,
    navigate,
    location,
    flattenedProjectContents,
    projectNavState,
    setProjectNavState,
  } = useContext(MsNavContext);

  const { active } = useDndContext();
  const [editing, setEditing] = useState(false);
  const [newHeading, setNewHeading] = useState(item.heading || "");
  const inputRef = React.useRef(null);
  const reject = ["chapter", "part"];

  const {
    setNodeRef: setDragRef,
    attributes,
    listeners,
    isDragging,
    transform,
  } = useDraggable({
    id: item.id,
    data: {
      type: item.type,
      heading: renderDefaultHeading(),
      indexMap: indexMap,
      indent: indent,
      context: "manuscript-draggable",
    },
  });

  const { setNodeRef: setDropRef, isOver } = useDroppable({
    id: item.id,
    data: {
      reject: ["chapter", "part"],
      type: item.type,
      indexMap: indexMap,
      context: "manuscript-droppable",
    },
  });

  useEffect(() => {
    if (editing && inputRef.current) {
      inputRef.current.focus(); // Focus on the input when editing is true
      inputRef.current.select(); // Selects all text in the input field

      // Function to handle loss of focus
      const handleBlur = () => {
        setEditing(false);
      };

      // Add blur event listener to input element
      inputRef.current.addEventListener("blur", handleBlur);

      // Cleanup: remove event listener when component unmounts or when editing becomes false
      return () => {
        if (inputRef.current) {
          inputRef.current.removeEventListener("blur", handleBlur);
        }
      };
    }
  }, [editing]);

  function renderDefaultHeading() {
    return `Chapter ${
      flattenedProjectContents
        .filter((i) => i.type === "chapter")
        .findIndex((i) => i.id === item.id) + 1
    }${item.heading.trim().length > 0 ? `: ${item.heading}` : ""}`;
  }
  return (
    <div ref={setDropRef}>
      <NavItem
        ref={setDragRef}
        isDragging={isDragging}
        {...attributes}
        {...listeners}
        style={{
          opacity: isDragging ? 0 : 1,
          backgroundColor:
            isOver && !reject.includes(active.data.current.type)
              ? "lightgray"
              : "transparent",
          paddingLeft: indent ? `${indent}px` : "0px",
        }}
      >
        <div className="icon">
          <Icons.ChapterIcon />
        </div>
        {editing ? (
          <FormInput
            ref={inputRef}
            type="text"
            className="title"
            value={newHeading}
            onChange={(e) => setNewHeading(e.target.value)}
            onKeyUp={async (e) => {
              if (e.key === "Enter") {
                setEditing(false);
                let newProject = JSON.parse(JSON.stringify(project));
                //Find the document in the project.contents using item.id, the document may be nested inside other folders:
                const updateHeading = (contents) => {
                  for (let i = 0; i < contents.length; i++) {
                    const content = contents[i];
                    if (content.id === item.id) {
                      content.heading = newHeading;
                    }
                    if (content.contents && content.contents.length > 0) {
                      updateHeading(content.contents);
                    }
                  }
                };
                updateHeading(newProject.contents);
                await updateScribiDocument(newProject);
              }
              if (e.key === "Escape") {
                setEditing(false);
                setNewHeading(item.heading || "");
              }
            }}
          />
        ) : (
          <label className="title" onDoubleClick={() => setEditing(true)}>
            {renderDefaultHeading()}
          </label>
        )}
      </NavItem>
      {item.children.map((child, index) => (
        <React.Fragment key={child.id}>
          {index > 0 ? null : (
            <DroppableDivider
              id={`before:${child.id}`}
              reject={[child.id]}
              indexMap={[...indexMap, index]}
              indent={indent + 20}
            />
          )}
          <RenderMsItem
            item={child}
            key={index}
            indexMap={[...indexMap, index]}
            indent={indent + 20}
          />
          <DroppableDivider
            id={`after:${child.id}`}
            reject={[child.id, item?.children[index + 1]?.id]}
            indexMap={[...indexMap, index + 1]}
            indent={indent + 20}
          />
        </React.Fragment>
      ))}
    </div>
  );
};

const PartType = ({ item, indexMap }) => {
  const {
    project,
    navigate,
    location,
    flattenedProjectContents,
    projectNavState,
    setProjectNavState,
  } = useContext(MsNavContext);

  const { setContextMenu } = useContext(Scribi);

  const inputRef = React.useRef(null);
  const labelRef = React.useRef(null);
  const [editing, setEditing] = useState(false);
  const [newHeading, setNewHeading] = useState(item.heading || "");
  const { active } = useDndContext();
  const reject = ["part"];

  const {
    setNodeRef: setDragRef,
    attributes,
    listeners,
    isDragging,
    transform,
  } = useDraggable({
    id: item.id,
    data: {
      type: item.type,
      heading: renderDefaultHeading(),
      indexMap: indexMap,
      indent: null,
      context: "manuscript-draggable",
    },
  });

  const { setNodeRef: setDropRef, isOver } = useDroppable({
    id: item.id,
    data: {
      type: item.type,
      reject: ["part"],
      indexMap: indexMap,
      context: "manuscript-droppable",
    },
  });

  useEffect(() => {
    if (editing && inputRef.current) {
      inputRef.current.focus(); // Focus on the input when editing is true
      inputRef.current.select(); // Selects all text in the input field

      // Function to handle loss of focus
      const handleBlur = () => {
        setEditing(false);
      };

      // Add blur event listener to input element
      inputRef.current.addEventListener("blur", handleBlur);

      // Cleanup: remove event listener when component unmounts or when editing becomes false
      return () => {
        if (inputRef.current) {
          inputRef.current.removeEventListener("blur", handleBlur);
        }
      };
    }
  }, [editing]);

  function renderDefaultHeading() {
    return `Part ${
      flattenedProjectContents
        .filter((i) => i.type === "part")
        .findIndex((i) => i.id === item.id) + 1
    }${item.heading.trim().length > 0 ? `: ${item.heading}` : ""}`;
  }

  return (
    <div ref={setDropRef}>
      <NavItem
        ref={setDragRef}
        isDragging={isDragging}
        {...attributes}
        {...listeners}
        style={{
          opacity: isDragging ? 0 : 1,
          backgroundColor:
            isOver && !reject.includes(active.data.current.type)
              ? "lightgray"
              : "transparent",
        }}
        onContextMenu={(e) => {
          e.preventDefault();

          setContextMenu({
            visible: true,
            x: labelRef.current.getBoundingClientRect().left,
            y: labelRef.current.getBoundingClientRect().bottom,
            menu: "partMenu",
            context: {
              id: item.id,
            },
          });
        }}
      >
        <div className="icon">
          <Icons.PartIcon />
        </div>
        {editing ? (
          <FormInput
            ref={inputRef}
            type="text"
            className="title"
            value={newHeading}
            onChange={(e) => setNewHeading(e.target.value)}
            onKeyUp={async (e) => {
              if (e.key === "Enter") {
                setEditing(false);
                let newProject = JSON.parse(JSON.stringify(project));
                //Find the document in the project.contents using item.id, the document may be nested inside other folders:
                const updateHeading = (contents) => {
                  for (let i = 0; i < contents.length; i++) {
                    const content = contents[i];
                    if (content.id === item.id) {
                      content.heading = newHeading;
                    }
                    if (content.contents && content.contents.length > 0) {
                      updateHeading(content.contents);
                    }
                  }
                };
                updateHeading(newProject.contents);
                await updateScribiDocument(newProject);
              }
              if (e.key === "Escape") {
                setEditing(false);
                setNewHeading(item.heading || "");
              }
            }}
          />
        ) : (
          <label
            ref={labelRef}
            className="title"
            onDoubleClick={() => setEditing(true)}
          >
            {renderDefaultHeading()}
          </label>
        )}
      </NavItem>
      {item.children.map((item, index) => (
        <RenderMsItem
          item={item}
          key={index}
          indexMap={[...indexMap, index]}
          indent={20}
        />
      ))}
    </div>
  );
};
