import React, { useContext, useState, useEffect, useRef } from "react";
import { Scribi } from "../utilities/api/store/store";
import { v4 as uuidv4 } from "uuid";
import {
  ActionButton,
  AutoTextArea,
  BodyContainer,
  CancelButton,
  ConfirmButton,
  DropArea,
  FormContainer,
  FormGrid,
  FormGridHeading,
  FormInput,
  FormLabel,
  FormRow,
  FormSection,
  FormSectionTitle,
  FormSelect,
  FormTextArea,
  FormTitle,
  HrDivider,
  IconButton,
  Tag,
} from "../Scribi-Components/ScribiComponents";
import Icons from "../Icons/Icons";
import { useImageUpload } from "../utilities/hooks/UseImageUpload";
import {
  createScribiDocument,
  updateScribiDocument,
} from "../utilities/firestore/db";
import ScribiRestService from "../utilities/firestore/ScribiRestService";

const ManageSeries = () => {
  const {
    location,
    modal,
    setModal,
    pens,
    worlds,
    series,
    books,
    user,
    deleteSeries,
    navigate,
  } = useContext(Scribi);
  const seriesId = location.state?.series?.id ? location.state.series.id : null;
  const [currentSeries, setCurrentSeries] = useState(
    location.state?.series
      ? location.state.series
      : {
          title: "",
          id: uuidv4(),
          ownerId: user.uid,
          created: Date.now(),
          editors: [],
          viewers: [],
          notes: [],
          public: false,
          logo: "",
          plan: "",
          description: "",
          world: "",
          pen: "",
          tags: [],
          doctype: "series",
        }
  );
  const [newPen, setNewPen] = useState({
    id: uuidv4(),
    ownerId: user.uid,
    created: Date.now(),
    editors: [],
    viewers: [],
    fullName: "",
    firstName: "",
    lastName: "",
    description: "",
    authorBio: "",
    authorPhoto: "",
    authorLogo: "",
    links: [],
    public: false,
    doctype: "pens",
    notes: [],
    tags: [],
  });
  const [newTag, setNewTag] = useState("");
  const [newWorld, setNewWOrld] = useState({
    id: uuidv4(),
    ownerId: user.uid,
    title: "",
    plan: "",
    description: "",
    editors: [],
    viewers: [],
    public: false,
    pen: "",
    doctype: "worlds",
    created: Date.now(),
    tags: [],
    schema: {
      title: "",
      description: "",
      types: [
        {
          label: "Characters",
          subtypes: [],
          attributes: [],
        },
        {
          label: "Locations",
          subtypes: [],
          attributes: [],
        },
      ],
    },
    notes: [],
    logo: null,
  });
  const [logo, setLogo] = useState(null);
  const { getRootProps, getInputProps } = useImageUpload(setLogo);

  useEffect(() => {
    if (location.state) {
      setCurrentSeries({
        title: location.state.series.title || "",
        id: location.state.series.id || uuidv4(),
        ownerId: location.state.series.ownerId || user.uid,
        created: location.state.series.created || Date.now(),
        editors: location.state.series.editors || [],
        viewers: location.state.series.viewers || [],
        notes: location.state.series.notes || [],
        public: location.state.series.public || false,
        logo: location.state.series.logo || "",
        plan: location.state.series.plan || "",
        description: location.state.series.description || "",
        world: location.state.series.world || "",
        pen: location.state.series.pen || "",
        tags: location.state.series.tags || [],
        doctype: "series",
      });
    } else {
      setCurrentSeries({
        title: "",
        id: uuidv4(),
        ownerId: user.uid,
        created: Date.now(),
        editors: [],
        viewers: [],
        notes: [],
        public: false,
        logo: "",
        plan: "",
        description: "",
        world: "",
        pen: "",
        tags: [],
        doctype: "series",
      });
    }
  }, [location.state]);

  const saveSeries = async () => {
    if (currentSeries.title.trim().length === 0) {
      setModal({
        visible: true,
        title: "Error",
        message: "Series Title is required.",
        confirm: () => {
          setModal({ visible: false, title: "", message: "", confirm: null });
        },
      });
      return;
    }
    if (currentSeries.pen.trim().length === 0) {
      setModal({
        visible: true,
        title: "Error",
        message: "Pen Name is required.",
        confirm: () => {
          setModal({ visible: false, title: "", message: "", confirm: null });
        },
      });
      return;
    }
    if (currentSeries.world.trim().length === 0) {
      setModal({
        visible: true,
        title: "Error",
        message: "World is required.",
        confirm: () => {
          setModal({ visible: false, title: "", message: "", confirm: null });
        },
      });
      return;
    }

    let newSeriesDoc = JSON.parse(JSON.stringify(currentSeries));
    let newPenDoc = JSON.parse(JSON.stringify(newPen));
    let newWorldDoc = JSON.parse(JSON.stringify(newWorld));

    if (currentSeries.pen === newPen.id) {
      newPenDoc.fullName = `${newPen.firstName}${
        newPen.lastName.trim().length > 0 ? ` ${newPen.lastName}` : ""
      }`;
      newWorldDoc.pen = newPen.id;
      let res = await createScribiDocument(newPenDoc);
      if (!res.isCreated) {
        setModal({
          visible: true,
          title: "Error",
          message: "Error creating new pen name.",
          confirm: () => {
            setModal({ visible: false, title: "", message: "", confirm: null });
          },
        });
        return;
      }
    }

    if (currentSeries.world === newWorld.id) {
      let res = await createScribiDocument(newWorldDoc);
      if (newSeriesDoc.pen !== newPen.id) {
        newWorldDoc.pen = newSeriesDoc.pen;
      }
      if (!res.isCreated) {
        setModal({
          visible: true,
          title: "Error",
          message: "Error creating new world.",
          confirm: () => {
            setModal({ visible: false, title: "", message: "", confirm: null });
          },
        });
        return;
      }
    }

    if (logo) {
      let logoUrl = await ScribiRestService.uploadImage(logo);
      newSeriesDoc.logo = logoUrl;
    }

    if (seriesId) {
      let res = await updateScribiDocument(newSeriesDoc);
      if (res.isUpdated) {
        setModal({
          visible: true,
          title: "Success",
          message: "Series updated successfully.",
          confirm: () => {
            setModal({ visible: false, title: "", message: "", confirm: null });
            navigate("/", { state: { series: currentSeries } });
          },
        });
      } else {
        setModal({
          visible: true,
          title: "Error",
          message: "Error updating series.",
          confirm: () => {
            setModal({ visible: false, title: "", message: "", confirm: null });
          },
        });
      }
    } else {
      let res = await createScribiDocument(newSeriesDoc);
      if (res.isCreated) {
        setModal({
          visible: true,
          title: "Success",
          message: "Series created successfully.",
          confirm: () => {
            setModal({ visible: false, title: "", message: "", confirm: null });
            navigate("/", { state: { series: currentSeries } });
          },
        });
      } else {
        setModal({
          visible: true,
          title: "Error",
          message: "Error creating series.",
          confirm: () => {
            setModal({ visible: false, title: "", message: "", confirm: null });
          },
        });
      }
    }
  };

  return (
    <BodyContainer>
      <FormContainer>
        <FormSection>
          <FormRow>
            <FormTitle>
              {seriesId ? `Manage ${currentSeries.title}` : "Create New Series"}
            </FormTitle>
            {seriesId && (
              <CancelButton
                onClick={() => {
                  deleteSeries(currentSeries);
                }}
                style={{ marginLeft: "auto" }}
              >
                Delete Series
              </CancelButton>
            )}
          </FormRow>
          <HrDivider />
          <FormGrid
            columns="min-content 1fr"
            style={{ alignItems: "flex-start" }}
          >
            <FormLabel>Series Title:</FormLabel>
            <FormInput
              type="text"
              placeholder="Series Title is required"
              value={currentSeries.title}
              onChange={(e) => {
                setCurrentSeries({ ...currentSeries, title: e.target.value });
              }}
            />
            <FormLabel>Series Plan:</FormLabel>
            <AutoTextArea
              placeholder={"Your plan for the series"}
              value={currentSeries.plan}
              setValue={(value) => {
                setCurrentSeries({ ...currentSeries, plan: value });
              }}
            />
            <FormLabel>Series Description:</FormLabel>
            <AutoTextArea
              placeholder={"A reader-facing description of your series"}
              value={currentSeries.description}
              setValue={(value) => {
                setCurrentSeries({ ...currentSeries, description: value });
              }}
            />
            <FormLabel>Tags:</FormLabel>
            <FormInput
              type="text"
              placeholder="Press enter to add a tag"
              value={newTag}
              onChange={(e) => setNewTag(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  const newTags = JSON.parse(
                    JSON.stringify(currentSeries.tags)
                  );
                  newTags.push(newTag.toLowerCase());
                  setCurrentSeries({ ...currentSeries, tags: newTags });
                  setNewTag("");
                }
              }}
            />
            {currentSeries.tags.length > 0 && (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "5px",
                  gridColumn: "2",
                }}
              >
                {currentSeries.tags.map((tag, i) => (
                  <Tag
                    key={i}
                    tag={tag}
                    index={i}
                    state={currentSeries}
                    setState={setCurrentSeries}
                  />
                ))}
              </div>
            )}
            <HrDivider style={{ gridColumn: "1 / span 2" }} />
            <FormLabel>Pen Name:</FormLabel>
            <FormSelect
              value={currentSeries.pen}
              onChange={(e) => {
                setCurrentSeries({ ...currentSeries, pen: e.target.value });
              }}
            >
              <option value="">Select a Pen</option>
              {pens.map((pen) => {
                return (
                  <option key={pen.id} value={pen.id}>
                    {pen.fullName}
                  </option>
                );
              })}
              <option value={newPen.id}>Create New Pen Name</option>
            </FormSelect>
            {currentSeries.pen === newPen.id && (
              <>
                <FormLabel>Pen First Name:</FormLabel>
                <FormInput
                  type="text"
                  value={newPen.firstName}
                  onChange={(e) => {
                    setNewPen({ ...newPen, firstName: e.target.value });
                  }}
                />
                <FormLabel>Pen Last Name:</FormLabel>
                <FormInput
                  type="text"
                  value={newPen.lastName}
                  onChange={(e) => {
                    setNewPen({ ...newPen, lastName: e.target.value });
                  }}
                />
              </>
            )}
            <FormLabel>Story World:</FormLabel>
            <FormSelect
              value={currentSeries.world}
              onChange={(e) => {
                setCurrentSeries({ ...currentSeries, world: e.target.value });
              }}
            >
              <option value="">Select a World</option>
              {worlds
                .filter((world) => world.pen === currentSeries.pen)
                .map((world) => (
                  <option key={world.id} value={world.id}>
                    {world.title}
                  </option>
                ))}
              <option value={newWorld.id}>Create New World</option>
            </FormSelect>
            {currentSeries.world === newWorld.id && (
              <>
                <FormLabel>World Title:</FormLabel>
                <FormInput
                  type="text"
                  value={newWorld.title}
                  onChange={(e) => {
                    setNewWOrld({ ...newWorld, title: e.target.value });
                  }}
                />
              </>
            )}
            <HrDivider style={{ gridColumn: "1 / span 2" }} />
            <FormLabel>Series Notes:</FormLabel>
            {currentSeries.notes.map((note, index) => (
              <Note
                note={note}
                key={index}
                index={index}
                notes={currentSeries.notes}
                setCurrentSeries={setCurrentSeries}
                currentSeries={currentSeries}
                deleteNote={(index) => {
                  let newCurrentSeries = JSON.parse(
                    JSON.stringify(currentSeries)
                  );
                  newCurrentSeries.notes.splice(index, 1);
                  setCurrentSeries(newCurrentSeries);
                }}
              />
            ))}
            <IconButton
              onClick={() => {
                let newCurrentSeries = JSON.parse(
                  JSON.stringify(currentSeries)
                );
                if (!newCurrentSeries.notes) {
                  newCurrentSeries.notes = [];
                }
                newCurrentSeries.notes.push("");
                setCurrentSeries(newCurrentSeries);
              }}
              style={{
                justifySelf: "right",
                alignSelf: "center",
                gridColumn: 2,
              }}
              title="Add note to world"
            >
              <Icons.AddIcon />
            </IconButton>
            <HrDivider style={{ gridColumn: "1 / span 2" }} />
            <FormLabel>Series Logo:</FormLabel>
            <DropArea
              {...getRootProps()}
              orientation="portrait"
              style={{ justifySelf: "center", alignSelf: "center" }}
            >
              <input {...getInputProps()} />
              {logo ? (
                <img src={logo} alt="Series logo" />
              ) : currentSeries.logo ? (
                <img src={currentSeries.logo} alt="Series Logo" />
              ) : (
                "Click to upload, or drop image here"
              )}
            </DropArea>
            {(logo || currentSeries.logo !== 0) && (
              <ActionButton
                style={{ gridColumn: "2", justifySelf: "center" }}
                onClick={() => {
                  setCurrentSeries({ ...currentSeries, logo: "" });
                  setLogo(null);
                }}
              >
                Clear Image
              </ActionButton>
            )}
          </FormGrid>
          <FormRow justifyContent="right">
            <ConfirmButton type="button" onClick={saveSeries}>
              {seriesId ? "Update Series" : "Create Series"}
            </ConfirmButton>
            <CancelButton
              type="button"
              onClick={() => navigate("/", { state: null })}
            >
              Cancel
            </CancelButton>
          </FormRow>
        </FormSection>
      </FormContainer>
    </BodyContainer>
  );
};

export default ManageSeries;

function Note({
  note,
  index,
  notes,
  setCurrentSeries,
  currentSeries,
  deleteNote,
}) {
  return (
    <div
      style={{
        gridColumn: 2,
        display: "grid",
        gridTemplateColumns: "1fr min-content",
        gap: "10px",
      }}
    >
      <AutoTextArea
        value={note}
        setValue={(value) => {
          let newNotes = JSON.parse(JSON.stringify(notes));
          newNotes[index] = value;
          setCurrentSeries({ ...currentSeries, notes: newNotes });
        }}
        placeholder="Make a note here..."
      />
      <IconButton onClick={() => deleteNote(index)} title="Delete note">
        <Icons.DeleteIcon />
      </IconButton>
    </div>
  );
}
