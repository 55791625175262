/* eslint-disable no-unused-vars */
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, onAuthStateChanged } from "firebase/auth";
import {
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager,
} from "@firebase/firestore";
import { getStorage } from "@firebase/storage";
import {
  getDatabase,
  ref,
  onDisconnect,
  onValue,
  serverTimestamp,
  set,
} from "firebase/database";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  api: process.env.REACT_APP_CLOUD_FUNCTION_API_URL,
  clientId: process.env.REACT_APP_GAPI_CLIENT_ID,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export const db = initializeFirestore(app, {
  localCache: persistentLocalCache({
    tabManager: persistentMultipleTabManager(),
  }),
});
export const storage = getStorage(app);
export const RTDatabase = getDatabase(app);

onAuthStateChanged(auth, (user) => {
  if (user) {
    const userId = user.uid;
    const userStatusDatabaseRef = ref(RTDatabase, `/presence/${userId}`);

    // Set initial status
    set(userStatusDatabaseRef, {
      isOnline: true,
    });

    // Set up onDisconnect
    onDisconnect(userStatusDatabaseRef)
      .set({
        isOnline: false,
      })
      .then(() => {
        set(userStatusDatabaseRef, {
          isOnline: true,
        });
      });

    const specificUserRef = ref(RTDatabase, `presence/${userId}`);

    onValue(specificUserRef, (snapshot) => {
      const status = snapshot.val();
      console.log(
        `User ${userId} is ${status.isOnline ? "online" : "offline"}`
      );
    });
  }
});
