import React from "react";
import StorybaseSidebar from "./StorybaseSidebar";
import styled from "styled-components";
import Icons from "../../Icons/Icons";
import { IconButton } from "../../Scribi-Components/ScribiComponents";
import ElementDisplay from "./ElementDisplay";
import { Scribi } from "../../utilities/api/store/store";

const TabContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 5px;
  gap: 1px;
  border-bottom: 2px solid ${({ theme }) => theme.primary};
`;

const TabButton = styled(IconButton)`
  padding: 5px;
  border-radius: 5px 5px 0px 0px;
  background-color: ${({ theme, active }) =>
    active ? theme.tertiary : theme.primary};
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: ${({ theme }) => theme.textLightColor};
  svg {
    height: 15px;
    width: 15px;
  }
`;

const ProjectSidebar = () => {
  const { project } = React.useContext(Scribi);
  const [tab, setTab] = React.useState(
    project?.standalone === "pen" ? "book" : "storybase"
  );
  const standalone = project?.standalone || false;
  const [element, setElement] = React.useState(null);
  return (
    <>
      <TabContainer>
        {!standalone && (
          <TabButton
            className="tab"
            onClick={() => setTab("storybase")}
            active={tab === "storybase"}
          >
            <Icons.StorybaseIcon />
            Storybase
          </TabButton>
        )}
        <TabButton
          className="tab"
          onClick={() => setTab("book")}
          active={tab === "book"}
        >
          <Icons.BookIcon />
          Book
        </TabButton>
        <TabButton
          className="tab"
          onClick={() => setTab("chapter")}
          active={tab === "chapter"}
        >
          <Icons.ChapterIcon />
          Chapter
        </TabButton>
        <TabButton
          className="tab"
          onClick={() => setTab("element")}
          active={tab === "element"}
        >
          <Icons.UserIcon />
          Element
        </TabButton>
      </TabContainer>
      {tab === "storybase" && (
        <StorybaseSidebar
          filter={"world"}
          setTab={setTab}
          setElement={setElement}
        />
      )}
      {tab === "book" && (
        <StorybaseSidebar
          filter={"book"}
          setTab={setTab}
          setElement={setElement}
        />
      )}
      {tab === "chapter" && (
        <StorybaseSidebar
          filter={"chapter"}
          setTab={setTab}
          setElement={setElement}
        />
      )}
      {tab === "element" && (
        <ElementDisplay
          element={element}
          setTab={setTab}
          setElement={setElement}
        />
      )}
    </>
  );
};

export default ProjectSidebar;
