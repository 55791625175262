/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import {
  ActionButton,
  CancelButton,
  FormRow,
  FormSelect,
  IconButton,
  TimerContainer,
  WorkAppHeader,
} from "../Scribi-Components/ScribiComponents";
import Icons from "../Icons/Icons";
import Search from "../Search/Search";
import { Scribi } from "../utilities/api/store/store";
import { createScribiSprintTimer } from "../utilities/firestore/db";
import { RTDatabase } from "../utilities/firestore/cloud-configs";
import { ref, remove, set, update } from "firebase/database";

const WorkHeader = () => {
  const {
    settings,
    showUserMenu,
    setShowUserMenu,
    user,
    navigate,
    joinedSprintTimer,
    timer,
    groupSprints,
    sprintStats,
    setFormModal,
  } = React.useContext(Scribi);

  const [validSprintList, setValidSprintList] = useState([]);
  const [editingSprint, setEditingSprint] = useState(false);
  const [selectedSprint, setSelectedSprint] = useState("");

  useEffect(() => {
    setValidSprintList(groupSprints.filter((g) => g.ownerId === user.uid));
  }, [groupSprints, user.uid]);

  useEffect(() => {
    if (joinedSprintTimer) {
      setEditingSprint(false);
    }
  }, [joinedSprintTimer]);

  useEffect(() => {
    if (selectedSprint === "") {
      return;
    }
    if (selectedSprint === "new") {
      setFormModal({
        visible: true,
        component: "sprintTimer",
        callbackAction: () => setSelectedSprint(""),
      });
    }
    if (
      selectedSprint !== "new" &&
      selectedSprint !== "" &&
      !joinedSprintTimer
    ) {
      let sprintDocument = groupSprints.find((s) => s.id === selectedSprint);
      // Perform deep copy
      let newSprintDoc = JSON.parse(JSON.stringify(sprintDocument));

      // Set new status and currentStep
      newSprintDoc.status = "ready";
      newSprintDoc.currentStep = 0;
      try {
        set(ref(RTDatabase, "sprints/" + selectedSprint), newSprintDoc);
        setEditingSprint(false);
        setSelectedSprint("");
      } catch (error) {
        console.log(error);
      }
    }
  }, [selectedSprint]);

  return (
    <WorkAppHeader className="work-header">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "left",
          gridColumn: "1",
          gap: "20px",
        }}
      >
        <IconButton
          className="work-header-button"
          onClick={() => navigate("/")}
        >
          <Icons.HomeIcon />
        </IconButton>
        <IconButton className="work-header-button">
          <Icons.MessagesIcon />
        </IconButton>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gridColumn: "2",
          gap: "20px",
        }}
      >
        <Search />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "right",
          gridColumn: "3",
          gap: "20px",
          position: "relative",
        }}
      >
        {!joinedSprintTimer && !editingSprint && (
          <IconButton
            className={"work-header-button"}
            style={{ marginRight: "auto" }}
            onClick={() => setEditingSprint(true)}
          >
            <Icons.TimerIcon />
          </IconButton>
        )}
        {joinedSprintTimer && <SprintTimer />}
        {editingSprint && !joinedSprintTimer && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
              marginRight: "auto",
            }}
          >
            <FormSelect
              value={selectedSprint}
              onChange={(e) => setSelectedSprint(e.target.value)}
              style={{
                width: "300px",
                maxWidth: "300px",
                minWidth: "300px",
              }}
            >
              <option value={""}>Select Sprint</option>
              {validSprintList.map((sprint, i) => (
                <option key={i} value={sprint.id}>
                  {sprint.title}
                </option>
              ))}
              <option value={"new"}>New Sprint</option>
            </FormSelect>
            <CancelButton onClick={() => setEditingSprint(false)}>
              Cancel
            </CancelButton>
          </div>
        )}
        <IconButton
          className={"work-header-button"}
          onClick={() => navigate("/pen-name", { state: null })}
        >
          <Icons.PenIcon />
        </IconButton>
        <IconButton
          className={"work-header-button"}
          onClick={() => {
            navigate("/story-world", {
              state: null,
            });
          }}
        >
          <Icons.WorldIcon />
        </IconButton>
        <IconButton
          className={"work-header-button"}
          onClick={() => {
            navigate("/book-series", {
              state: null,
            });
          }}
        >
          <Icons.SeriesIcon />
        </IconButton>
        <IconButton
          className={"work-header-button"}
          onClick={() => {
            navigate("/book", {
              state: null,
            });
          }}
        >
          <Icons.BookIcon />
        </IconButton>
        <IconButton
          className={"work-header-button"}
          onClick={(e) => {
            setShowUserMenu(!showUserMenu);
          }}
        >
          <img
            src={settings?.photoURL ? settings.photoURL : user.photoURL}
            referrerPolicy="no-referrer"
            alt="user"
          />
        </IconButton>
      </div>
    </WorkAppHeader>
  );
};

export default WorkHeader;

const SprintTimer = () => {
  const {
    joinedSprintTimer,
    timer,
    groupSprints,
    sprintStats,
    user,
    setModal,
  } = React.useContext(Scribi);

  const [timerState, setTimerState] = useState({});
  const lastTime = useRef("");

  useEffect(() => {
    let timerInterval;
    if (joinedSprintTimer && !timerState.currentTime) {
      let savedTimerState = JSON.parse(localStorage.getItem("timerState"));
      if (savedTimerState) {
        setTimerState(savedTimerState);
      } else {
        setTimerState({
          currentTime: Date.now(),
          endTime:
            Date.now() +
            joinedSprintTimer.schedule[joinedSprintTimer.currentStep].duration,
          state: "ready",
          type: joinedSprintTimer.schedule[joinedSprintTimer.currentStep].type,
          remaining:
            joinedSprintTimer.schedule[joinedSprintTimer.currentStep].duration,
          elapsed: 0,
        });
      }
    }
    if (joinedSprintTimer && joinedSprintTimer.status === "running") {
      timerInterval = setInterval(() => {
        setTimerState((prevState) => ({
          currentTime: Date.now(),
          endTime:
            prevState.state === "ready" || prevState.state === "paused"
              ? Date.now() + prevState.remaining
              : prevState.endTime,
          state: "running",
          type: prevState.type,
          remaining:
            prevState.state === "ready" || prevState.state === "paused"
              ? prevState.remaining
              : prevState.endTime - Date.now(),
          elapsed:
            prevState.state === "ready" || prevState.state === "paused"
              ? 0
              : Date.now() - prevState.endTime,
        }));
      }, 1000);
    } else if (joinedSprintTimer && joinedSprintTimer.status === "paused") {
      setTimerState((prevState) => ({
        ...prevState,
        state: "paused",
      }));
    }

    return () => {
      clearInterval(timerInterval);
    };
  }, [joinedSprintTimer]);

  useEffect(() => {
    if (timerState) {
      if (timerState.remaining <= 0) {
        let nextStep =
          joinedSprintTimer.schedule[joinedSprintTimer.currentStep + 1];
        if (nextStep) {
          update(ref(RTDatabase, "sprints/" + joinedSprintTimer.id), {
            currentStep: joinedSprintTimer.currentStep + 1,
          });
          lastTime.current = "";
        } else {
          update(ref(RTDatabase, "sprints/" + joinedSprintTimer.id), {
            status: "complete",
          });
          localStorage.removeItem("timerState");
          lastTime.current = "";
        }
      }
      if (joinedSprintTimer.status !== "complete") {
        localStorage.setItem("timerState", JSON.stringify(timerState));
      }
    }
  }, [timerState]);

  useEffect(() => {
    if (joinedSprintTimer) {
      if (joinedSprintTimer.status === "ready") {
        setTimerState({
          currentTime: Date.now(),
          endTime:
            Date.now() +
            joinedSprintTimer.schedule[joinedSprintTimer.currentStep].duration,
          state: "ready",
          type: joinedSprintTimer.schedule[joinedSprintTimer.currentStep].type,
          remaining:
            joinedSprintTimer.schedule[joinedSprintTimer.currentStep].duration,
          elapsed: 0,
        });
      }
    }
  }, [joinedSprintTimer.status]);

  useEffect(() => {
    if (joinedSprintTimer && joinedSprintTimer.currentStep > 0) {
      setTimerState({
        currentTime: Date.now(),
        endTime:
          Date.now() +
          joinedSprintTimer.schedule[joinedSprintTimer.currentStep].duration,
        state: "ready",
        type: joinedSprintTimer.schedule[joinedSprintTimer.currentStep].type,
        remaining:
          joinedSprintTimer.schedule[joinedSprintTimer.currentStep].duration,
        elapsed: 0,
      });
    }
  }, [joinedSprintTimer.currentStep]);

  function renderTime() {
    const totalSeconds = Math.floor(timerState.remaining / 1000);
    let minutes = Math.floor(totalSeconds / 60);
    let seconds = totalSeconds % 60;
    let lastMinutes = parseInt(lastTime.current.split(":")[0]);
    let lastSeconds = parseInt(lastTime.current.split(":")[1]);

    let currentTime = `${String(minutes).padStart(2, "0")}:${String(
      seconds
    ).padStart(2, "0")}`;

    if (
      currentTime === lastTime.current &&
      joinedSprintTimer.status === "running"
    ) {
      if (seconds === 0) {
        seconds = 59;
        if (minutes > 0) {
          minutes--;
        }
      } else {
        seconds--;
      }
      currentTime = `${String(minutes).padStart(2, "0")}:${String(
        seconds
      ).padStart(2, "0")}`;
    }
    if (
      lastTime.current !== "" &&
      seconds > lastSeconds &&
      minutes === lastMinutes
    ) {
      let adjustedSeconds = lastSeconds - 1 < 0 ? 59 : lastSeconds - 1;
      let adjustedMinutes = adjustedSeconds === 59 ? lastMinutes - 1 : minutes;
      if (adjustedMinutes < 0) {
        adjustedMinutes = 0;
        adjustedSeconds = 0;
      }
      currentTime = `${String(adjustedMinutes).padStart(2, "0")}:${String(
        adjustedSeconds
      ).padStart(2, "0")}`;
    }

    // Ensure minimum display is 00:00
    if (minutes <= 0 && seconds <= 0) {
      currentTime = "00:00";
    }
    if (minutes < 0) {
      currentTime = "00:00";
    }
    if (
      joinedSprintTimer.status === "ready" ||
      joinedSprintTimer.status === "paused"
    ) {
      lastTime.current = `${currentTime.split(":")[0]}:${String(
        parseInt(currentTime.split(":")[1]) + 1
      ).padStart(2, "0")}`;
    } else {
      lastTime.current = currentTime;
    }

    return currentTime;
  }

  const cancelSprint = async () => {
    const sprintRef = ref(RTDatabase, `sprints/${joinedSprintTimer.id}`);
    update(sprintRef, {
      status: "complete",
    });
  };

  const clearSprint = async () => {
    const sprintRef = ref(RTDatabase, `sprints/${joinedSprintTimer.id}`);
    update(sprintRef, {
      status: "finished",
    });
  };

  const startSprint = async () => {
    const sprintRef = ref(RTDatabase, `sprints/${joinedSprintTimer.id}`);
    update(sprintRef, {
      status: "running",
    });
  };

  const pauseSprint = async () => {
    const sprintRef = ref(RTDatabase, `sprints/${joinedSprintTimer.id}`);
    update(sprintRef, {
      status: "paused",
    });
  };

  const resetSprint = async () => {
    const sprintRef = ref(RTDatabase, `sprints/${joinedSprintTimer.id}`);
    update(sprintRef, {
      status: "ready",
      currentStep: 0,
    });
  };

  function renderTimerState() {
    if (joinedSprintTimer.status === "ready") {
      return "Ready";
    }
    if (joinedSprintTimer.status === "paused") {
      return "Paused";
    }
    if (joinedSprintTimer.status === "finished") {
      return "Finished";
    }
    if (joinedSprintTimer.status === "running") {
      return "Running";
    }
    if (joinedSprintTimer.status === "complete") {
      return "Finished";
    }
  }

  function renderTask() {
    if (timerState.type === "sprint") {
      return "Sprint";
    }
    if (timerState.type === "shortbreak") {
      return "Short Break";
    }
    if (timerState.type === "longbreak") {
      return "Long Break";
    }
  }

  function renderButtons() {
    return (
      <FormRow>
        {joinedSprintTimer.status === "ready" ||
        joinedSprintTimer.status === "paused" ? (
          <IconButton size={15} onClick={() => startSprint()}>
            <Icons.PlayIcon />
          </IconButton>
        ) : (
          <IconButton size={15} onClick={() => pauseSprint()}>
            <Icons.PauseIcon />
          </IconButton>
        )}
        <IconButton
          size={15}
          onClick={() => {
            setModal({
              visible: true,
              title: "Cancel Sprint",
              message:
                "Are you sure you want to end this sprint early? You'll have to start over from the beginning if so. If you only wish to pause the timer, click the pause button instead.",
              confirm: () => {
                cancelSprint();
              },
              cancel: () => {
                setModal({
                  visible: false,
                  title: "",
                  message: "",
                  confirm: null,
                  cancel: null,
                });
              },
            });
          }}
        >
          <Icons.StopIcon />
        </IconButton>
      </FormRow>
    );
  }

  function renderResetClearButtons() {
    return (
      <FormRow>
        <IconButton size={15} onClick={resetSprint}>
          <Icons.ResetIcon />
        </IconButton>
        <IconButton size={15} onClick={clearSprint}>
          <Icons.CloseIcon />
        </IconButton>
      </FormRow>
    );
  }

  return (
    <TimerContainer>
      <label>{renderTimerState()}</label>
      <label style={{ whiteSpace: "nowrap" }}>{renderTime()}</label>
      <label style={{ whiteSpace: "nowrap" }}>{renderTask()}</label>
      {joinedSprintTimer.ownerId === user.uid &&
        joinedSprintTimer.status !== "complete" &&
        renderButtons()}
      {joinedSprintTimer.status === "complete" && renderResetClearButtons()}
    </TimerContainer>
  );
};
