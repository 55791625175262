import React from "react";
import {
  BodyContainer,
  FormTitle,
  HrDivider,
} from "../Scribi-Components/ScribiComponents";

const Support = () => {
  return (
    <BodyContainer>
      <div style={{ width: "100%", maxWidth: "1000px", margin: "0 auto" }}>
        <h2>
          <strong>Help and Support</strong>
        </h2>
        <HrDivider />
      </div>
    </BodyContainer>
  );
};

export default Support;
