import React from "react";
import { Scribi } from "../api/store/store";
import { useDndContext } from "@dnd-kit/core";
import Icons from "../../Icons/Icons";
import {
  IconButton,
  IconPreviewItem,
  NavItem,
} from "../../Scribi-Components/ScribiComponents";
import { ItemContainer } from "../../Project/Sidebar/StorybaseSidebar";
import { DragHandleContext } from "../api/store/DragHandleManager";

const PreviewItem = {
  document: DocumentPreviewItem,
  chapter: ChapterPreviewItem,
  part: PartPreviewItem,
  note: NotePreviewItem,
  element: ElementPreviewItem,
};

const DragPreview = () => {
  const { dragging } = React.useContext(Scribi);
  const { active } = React.useContext(DragHandleContext);

  return (
    dragging &&
    active && (
      <div style={{ backgroundColor: "transparent" }}>
        {PreviewItem[active.data.current.type]({
          indent: active?.data?.current?.indent || null,
        })}
      </div>
    )
  );
};

export default DragPreview;

export function DocumentPreviewItem({ indent }) {
  const { active } = React.useContext(DragHandleContext);

  return (
    active && (
      <NavItem
        style={{
          paddingLeft: indent ? `${indent}px` : "0px",
        }}
      >
        <div className="icon">
          <Icons.DocumentIcon />
        </div>
        <label className="title">{active.data.current.heading}</label>
      </NavItem>
    )
  );
}

export function ChapterPreviewItem({ indent }) {
  const { active } = React.useContext(DragHandleContext);

  return (
    active && (
      <NavItem
        style={{
          paddingLeft: indent ? `${indent}px` : "0px",
        }}
      >
        <div className="icon">
          <Icons.ChapterIcon />
        </div>
        <label className="title">{active.data.current.heading}</label>
      </NavItem>
    )
  );
}

export function PartPreviewItem() {
  const { active } = React.useContext(DragHandleContext);

  return (
    active && (
      <NavItem>
        <div className="icon">
          <Icons.PartIcon />
        </div>
        <label className="title">{active.data.current.heading}</label>
      </NavItem>
    )
  );
}

function NotePreviewItem() {
  return (
    <IconPreviewItem>
      <Icons.NoteIcon />
    </IconPreviewItem>
  );
}

function ElementPreviewItem() {
  const { active } = React.useContext(DragHandleContext);

  return (
    active && (
      <ItemContainer
        style={{
          gridTemplateColumns: `min-content auto min-content`,
        }}
        pinned={active.data.current.element.pinned}
      >
        <img className="element" src={active.data.current.element.image} />
        <label className="element">{active.data.current.element.name}</label>
        <IconButton size="15" className="pin">
          <Icons.PinIcon />
        </IconButton>
      </ItemContainer>
    )
  );
}
