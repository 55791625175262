import { Scribi } from "../../utilities/api/store/store";
import "./MentionList.css";
import { v4 as uuidv4 } from "uuid";

import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { createScribiDocument } from "../../utilities/firestore/db";

export const MentionList = forwardRef((props, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const { elements, project, setModal } = useContext(Scribi);

  const selectItem = async (index) => {
    const item = props.items[index];

    if (item) {
      if (item.id) {
        props.command({ id: item.id, label: item.name });
      } else {
        try {
          let newElement = {
            ...item,
            id: uuidv4(),
          };
          await createScribiDocument(newElement);
          props.command({ id: item.name, label: item.name });
        } catch (error) {
          setModal({
            open: true,
            title: "Error",
            message: `Error creating ${item.name}: ${error.message}`,
            confirm: () => setModal({ open: false }),
          });
        }
      }
    }
  };

  const upHandler = () => {
    setSelectedIndex(
      (selectedIndex + props.items.length - 1) % props.items.length
    );
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length);
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useEffect(() => setSelectedIndex(0), [props.items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === "ArrowUp") {
        upHandler();
        return true;
      }

      if (event.key === "ArrowDown") {
        downHandler();
        return true;
      }

      if (event.key === "Enter") {
        enterHandler();
        return true;
      }

      return false;
    },
  }));

  return (
    <div className="items">
      {props.items.length ? (
        props.items.map((item, index) => (
          <button
            className={`item ${index === selectedIndex ? "is-selected" : ""}`}
            key={index}
            onClick={() => selectItem(index)}
            style={{
              paddingLeft: item.nameType === "alias" ? "10px" : "0px",
            }}
          >
            {item.nameType === "alias" ? `- ${item.name}` : item.name}
          </button>
        ))
      ) : (
        <div className="item">No result</div>
      )}
    </div>
  );
});
