import React from "react";
import styled from "styled-components";
import { NavItem } from "../../Scribi-Components/ScribiComponents";
import Icons from "../../Icons/Icons";
import { Scribi } from "../../utilities/api/store/store";

export const NavList = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 25px;
  gap: 5px;
`;

const Catalog = () => {
  const { pens, navigate } = React.useContext(Scribi);
  return (
    <NavList>
      {pens.map((pen, index) => {
        return <CatalogPen pen={pen} key={index} />;
      })}
    </NavList>
  );
};

export default Catalog;

const CatalogPen = ({ pen }) => {
  const { navigate, worlds, books } = React.useContext(Scribi);
  const [open, setOpen] = React.useState(false);
  const penWorlds = worlds.filter((world) => world.pen === pen.id);
  const penBooks = books.filter(
    (book) => book.pen === pen.id && book.standalone === "pen"
  );
  return (
    <>
      <NavItem>
        {(penWorlds.length > 0 || penBooks.length > 0) && (
          <button className="toggle" onClick={() => setOpen(!open)}>
            {open ? (
              <Icons.ToggleOpenIconOpened />
            ) : (
              <Icons.ToggleOpenIconClosed open={false} />
            )}
          </button>
        )}
        <div className="icon">
          <Icons.PenIcon />
        </div>
        <label
          className="title"
          onClick={() => {
            navigate("/pen-name", { state: { pen } });
          }}
        >
          {pen.fullName}
        </label>
      </NavItem>
      {open && (
        <>
          {penBooks.map((book, index) => (
            <CatalogBook book={book} key={index} indent={25} />
          ))}
          {penWorlds.map((world, index) => (
            <CatalogWorld world={world} key={index} />
          ))}
        </>
      )}
    </>
  );
};

const CatalogBook = ({ book, indent }) => {
  const { navigate } = React.useContext(Scribi);
  return (
    <NavItem style={{ paddingLeft: `${indent}px` }}>
      <div className="icon">
        <Icons.BookIcon />
      </div>
      <label
        className="title"
        onClick={() => {
          navigate("/book", { state: { book } });
        }}
      >
        {book.title}
      </label>
    </NavItem>
  );
};

const CatalogWorld = ({ world }) => {
  const { series, books, navigate } = React.useContext(Scribi);
  const [open, setOpen] = React.useState(false);

  const worldSeries = series.filter((series) => series.world === world.id);
  const worldBooks = books.filter(
    (book) => book.world === world.id && !book.series
  );

  return (
    <>
      <NavItem style={{ paddingLeft: "25px" }}>
        {(worldSeries.length > 0 || worldBooks.length > 0) && (
          <button className="toggle" onClick={() => setOpen(!open)}>
            {open ? (
              <Icons.ToggleOpenIconOpened />
            ) : (
              <Icons.ToggleOpenIconClosed open={false} />
            )}
          </button>
        )}
        <div className="icon">
          <Icons.WorldIcon />
        </div>
        <label
          className="title"
          onClick={() => {
            navigate("/story-world", { state: { world } });
          }}
        >
          {world.title}
        </label>
      </NavItem>
      {open && (
        <>
          {worldBooks.map((book, index) => (
            <CatalogBook book={book} key={index} indent={50} />
          ))}
          {worldSeries.map((series, index) => (
            <CatalogSeries series={series} key={index} />
          ))}
        </>
      )}
    </>
  );
};

const CatalogSeries = ({ series }) => {
  const { books, navigate } = React.useContext(Scribi);
  const [open, setOpen] = React.useState(false);

  const seriesBooks = books.filter((book) => book.series === series.id);

  return (
    <>
      <NavItem style={{ paddingLeft: "50px" }}>
        {seriesBooks.length > 0 && (
          <button className="toggle" onClick={() => setOpen(!open)}>
            {open ? (
              <Icons.ToggleOpenIconOpened />
            ) : (
              <Icons.ToggleOpenIconClosed open={false} />
            )}
          </button>
        )}
        <div className="icon">
          <Icons.SeriesIcon />
        </div>
        <label
          className="title"
          onClick={() => {
            navigate("/book-series", { state: { series } });
          }}
        >
          {series.title}
        </label>
      </NavItem>
      {open && (
        <>
          {seriesBooks.map((book, index) => (
            <CatalogBook book={book} key={index} indent={75} />
          ))}
        </>
      )}
    </>
  );
};
