/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import {
  AutoTextArea,
  BodyContainer,
  ConfirmButton,
  FormContainer,
  FormLabel,
  FormRow,
  FormSelect,
  FormTextArea,
} from "../../Scribi-Components/ScribiComponents";
import { Scribi } from "../../utilities/api/store/store";
import { updateScribiDocument } from "../../utilities/firestore/db";

const ElementDisplay = ({ element, setElement }) => {
  const { elements, project, worlds, setModal } = React.useContext(Scribi);

  const [activeElement, setActiveElement] = React.useState(element);
  const [mountedElement, setMountedElement] = React.useState(null);
  const [originalElement, setOriginalElement] = React.useState(null);
  const [worldElements, setWorldElements] = React.useState(
    elements.filter((e) => e.world === project.world)
  );

  const [updated, setUpdated] = React.useState(false);

  const schema = worlds.find((w) => w.id === project.world).schema;

  useEffect(() => {
    if(activeElement){
      let data = elements.find((e) => e.id === activeElement);
      setMountedElement({
        ...data,
        description: data.description || "",
        attributes: data.attributes || [],
        tags: data.tags || [],
        notes: data.notes || [],
        type: data.type || "",
        subtype: data.subtype || "",
        aliases: data.aliases || [],
      });
      setOriginalElement(data);
    }
  }, [activeElement]);



  async function updateElement() {
    const newElement = {
      ...mountedElement
    };

    console.log(newElement);

    try {
      await updateScribiDocument(newElement);
      setUpdated(false);
    } catch (error) {
      setModal({
        visible: true,
        title: "Error",
        message: `There was a problem updating this element: ${error.message}`,
        confirm: () =>
          setModal({
            visible: false,
            title: null,
            message: null,
            confirm: null,
          }),
      });
    }
  }

  return mountedElement && (
    <BodyContainer>
      <FormContainer style={{ padding: "5px" }}>
        <FormSelect
          value={activeElement}
          onChange={(e) => {
            setActiveElement(e.target.value)
            setUpdated(false)
          }}
        >
          {worldElements.map((e) => (
            <option value={e.id} key={e.id}>{e.name}</option>
          ))}
        </FormSelect>
        <FormLabel>Description</FormLabel>
        <AutoTextArea
          value={mountedElement.description}
          setValue={(value) => {
            setMountedElement({
              ...mountedElement,
              description: value,
            });
            setUpdated(true);
          }}
        />
        <FormLabel>Aliases:</FormLabel>
        <AutoTextArea 
          value={mountedElement.aliases.join(", ")}
          setValue={(value) => {
            setMountedElement({
              ...mountedElement,
              aliases: value.split(", "),
            });
            setUpdated(true);
          }}
        />
        <FormRow justifyContent={"right"}>
          <ConfirmButton
            type="button"
            onClick={updateElement}
            disabled={!updated}
          >
            Update Element
          </ConfirmButton>
        </FormRow>
      </FormContainer>
    </BodyContainer>
  );
};

export default ElementDisplay;
