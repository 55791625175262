import React, { useContext } from "react";
import {
  BodyContainer,
  FormGrid,
  IconButton,
  ProjectCard,
  ProjectCardHeader,
  Tag,
} from "../Scribi-Components/ScribiComponents";
import { Scribi } from "../utilities/api/store/store";
import Icons from "../Icons/Icons";
import { updateScribiDocument } from "../utilities/firestore/db";

const Projects = () => {
  const { books } = useContext(Scribi);
  return (
    <BodyContainer>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          padding: "10px",
          gap: "10px",
        }}
      >
        {books.map((book, index) => {
          return <ProjectBlock bookId={book.id} key={index} />;
        })}
      </div>
    </BodyContainer>
  );
};

export default Projects;

const ProjectBlock = ({ bookId }) => {
  const { books, pens, worlds, series, navigate } = useContext(Scribi);
  const book = books.find((b) => b.id === bookId);

  const updateTags = async (tags) => {
    let bookDoc = {
      ...book,
      tags: tags,
    };
    await updateScribiDocument(bookDoc);
  };

  const RenderStoryWorldTitle = () => {
    if (book.standalone === "pen") {
      return "Standalone";
    } else {
      const world = worlds.find((w) => w.id === book.world);
      return world.title;
    }
  };

  const RenderWords = () => {
    return (
      <label>
        {book.currentWords || 0}/{book.targetWords}
      </label>
    );
  };
  return (
    <ProjectCard>
      <ProjectCardHeader>
        <label
          style={{
            fontSize: "1.2rem",
            fontWeight: "bold",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "250px", // You can set to whatever max width you want
          }}
        >
          {book.title}
        </label>
        <IconButton
          size={20}
          type="button"
          onClick={() =>
            navigate({
              pathname: `/project`,
              search: `?id=${book.id}`,
              hash: `${JSON.parse(localStorage.getItem(book.id))?.page || ""}`,
            })
          }
        >
          <Icons.EditIcon />
        </IconButton>
      </ProjectCardHeader>
      <FormGrid
        columns={"min-content 1fr"}
        style={{ alignItems: "flex-start" }}
      >
        <label>Author:</label>
        <label> {pens.find((p) => p.id === book.pen).fullName}</label>
        <label>World:</label>
        <label>{RenderStoryWorldTitle()}</label>
        {book.series && (
          <>
            <label>Series:</label>
            <label>{series.filter((s) => s.id === book.series).title}</label>
          </>
        )}
        <label>Words:</label>
        {RenderWords()}
        <label>Tags:</label>
        {book.tags.length > 0 && (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "5px",
              gridColumn: "2",
            }}
          >
            {book.tags.map((tag, i) => (
              <Tag
                key={i}
                tag={tag}
                index={i}
                state={book}
                setState={() => {
                  updateTags(book.tags.filter((t) => t !== tag));
                }}
              />
            ))}
          </div>
        )}
      </FormGrid>
    </ProjectCard>
  );
};
