import { useCallback } from "react";
import { useDropzone } from "react-dropzone";

export const useImageUpload = (setImageState) => {
  const readFileAsDataURL = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = () => reject(new Error("Failed to read file"));
      reader.readAsDataURL(file);
    });
  };

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const dataURL = await readFileAsDataURL(acceptedFiles[0]);
      setImageState(dataURL);
    },
    [setImageState]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    multiple: false,
    onDrop,
  });

  return { getRootProps, getInputProps };
};
