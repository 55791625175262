import React, { useEffect } from "react";
import { Scribi } from "../utilities/api/store/store";
import styled from "styled-components";
import {
  CancelButton,
  ConfirmButton,
  FormContainer,
  FormGrid,
  FormInput,
  FormLabel,
  FormRow,
  FormSection,
  FormSelect,
  FormTextArea,
  FormTitle,
  HrDivider,
  IconButton,
} from "../Scribi-Components/ScribiComponents";
import Icons from "../Icons/Icons";
import { v4 as uuid } from "uuid";
import {
  createScribiDocument,
  createScribiSprintTimer,
  updateScribiDocument,
} from "../utilities/firestore/db";
import { RTDatabase } from "../utilities/firestore/cloud-configs";
import { ref, set } from "firebase/database";

export const ModalOverlay = styled.div`
  position: absolute;
  height: 100vh;
  width: 100vw;
  background: ${({ overlayStyle }) =>
    overlayStyle === "cover" ? "rgba(0,0,0,0.5)" : "transparent"};
  z-index: 900;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  div.form-modal {
    background: ${({ theme }) => theme.menuBackground};
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 500px;
    height: min-content;
    max-height: 500px;
    overflow-y: auto;
    top: 50px;
    margin: 0 auto;
    position: relative;
    div.body {
      padding: 10px;
      height: 100%;
    }
  }
`;

const Forms = {
  sprintTimer: SprintTimerForm,
  addElementForm: AddElementForm,
  addTypeForm: AddWorldTypeForm,
  addSubtypeForm: AddWorldSubtypeForm,
};

const FormModal = () => {
  const { formModal, setFormModal } = React.useContext(Scribi);
  return (
    formModal.visible && (
      <ModalOverlay
        overlayStyle="cover"
        onClick={() => {
          if (formModal.callbackAction) {
            formModal.callbackAction();
          }
          setFormModal({ visible: false, component: null });
        }}
      >
        <div className="form-modal" onClick={(e) => e.stopPropagation()}>
          <IconButton
            size={20}
            onClick={(e) => {
              if (formModal.callbackAction) {
                formModal.callbackAction();
              }
              setFormModal({ visible: false, component: null });
            }}
            style={{ position: "absolute", top: "10px", right: "10px" }}
          >
            <Icons.CloseIcon />
          </IconButton>
          <div className="body">
            {Forms[formModal.component]({ context: formModal.context })}
          </div>
        </div>
      </ModalOverlay>
    )
  );
};

export default FormModal;

function SprintTimerForm() {
  const { setFormModal, formModal, user, setModal, joinedSprintTimer } =
    React.useContext(Scribi);
  const [title, setTitle] = React.useState("");
  const [timerSchedule, setTimerSchedule] = React.useState({
    sprints: 0,
    longBreaks: 0,
    sprintDuration: 0,
    shortBreakDuration: 0,
    longBreakDuration: 0,
  });

  const [totalWorkingTime, setTotalWorkingTime] = React.useState("00:00");

  useEffect(() => {
    setTotalWorkingTime(renderWorkingTime());
  }, [timerSchedule]);

  const createSprintDocument = async () => {
    const createTimerSchedule = () => {
      const {
        sprints,
        longBreaks,
        sprintDuration,
        shortBreakDuration,
        longBreakDuration,
      } = timerSchedule;

      let schedule = [];
      let elapsed = 0;

      for (let i = 1; i <= sprints; i++) {
        // Add sprint
        schedule.push({
          type: "sprint",
          duration: sprintDuration * 60 * 1000, // convert minutes to milliseconds
          elapsed,
        });
        elapsed += sprintDuration * 60 * 1000;

        // Add breaks
        const breakDuration =
          i % longBreaks === 0 ? longBreakDuration : shortBreakDuration;
        schedule.push({
          type: i % longBreaks === 0 ? "longbreak" : "shortbreak",
          duration: breakDuration * 60 * 1000, // convert minutes to milliseconds
          elapsed,
        });
        elapsed += breakDuration * 60 * 1000;
      }

      // Remove the last break as it's not needed after the last sprint
      schedule.pop();

      return schedule;
    };

    const getTotalDuration = () => {
      return createTimerSchedule().reduce((acc, cur) => acc + cur.duration, 0);
    };

    const sprintSchedule = createTimerSchedule();
    let sprintDoc = {
      id: uuid(),
      title: title,
      ownerId: user.uid,
      doctype: "timers",
      group: [],
      totalDuration: getTotalDuration(),
      status: "finished",
      currentStep: 0,
      timeElapsed: 0,
      nextStepTime: sprintSchedule[0]?.elapsed || 0,
      schedule: sprintSchedule,
    };

    try {
      const res = await createScribiSprintTimer(sprintDoc);
      if (res.isCreated) {
        if (!joinedSprintTimer) {
          let readySprintDoc = JSON.parse(JSON.stringify(sprintDoc));
          readySprintDoc.status = "ready";
          const sprintRef = ref(RTDatabase, `sprints/${sprintDoc.id}`);
          set(sprintRef, readySprintDoc);
        }
        // Successfully created and set to 'ready'
        setModal({
          visible: true,
          title: "Sprint Timer Created",
          message: "Your sprint timer has been created.",
          confirm: () =>
            setModal({ visible: false, title: "", message: "", confirm: null }),
        });
        setFormModal({ visible: false, component: null });
      } else {
        // Handle case when res.isCreated is false
        setModal({
          visible: true,
          title: "Error Creating Timer",
          message: "There was an error creating your sprint timer.",
          confirm: () =>
            setModal({ visible: false, title: "", message: "", confirm: null }),
        });
      }
    } catch (error) {
      // Handle any errors during the async operations
      console.error("Error in creating Sprint Document: ", error);
      setModal({
        visible: true,
        title: "Unexpected Error",
        message: "An unexpected error occurred.",
        confirm: () =>
          setModal({ visible: false, title: "", message: "", confirm: null }),
      });
    }
  };

  const renderWorkingTime = () => {
    const {
      sprints,
      longBreaks,
      sprintDuration,
      shortBreakDuration,
      longBreakDuration,
    } = timerSchedule;

    let elapsed = 0;

    for (let i = 1; i <= sprints; i++) {
      // Add sprint
      elapsed += (sprintDuration || 0) * 60 * 1000;

      // Determine break duration
      const breakDuration =
        i % longBreaks === 0 ? longBreakDuration : shortBreakDuration;

      // Add break
      elapsed += (breakDuration || 0) * 60 * 1000;
    }

    // Remove the last break as it's not needed after the last sprint
    const lastBreakDuration =
      sprints % longBreaks === 0 ? longBreakDuration : shortBreakDuration;
    elapsed -= (lastBreakDuration || 0) * 60 * 1000;

    const totalMinutes = elapsed / 60000;

    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return `${hours.toString().padStart(2, "0")}:${Math.round(minutes)
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <>
      <FormRow>
        <FormTitle>New Sprint Timer</FormTitle>
      </FormRow>
      <HrDivider style={{ margin: "10px 0px" }} />
      <FormGrid columns="min-content 1fr">
        <FormLabel>Timer Name:</FormLabel>
        <FormInput value={title} onChange={(e) => setTitle(e.target.value)} />
        <FormLabel>Sprints:</FormLabel>
        <FormInput
          type="number"
          value={timerSchedule.sprints}
          onChange={(e) =>
            setTimerSchedule({
              ...timerSchedule,
              sprints: parseInt(e.target.value),
            })
          }
        />
        <FormLabel>Sprint Duration:</FormLabel>
        <FormInput
          type="number"
          value={timerSchedule.sprintDuration}
          onChange={(e) =>
            setTimerSchedule({
              ...timerSchedule,
              sprintDuration: parseInt(e.target.value),
            })
          }
        />
        <FormLabel>Short Break Duration: </FormLabel>
        <FormInput
          type="number"
          value={timerSchedule.shortBreakDuration}
          onChange={(e) =>
            setTimerSchedule({
              ...timerSchedule,
              shortBreakDuration: parseInt(e.target.value),
            })
          }
        />
        <FormLabel>Long Break Duration: </FormLabel>
        <FormInput
          type="number"
          value={timerSchedule.longBreakDuration}
          onChange={(e) =>
            setTimerSchedule({
              ...timerSchedule,
              longBreakDuration: parseInt(e.target.value),
            })
          }
        />
        <FormLabel>Long Break Every: </FormLabel>
        <FormRow>
          <FormInput
            type="number"
            value={timerSchedule.longBreaks}
            onChange={(e) =>
              setTimerSchedule({
                ...timerSchedule,
                longBreaks: parseInt(e.target.value),
              })
            }
          />
          <FormLabel>sprints</FormLabel>
        </FormRow>
        <FormLabel>Total Working Time:</FormLabel>
        <FormLabel>{renderWorkingTime()}</FormLabel>
      </FormGrid>
      <FormRow
        style={{ gridColumn: "1 / span 2", marginTop: "auto", flexGrow: 1 }}
        justifyContent="right"
      >
        <ConfirmButton type="button" onClick={() => createSprintDocument()}>
          Create
        </ConfirmButton>
        <CancelButton
          type="button"
          onClick={() => {
            formModal.callbackAction();
            setFormModal({ visible: false, component: null });
          }}
        >
          Cancel
        </CancelButton>
      </FormRow>
    </>
  );
}

function AddElementForm({ context }) {
  const { user, setModal, setFormModal } = React.useContext(Scribi);
  const [element, setElement] = React.useState({
    id: uuid(),
    world: context.world.id,
    ownerId: user.uid,
    name: "",
    description: "",
    type: context?.type?.label ? context.type.label : "",
    subtype: context?.subtype?.label ? context.subtype.label : "",
    notes: [],
    tags: [],
    attributes: [],
    images: [],
    doctype: "elements",
    viewers: [],
    editors: [],
    created: Date.now(),
  });
  const [newType, setNewType] = React.useState({
    label: "",
    subtypes: [],
    attributes: [],
  });
  const [newSubtype, setNewSubtype] = React.useState({
    label: "",
    attributes: [],
  });

  async function createElement() {
    let newElement = JSON.parse(JSON.stringify(element));
    let addNewType = false;
    let addNewSubtype = false;
    let newSchema = JSON.parse(JSON.stringify(context.schema));
    if (element.name.trim().length === 0) {
      setModal({
        visible: true,
        title: "Error Creating Element",
        message: "Element name cannot be empty.",
        confirm: () => setModal({ visible: false, title: "", message: "" }),
      });
      return;
    }
    if (element.type.trim().length === 0) {
      newElement.type = "quick-element";
      newElement.subtype = null;
    }
    if (element.subtype.trim().length === 0) {
      newElement.subtype = null;
    }
    if (element.type === "addType") {
      newElement.type = newType.label;
      addNewType = true;
      newSchema.types.push(newType);
    }
    if (
      element.subtype === "addSubtype" &&
      newElement.type.trim().length !== 0
    ) {
      newElement.subtype = newSubtype.label;
      addNewSubtype = true;
      newSchema.types[
        newSchema.types.findIndex((t) => t.label === newElement.type)
      ].subtypes.push(newSubtype);
    }
    try {
      if (addNewType || addNewSubtype) {
        await updateScribiDocument({
          ...context.world,
          schema: newSchema,
        });
      }
      await createScribiDocument(newElement);
      setModal({
        visible: true,
        title: "Element Created",
        message: "Your element has been created.",
        confirm: () => setModal({ visible: false, title: "", message: "" }),
      });
      setFormModal({ visible: false, component: null });
    } catch (error) {
      setModal({
        visible: true,
        title: "Unexpected Error",
        message: `An unexpected error occurred: ${error}`,
        confirm: () => setModal({ visible: false, title: "", message: "" }),
      });
    }
  }

  return (
    <>
      <FormRow>
        <FormTitle>Add Element</FormTitle>
      </FormRow>
      <HrDivider style={{ margin: "10px 0px" }} />
      <FormGrid columns="min-content 1fr" style={{ alignItems: "flex-start" }}>
        <FormLabel>Element Name:</FormLabel>
        <FormInput
          value={element.name}
          onChange={(e) => setElement({ ...element, name: e.target.value })}
        />
        <FormLabel>Element Description:</FormLabel>
        <FormTextArea
          value={element.description}
          onChange={(e) =>
            setElement({ ...element, description: e.target.value })
          }
        />
        <FormLabel>Element Type:</FormLabel>
        <FormSelect
          value={element.type}
          onChange={(e) => setElement({ ...element, type: e.target.value })}
        >
          <option value="">Select a Type</option>
          {context.schema.types.map((type) => (
            <option key={type.label} value={type.label}>
              {type.label}
            </option>
          ))}
          <option value="quick-element">Quick Element</option>
          <option value="addType">Add New Type</option>
        </FormSelect>
        {element.type === "addType" && (
          <>
            <FormLabel>New Type Label:</FormLabel>
            <FormInput
              value={newType.label}
              onChange={(e) =>
                setNewType({ ...newType, label: e.target.value })
              }
            />
          </>
        )}
        {element.type !== "quick-element" && (
          <>
            <FormLabel>Element Subtype:</FormLabel>
            <FormSelect
              value={element.subtype}
              onChange={(e) =>
                setElement({ ...element, subtype: e.target.value })
              }
            >
              <option value="">Select a Subtype</option>
              {context.schema.types[
                context.schema.types.findIndex((t) => t.label === element.type)
              ]?.subtypes?.map((subtype) => (
                <option key={subtype.label} value={subtype.label}>
                  {subtype.label}
                </option>
              ))}
              <option value="addSubtype">Add New Subtype</option>
            </FormSelect>
          </>
        )}
        {element.subtype === "addSubtype" && (
          <>
            <FormLabel>New Subtype Label:</FormLabel>
            <FormInput
              value={newSubtype.label}
              onChange={(e) =>
                setNewSubtype({ ...newSubtype, label: e.target.value })
              }
            />
          </>
        )}
        <HrDivider style={{ gridColumn: "1 / span 2" }} />
      </FormGrid>
      <FormRow justifyContent="right" style={{ paddingTop: "10px" }}>
        <ConfirmButton type="button" onClick={createElement}>
          Add
        </ConfirmButton>
        <CancelButton
          type="button"
          onClick={() =>
            setFormModal({
              visible: false,
              component: null,
            })
          }
        >
          Cancel
        </CancelButton>
      </FormRow>
    </>
  );
}

function AddWorldTypeForm({ context }) {
  const { user, setModal, setFormModal } = React.useContext(Scribi);
  const [newType, setNewType] = React.useState({
    label: "",
    subtypes: [],
    attributes: [],
  });
  const [newSubtype, setNewSubtype] = React.useState({
    label: "",
    attributes: [],
  });

  async function createType() {
    let newSchema = JSON.parse(JSON.stringify(context.schema));
    if (newType.label.trim().length === 0) {
      setModal({
        visible: true,
        title: "Error Creating Type",
        message: "Type label cannot be empty.",
        confirm: () => setModal({ visible: false, title: "", message: "" }),
      });
      return;
    }
    if (newSchema.types.findIndex((t) => t.label === newType.label) !== -1) {
      setModal({
        visible: true,
        title: "Error Creating Type",
        message: "Type label already exists.",
        confirm: () => setModal({ visible: false, title: "", message: "" }),
      });
      return;
    }
    newSchema.types.push(newType);
    try {
      await updateScribiDocument({
        ...context.world,
        schema: newSchema,
      });
      setModal({
        visible: true,
        title: "Type Created",
        message: "Your Storybase schema has been updated.",
        confirm: () => setModal({ visible: false, title: "", message: "" }),
      });
      setFormModal({ visible: false, component: null });
    } catch (error) {
      setModal({
        visible: true,
        title: "Unexpected Error",
        message: `An unexpected error occurred: ${error}`,
        confirm: () => setModal({ visible: false, title: "", message: "" }),
      });
    }
  }
  return (
    <>
      <FormRow>
        <FormTitle>Add New Storybase Type</FormTitle>
      </FormRow>
      <HrDivider style={{ margin: "10px 0px" }} />
      <FormGrid columns="min-content 1fr" style={{ alignItems: "flex-start" }}>
        <FormLabel>Type Label:</FormLabel>
        <FormInput
          value={newType.label}
          onChange={(e) => setNewType({ ...newType, label: e.target.value })}
        />
      </FormGrid>
      <FormRow justifyContent="right" style={{ paddingTop: "10px" }}>
        <ConfirmButton type="button" onClick={createType}>
          Add
        </ConfirmButton>
        <CancelButton
          type="button"
          onClick={() => setFormModal({ visible: false, component: null })}
        >
          Cancel
        </CancelButton>
      </FormRow>
    </>
  );
}

function AddWorldSubtypeForm({ context }) {
  const { user, setModal, setFormModal } = React.useContext(Scribi);
  const [newType, setNewType] = React.useState({
    label: context.type.label || "",
    subtypes: context.type.subtypes || [],
    attributes: context.type.attributes || [],
  });
  const [newSubtype, setNewSubtype] = React.useState({
    label: "",
    attributes: [],
  });

  async function createSubtype() {
    let newSchema = JSON.parse(JSON.stringify(context.schema));
    if (newSubtype.label.trim().length === 0) {
      setModal({
        visible: true,
        title: "Error Creating Subtype",
        message: "Type label cannot be empty.",
        confirm: () => setModal({ visible: false, title: "", message: "" }),
      });
      return;
    }
    if (
      newType.subtypes.findIndex((t) => t.label === newSubtype.label) !== -1
    ) {
      setModal({
        visible: true,
        title: "Error Creating Subtype",
        message: "Subtype label already exists.",
        confirm: () => setModal({ visible: false, title: "", message: "" }),
      });
      return;
    }
    newSchema.types
      .find((t) => t.label === newType.label)
      .subtypes.push(newSubtype);
    try {
      await updateScribiDocument({
        ...context.world,
        schema: newSchema,
      });
      setModal({
        visible: true,
        title: "Type Created",
        message: "Your Storybase schema has been updated.",
        confirm: () => setModal({ visible: false, title: "", message: "" }),
      });
      setFormModal({ visible: false, component: null });
    } catch (error) {
      setModal({
        visible: true,
        title: "Unexpected Error",
        message: `An unexpected error occurred: ${error}`,
        confirm: () => setModal({ visible: false, title: "", message: "" }),
      });
    }
  }
  return (
    <>
      <FormRow>
        <FormTitle>Add New Storybase Subtype</FormTitle>
      </FormRow>
      <HrDivider style={{ margin: "10px 0px" }} />
      <FormGrid columns="min-content 1fr" style={{ alignItems: "flex-start" }}>
        <FormLabel>Type:</FormLabel>
        <FormSelect
          value={newType.label}
          onChange={(e) => setNewType({ ...newType, label: e.target.value })}
        >
          <option value="">Select a Type</option>
          {context.schema.types.map((type) => (
            <option key={type.label} value={type.label}>
              {type.label}
            </option>
          ))}
        </FormSelect>
        <FormLabel>New Subtype Label:</FormLabel>
        <FormInput
          value={newSubtype.label}
          onChange={(e) =>
            setNewSubtype({ ...newSubtype, label: e.target.value })
          }
        />
      </FormGrid>
      <FormRow justifyContent="right" style={{ paddingTop: "10px" }}>
        <ConfirmButton type="button" onClick={createSubtype}>
          Add
        </ConfirmButton>
        <CancelButton
          type="button"
          onClick={() => setFormModal({ visible: false, component: null })}
        >
          Cancel
        </CancelButton>
      </FormRow>
    </>
  );
}
