import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import ScribiProvider from "./utilities/api/store/store";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <ScribiProvider>
      <App />
    </ScribiProvider>
  </BrowserRouter>
);
