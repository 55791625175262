/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Scribi } from "../utilities/api/store/store";
import { v4 as uuid } from "uuid";
import {
  ActionButton,
  BodyContainer,
  CancelButton,
  ConfirmButton,
  DropArea,
  FormContainer,
  FormGrid,
  FormGridHeading,
  FormInput,
  FormLabel,
  FormRow,
  FormSection,
  FormSectionTitle,
  FormTextArea,
  FormTitle,
  HrDivider,
  IconButton,
  Tag,
} from "../Scribi-Components/ScribiComponents";
import Icons from "../Icons/Icons";
import ScribiRestService from "../utilities/firestore/ScribiRestService";
import { useImageUpload } from "../utilities/hooks/UseImageUpload";

const ManagePen = () => {
  const {
    navigate,
    pens,
    worlds,
    series,
    books,
    user,
    setModal,
    elements,
    deletePen,
  } = useContext(Scribi);
  const location = useLocation();
  const [newTag, setNewTag] = useState("");
  let now = new Date().getTime();
  const penId = location.state ? location.state.pen.id : null;
  const [pen, setPen] = useState(
    penId
      ? { ...location.state.pen }
      : {
          firstName: "",
          lastName: "",
          fullName: "",
          tags: [],
          description: "",
          authorBio: "",
          authorPhoto: "",
          authorLogo: "",
          links: [{ text: "", url: "" }],
          public: false,
          id: uuid(),
          ownerId: user.uid,
          editors: [],
          viewers: [],
          doctype: "pens",
          created: now,
          notes: [],
        }
  );

  useEffect(() => {
    if (location.state) {
      setPen({
        firstName: location.state.pen.firstName || "",
        lastName: location.state.pen.lastName || "",
        fullName: location.state.pen.fullName || "",
        tags: location.state.pen.tags || [],
        description: location.state.pen.description || "",
        authorBio: location.state.pen.authorBio || "",
        authorPhoto: location.state.pen.authorPhoto || "",
        authorLogo: location.state.pen.authorLogo || "",
        links: location.state.pen.links || [{ text: "", url: "" }],
        public: location.state.pen.public || false,
        id: location.state.pen.id || uuid(),
        ownerId: location.state.pen.ownerId || user.uid,
        editors: location.state.pen.editors || [],
        viewers: location.state.pen.viewers || [],
        doctype: "pens",
        created: location.state.pen.created || now,
      });
    } else {
      setPen({
        firstName: "",
        lastName: "",
        fullName: "",
        tags: [],
        description: "",
        authorBio: "",
        authorPhoto: "",
        authorLogo: "",
        links: [{ text: "", url: "" }],
        public: false,
        id: uuid(),
        ownerId: user.uid,
        editors: [],
        viewers: [],
        doctype: "pens",
        created: now,
      });
    }
  }, [location.state]);

  const [authorPhoto, setAuthorPhoto] = useState(null);
  const [authorLogo, setAuthorLogo] = useState(null);

  const { getRootProps: getRootPropsPhoto, getInputProps: getInputPropsPhoto } =
    useImageUpload(setAuthorPhoto);

  const { getRootProps: getRootPropsLogo, getInputProps: getInputPropsLogo } =
    useImageUpload(setAuthorLogo);

  const handleSavePen = async () => {
    if (!pen.firstName) {
      alert(
        "First name is required! If your pen name is a single word, please use that in the first name field."
      );
      return;
    }
    let logoUrl = authorLogo
      ? await ScribiRestService.uploadImage(authorLogo)
      : "";
    let photoUrl = authorPhoto
      ? await ScribiRestService.uploadImage(authorPhoto)
      : "";
    let newPen = {
      ...pen,
      fullName: `${pen.firstName}${
        pen.lastName.trim().length > 0 ? ` ${pen.lastName}` : ""
      }`,
      authorPhoto: photoUrl.downloadURL,
      authorLogo: logoUrl.downloadURL,
    };
    let res;
    if (penId) {
      res = await ScribiRestService.updateDocument(
        `users/${user.uid}/pens`,
        newPen
      );
    } else {
      res = await ScribiRestService.createDocument(newPen);
    }

    if (res.isUpdated) {
      setModal({
        title: "Pen Name Updated",
        message: `Your pen name ${pen.fullName} has been updated!`,
        confirm: () => navigate("/"),
        visible: true,
      });
    }

    if (res.isCreated) {
      setModal({
        title: "Pen Name Created",
        message: `Your pen name ${pen.fullName} has been created!`,
        confirm: () => navigate("/"),
        visible: true,
      });
    }
  };

  return (
    <BodyContainer>
      <FormContainer>
        <FormSection>
          <FormRow>
            <FormTitle>
              {penId ? `Manage ${pen.fullName}` : "New Pen Name"}
            </FormTitle>
            {penId && (
              <CancelButton
                type="button"
                onClick={() => deletePen(pen)}
                style={{ marginLeft: "auto" }}
              >
                Delete Pen Name
              </CancelButton>
            )}
          </FormRow>
          <HrDivider />
          <FormGrid
            columns="min-content 1fr"
            style={{ alignItems: "flex-start" }}
          >
            <FormLabel>First Name:</FormLabel>
            <FormInput
              type="text"
              value={pen.firstName}
              onChange={(e) => setPen({ ...pen, firstName: e.target.value })}
              placeholder="Required"
            />
            <FormLabel>Last Name:</FormLabel>
            <FormInput
              type="text"
              value={pen.lastName}
              onChange={(e) => setPen({ ...pen, lastName: e.target.value })}
              placeholder="Optional"
            />
            <FormLabel>Description:</FormLabel>
            <FormTextArea
              value={pen.description}
              onChange={(e) => setPen({ ...pen, description: e.target.value })}
              placeholder="Your plan for this pen name"
            />
            <FormLabel>Author Bio:</FormLabel>
            <FormTextArea
              value={pen.authorBio}
              onChange={(e) => setPen({ ...pen, authorBio: e.target.value })}
              placeholder="Reader-facing author bio"
            />
            <FormLabel>Tags:</FormLabel>
            <FormInput
              type="text"
              placeholder="Press enter to add a tag"
              value={newTag}
              onChange={(e) => setNewTag(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  const newTags = JSON.parse(JSON.stringify(pen.tags));
                  newTags.push(newTag.toLowerCase());
                  setPen({ ...pen, tags: newTags });
                  setNewTag("");
                }
              }}
            />
            {pen.tags.length > 0 && (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "5px",
                  gridColumn: "2",
                }}
              >
                {pen.tags.map((tag, i) => (
                  <Tag
                    key={i}
                    tag={tag}
                    index={i}
                    state={pen}
                    setState={setPen}
                  />
                ))}
              </div>
            )}
          </FormGrid>
          <HrDivider />
          <FormSectionTitle>Images:</FormSectionTitle>
          <FormGrid columns="1fr 1fr">
            <FormLabel style={{ width: "100%", textAlign: "center" }}>
              Author Photo:
            </FormLabel>
            <FormLabel style={{ width: "100%", textAlign: "center" }}>
              Author Logo:
            </FormLabel>
            <DropArea
              {...getRootPropsPhoto()}
              orientation="portrait"
              style={{ justifySelf: "center", alignSelf: "center" }}
            >
              <input {...getInputPropsPhoto()} />
              {authorPhoto ? (
                <img src={authorPhoto} alt="Author Photo" />
              ) : pen.authorPhoto ? (
                <img src={pen.authorPhoto} alt="Author Photo" />
              ) : (
                "Click to upload, or drop photo here"
              )}
            </DropArea>
            <DropArea
              {...getRootPropsLogo()}
              orientation="portrait"
              style={{ justifySelf: "center", alignSelf: "center" }}
            >
              <input {...getInputPropsLogo()} />
              {authorLogo ? (
                <img src={authorLogo} alt="Author Logo" />
              ) : pen.authorLogo ? (
                <img src={pen.authorLogo} alt="Author Logo" />
              ) : (
                "Click to upload, or drop logo here"
              )}
            </DropArea>
            <ActionButton
              style={{ width: "200px", justifySelf: "center" }}
              onClick={() => {
                setAuthorPhoto(null);
                setPen({ ...pen, authorPhoto: "" });
              }}
            >
              Clear Image
            </ActionButton>
            <ActionButton
              style={{ width: "200px", justifySelf: "center" }}
              onClick={() => {
                setAuthorLogo(null);
                setPen({ ...pen, authorLogo: "" });
              }}
            >
              Clear Image
            </ActionButton>
          </FormGrid>
          <HrDivider />
          <FormSectionTitle>Author Links:</FormSectionTitle>
          <FormGrid columns="1fr 3fr 25px">
            <FormLabel>Link Text:</FormLabel>
            <FormLabel>URL:</FormLabel>
            <div></div>
            {pen.links.map((link, i) => (
              <React.Fragment key={i}>
                <FormInput
                  type="text"
                  value={link.text}
                  onChange={(e) => {
                    const newLinks = JSON.parse(JSON.stringify(pen.links));
                    newLinks[i].text = e.target.value;
                    setPen({ ...pen, links: newLinks });
                  }}
                />
                <FormInput
                  type="text"
                  value={link.url}
                  onChange={(e) => {
                    const newLinks = JSON.parse(JSON.stringify(pen.links));
                    newLinks[i].url = e.target.value;
                    setPen({ ...pen, links: newLinks });
                  }}
                />
                <IconButton
                  onClick={() => {
                    const newLinks = JSON.parse(JSON.stringify(pen.links));
                    newLinks.splice(i, 1);
                    setPen({ ...pen, links: newLinks });
                  }}
                >
                  <Icons.DeleteIcon />
                </IconButton>
              </React.Fragment>
            ))}
            <ActionButton
              style={{ gridColumn: "1 / span 2", justifySelf: "right" }}
              onClick={() => {
                const newLinks = JSON.parse(JSON.stringify(pen.links));
                newLinks.push({ text: "", url: "" });
                setPen({ ...pen, links: newLinks });
              }}
            >
              Add Link
            </ActionButton>
          </FormGrid>
          <FormRow justifyContent="flex-end">
            <ConfirmButton type="button" onClick={handleSavePen}>
              {penId ? "Save Changes" : "Create Pen Name"}
            </ConfirmButton>
            <CancelButton type="button" onClick={() => navigate("/")}>
              Cancel
            </CancelButton>
          </FormRow>
        </FormSection>
      </FormContainer>
    </BodyContainer>
  );
};

export default ManagePen;
