/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import {
  BodyContainer,
  CalendarHeaderContainer,
  CancelButton,
  ConfirmButton,
  ExitButton,
  FormContainer,
  FormInput,
  FormLabeledCheckBox,
  FormRow,
  FormSection,
  FormSelect,
  FormTextArea,
  FormTitleInput,
} from "../../Scribi-Components/ScribiComponents";
import { useNavigate } from "react-router-dom";
import Icons from "../../Icons/Icons";
import styled from "styled-components";
import ReactDatePicker from "react-datepicker";
import { Scribi } from "../../utilities/api/store/store";
import { v4 as uuid } from "uuid";

function roundToNextFifteenMinutes(date) {
  const minutes = date.getMinutes();
  const remainder = 15 - (minutes % 15);
  date.setMinutes(minutes + remainder);
  date.setSeconds(0);
  return date;
}

function getDaySuffix(day) {
  if (day >= 11 && day <= 13) {
    return "th";
  }
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}

function getWeekdayPosition(date) {
  const weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ][date.getDay()];
  const day = date.getDate();

  let position = Math.ceil(day / 7);

  const lastDayOfMonth = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0
  ).getDate();
  const isLastInstance = day + 7 > lastDayOfMonth;

  let positionString = "";
  switch (position) {
    case 1:
      positionString = "First";
      break;
    case 2:
      positionString = "Second";
      break;
    case 3:
      positionString = "Third";
      break;
    case 4:
      positionString = isLastInstance ? "Last" : "Fourth";
      break;
    default:
      positionString = "Last";
      break;
  }

  return `${positionString} ${weekday}`;
}

function getMonthName(date) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return months[date.getMonth()];
}

const CalendarEventForm = () => {
  const { today, eventSeries, setEventSeries, user } = React.useContext(Scribi);
  const [startDate, setStartDate] = React.useState(
    roundToNextFifteenMinutes(new Date())
  );
  const [endDate, setEndDate] = React.useState(
    roundToNextFifteenMinutes(new Date())
  );

  useEffect(() => {
    if (eventSeries.length === 0) {
      setEventSeries([
        {
          id: uuid(),
          ownerId: user.uid,
          sequenceId: null,
          title: "",
          start: startDate,
          end: endDate,
          allDay: false,
          description: "",
          series: 0,
          recurring: false,
          period: "none",
          sequence: 0,
          interval: 0,
          link: "",
          linkTitle: "",
          priority: 0,
          reminder: false,
          reminderDate: null,
          bookId: null,
          page: null,
          doctype: "calendarEvent",
          invited: [],
          complete: false,
        },
      ]);
    }
  }, []);

  return (
    <BodyContainer>
      {eventSeries?.map((event, index) => (
        <RenderEvent event={event} key={index} index={index} />
      ))}
    </BodyContainer>
  );
};

export default CalendarEventForm;

function RenderEvent({ event, index }) {
  const { today, eventSeries, setEventSeries, navigate, saveEvent } =
    React.useContext(Scribi);

  const handleSaveEvent = async () => {
    if (event.recurring) {
    }
    if (!event.recurring) {
      let res = await saveEvent(eventSeries);
      if (res.isCreated) {
        navigate("/");
        setEventSeries([]);
      } else {
        alert("Error saving event");
      }
    } else {
    }
  };

  useEffect(() => {
    //when the eventSeries[index].start changes, if eventSeries[index].end is earlier than start, update end to be start + 15 minutes
    if (eventSeries[index].end < eventSeries[index].start) {
      const newEventSeries = [...eventSeries];
      newEventSeries[index].end = new Date(
        eventSeries[index].start.getTime() + 15 * 60000
      );
      setEventSeries(newEventSeries);
    }
  }, [eventSeries[index].start]);

  return (
    <>
      <CalendarHeaderContainer>
        <label>New Calendar Event</label>
        <ExitButton
          style={{ marginLeft: "auto" }}
          onClick={() => {
            navigate("/");
            setEventSeries([]);
          }}
        >
          <Icons.CloseIcon />
        </ExitButton>
      </CalendarHeaderContainer>
      <FormContainer>
        <FormTitleInput
          placeholder="Event Label"
          value={event.title}
          onChange={(e) => {
            const newEventSeries = [...eventSeries];
            newEventSeries[index].title = e.target.value;
            setEventSeries(newEventSeries);
          }}
        />
        <FormRow
          style={{
            width: "100%",
            height: "fit-content",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "20px",
          }}
        >
          <FormSection>
            <FormRow>
              <ReactDatePicker
                selected={event.start}
                minDate={today}
                onChange={(date) => {
                  const newEventSeries = [...eventSeries];
                  newEventSeries[index].start = date;
                  setEventSeries(newEventSeries);
                }}
                popperPlacement="bottom-start"
                showPopperArrow={false}
              />
              <label>from:</label>
              <ReactDatePicker
                selected={event.start}
                onChange={(date) => {
                  const newEventSeries = [...eventSeries];
                  newEventSeries[index].start = date;
                  setEventSeries(newEventSeries);
                }}
                showTimeSelect
                showTimeSelectOnly
                minTime={
                  event.start.toDateString() === new Date().toDateString()
                    ? new Date()
                    : new Date().setHours(0, 0)
                }
                maxTime={new Date().setHours(23, 59)}
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="h:mm aa"
              />
              <label>to:</label>
              <ReactDatePicker
                selected={event.end}
                onChange={(date) => {
                  const newEventSeries = [...eventSeries];
                  newEventSeries[index].end = date;
                  setEventSeries(newEventSeries);
                }}
                showTimeSelect
                showTimeSelectOnly
                minTime={
                  event.start.getDate() === event.end.getDate()
                    ? new Date(event.start.getTime() + 15 * 60000)
                    : new Date(0, 0, 0, 0, 0, 0)
                }
                maxTime={new Date().setHours(23, 59)}
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="h:mm aa"
              />
            </FormRow>
          </FormSection>
        </FormRow>
        <FormTextArea
          placeholder="Event Description"
          value={event.description}
          onChange={(e) => {
            const newEventSeries = [...eventSeries];
            newEventSeries[index].description = e.target.value;
            setEventSeries(newEventSeries);
          }}
        />
        <FormRow>
          <FormLabeledCheckBox>
            <input
              type="checkbox"
              checked={event.recurring}
              onChange={(e) => {
                const newEventSeries = [...eventSeries];
                newEventSeries[index].recurring = e.target.checked;
                newEventSeries[index].sequenceId = e.target.checked
                  ? uuid()
                  : null;
                newEventSeries[index].sequence = null;
                setEventSeries(newEventSeries);
              }}
            />
            <label>Recurring</label>
          </FormLabeledCheckBox>
          <FormSelect
            style={{
              visibility: event.recurring ? "visible" : "hidden",
            }}
            value={event.period}
            onChange={(e) => {
              const newEventSeries = [...eventSeries];
              newEventSeries[index].period = e.target.value;
              setEventSeries(newEventSeries);
            }}
          >
            <option>Daily</option>
            <option>Daily on Work Days only</option>
            <option>Weekly</option>
            <option>{`Monthly on Same Date (${event.start.getDate()}${getDaySuffix(
              event.start.getDate()
            )})`}</option>
            <option>{`Monthly on ${getWeekdayPosition(event.start)}`}</option>
            <option>Yearly</option>
            <option>{`Yearly on ${getWeekdayPosition(
              event.start
            )} of ${getMonthName(event.start)}`}</option>
          </FormSelect>
          <label style={{ visibility: event.recurring ? "visible" : "hidden" }}>
            for
          </label>
          <FormInput
            type="number"
            placeholder="Forever"
            style={{
              width: "100px",
              visibility: event.recurring ? "visible" : "hidden",
            }}
            value={event.sequence ? event.sequence : ""}
            onChange={(e) => {
              const newEventSeries = [...eventSeries];
              newEventSeries[index].sequence = e.target.value;
              setEventSeries(newEventSeries);
            }}
          />
          <label style={{ visibility: event.recurring ? "visible" : "hidden" }}>
            times
          </label>
        </FormRow>
        <FormRow justifyContent="right">
          <ConfirmButton type="button" onClick={handleSaveEvent}>
            Save
          </ConfirmButton>
          <CancelButton type="button" onClick={() => navigate("/")}>
            Cancel
          </CancelButton>
        </FormRow>
      </FormContainer>
    </>
  );
}
