import React from "react";
import { Scribi } from "../utilities/api/store/store";
import { ModalOverlay } from "../Scribi-Components/ScribiComponents";
import Icons from "../Icons/Icons";

const menus = {
  msNavMenu: MSMenuOptions,
  storybaseType: StorybaseTypeMenu,
  partMenu: PartMenuOptions,
};

const ContextMenu = () => {
  const { contextMenu, setContextMenu } = React.useContext(Scribi);
  const modalRef = React.useRef(null);

  return (
    <ModalOverlay
      onClick={() => setContextMenu({ visible: false, x: 0, y: 0, menu: null })}
      onContextMenu={(e) => {
        e.preventDefault();
        setContextMenu({ visible: false, x: 0, y: 0, menu: null });
      }}
      position={{ x: contextMenu.x, y: contextMenu.y }}
    >
      <div className="modal" ref={modalRef}>
        {menus[contextMenu.menu]({ context: contextMenu.context })}
      </div>
    </ModalOverlay>
  );
};

export default ContextMenu;

function MSMenuOptions() {
  const {
    setContextMenu,
    project,
    projectNavState,
    addDocument,
    addChapter,
    addPart,
  } = React.useContext(Scribi);

  return (
    <>
      <div
        alt="Add Part"
        className="menuOption"
        onClick={(e) => {
          e.stopPropagation();
          addPart(project.id);
          setContextMenu({ visible: false, x: 0, y: 0, menu: null });
        }}
      >
        <Icons.PartIcon />
        <label>Add Part</label>
      </div>
      <div
        alt="Add Chapter"
        className="menuOption"
        onClick={(e) => {
          e.stopPropagation();
          addChapter(project.id);
          setContextMenu({ visible: false, x: 0, y: 0, menu: null });
        }}
      >
        <Icons.ChapterIcon />
        <label>Add Chapter</label>
      </div>

      <div
        alt="Add Document"
        className="menuOption"
        onClick={(e) => {
          e.stopPropagation();
          addDocument(project.id);
          setContextMenu({ visible: false, x: 0, y: 0, menu: null });
        }}
      >
        <Icons.DocumentIcon />
        <label>Add Document</label>
      </div>
    </>
  );
}

function StorybaseTypeMenu({ context }) {
  const { formModal, setFormModal, contextMenu, setContextMenu } =
    React.useContext(Scribi);
  return (
    <>
      <div
        alt="Add Element"
        className="menuOption"
        onClick={(e) => {
          e.stopPropagation();
          setFormModal({
            visible: true,
            component: "addElementForm",
            context: context,
          });
          setContextMenu({ visible: false, x: 0, y: 0, menu: null });
        }}
      >
        <Icons.AddElement />
        <label>Add Element</label>
      </div>
      <div
        alt="Add Element"
        className="menuOption"
        onClick={(e) => {
          e.stopPropagation();
          setFormModal({
            visible: true,
            component: "addTypeForm",
            context: context,
          });
          setContextMenu({ visible: false, x: 0, y: 0, menu: null });
        }}
      >
        <Icons.AddType />
        <label>Add New Type</label>
      </div>
      <div
        alt="Add Element"
        className="menuOption"
        onClick={(e) => {
          e.stopPropagation();
          setFormModal({
            visible: true,
            component: "addSubtypeForm",
            context: context,
          });
          setContextMenu({ visible: false, x: 0, y: 0, menu: null });
        }}
      >
        <Icons.AddSubtype />
        <label>Add New Subtype</label>
      </div>
    </>
  );
}

function PartMenuOptions({ context }) {
  const { deleteContentPart, setContextMenu } = React.useContext(Scribi);

  return (
    <>
      <div
        alt="Add Element"
        className="menuOption"
        onClick={(e) => {
          e.stopPropagation();
          deleteContentPart(context.id);
          setContextMenu({ visible: false, x: 0, y: 0, menu: null });
        }}
      >
        <Icons.TrashIcon />
        <label>Delete Part</label>
      </div>
    </>
  );
}
