import React from "react";
import { AppHeader, HeaderLink, IconButton } from "../Scribi-Components/ScribiComponents";
import Icons from "../Icons/Icons";



const Header = () => {
    return (
        <AppHeader>
            <HeaderLink href="#">Home</HeaderLink>
            <HeaderLink href="#">Features</HeaderLink>
            <HeaderLink href="#">Pricing</HeaderLink>
            <HeaderLink href="#">Support</HeaderLink>
            <IconButton className={"mobile-menu-button"}>
                <Icons.MenuIcon />
            </IconButton>
        </AppHeader>
    )
}

export default Header;