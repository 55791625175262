/* eslint-disable no-unused-vars */
import { auth } from "./cloud-configs";
import {
  getAuth,
  updateProfile,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
} from "firebase/auth";
import ScribiRestService from "./ScribiRestService";
import { v4 as uuidv4 } from "uuid";

const api = process.env.REACT_APP_CLOUD_FUNCTION_API_URL;

export const currentAuth = auth.currentUser

export const signInToScribi = async (email, password) => {
  await signInWithEmailAndPassword(auth, email, password).then((res) => {
    return res;
  });
};

export const signOutOfScribi = async () => {
  await auth.signOut();
}

export const signUpToScribi = async (email, password, username) => {
  try {
    await createUserWithEmailAndPassword(auth, email, password).then(
      async (response) => {
        if (response !== "Firebase: Error (auth/email-already-in-use).") {
          updateProfile(auth.currentUser, {
            displayName: username,
            photoURL:
              "https://firebasestorage.googleapis.com/v0/b/scribi-backend.appspot.com/o/public%2Fprofile-image-blank.jpg?alt=media&token=f64fda02-f118-4397-aa7c-09438a30139a",
          });
          let document = {
            userId: response.user.uid,
            profileId: uuidv4(),
            displayName: username,
          };
          await ScribiRestService.createDocument(
            "makeAuthorUser",
            document
          ).then((res) => {
            verifyEmail();
          });
        }
      }
    );
  } catch (error) {
    return error.message;
  }
};

export const resetPassWithEmail = async (email) => {
  await sendPasswordResetEmail(auth, email);
};

export const verifyEmail = async () => {
  await sendEmailVerification(auth.currentUser);
};
