import { Bar, Container, Section } from "@column-resizer/react";
import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { Scribi } from "../utilities/api/store/store";
import NavLeft from "../LeftNav/NavLeft";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "../Home/Home";
import CalendarEventForm from "../Home/CalendarEventForm/CalendarEventForm";
import UserSettings from "../User Settings/UserSettings";
import UserAccount from "../User Account/UserAccount";
import Projects from "../Projects/Projects";
import ManagePen from "../ManagePen/ManagePen";
import ManageWorld from "../ManageWorld/ManageWorld";
import TaskSidebar from "../Sidebar/TaskSidebar";
import {
  Body,
  Nav,
  Main,
  Divider,
  IconButton,
  SidebarHeader,
} from "../Scribi-Components/ScribiComponents";
import Icons from "../Icons/Icons";
import ManageSeries from "../ManageSeries/ManageSeries";
import ManageBook from "../ManageBook/ManageBook";
import OpenProject from "../Project/Main/OpenProject";
import ProjectSidebar from "../Project/Sidebar/ProjectSidebar";
import { DragOverlay, useDndContext } from "@dnd-kit/core";
import DragPreview from "../utilities/DragPreview/DragPreview";

const Dashboard = () => {
  const { user, sidebar, navigate, setSidebar, location, dragging } =
    React.useContext(Scribi);

  const { active } = useDndContext();

  return (
    <Body>
      <Nav minSize={50} defaultSize={user.navCol ? user.navCol : 250}>
        <NavLeft />
      </Nav>
      <Divider expandInteractiveArea />
      <Main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/calendar-event/:id?" element={<CalendarEventForm />} />
          <Route path="/settings" element={<UserSettings />} />
          <Route path="/account" element={<UserAccount />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/project" element={<OpenProject />} />
          <Route path="/pen-name" element={<ManagePen />} />
          <Route path="/story-world" element={<ManageWorld />} />
          <Route path="/book-series" element={<ManageSeries />} />
          <Route path="/book" element={<ManageBook />} />
        </Routes>
      </Main>

      <Divider expandInteractiveArea />
      <Nav
        minSize={270}
        defaultSize={sidebar.width}
        style={{
          display: sidebar.visible ? "flex" : "none",
          overflowY: "auto",
        }}
      >
        <SidebarHeader>
          <IconButton
            size={25}
            onClick={() => {
              setSidebar({ width: 0, visible: false });
              if (location.pathname === "/") {
                navigate(location.path, { state: null });
              }
              if (location.pathname === "/project") {
                console.log("Saving project sidebar state?");
                localStorage.setItem(
                  "projectSidebarState",
                  JSON.stringify({ visible: false, width: 0 })
                );
              }
            }}
            title="Hide sidebar"
          >
            <Icons.ArrowHideRight />
          </IconButton>
        </SidebarHeader>
        <Routes>
          <Route path="/" element={<TaskSidebar />} />
          <Route path="/project" element={<ProjectSidebar />} />
        </Routes>
      </Nav>
      <DragOverlay>
        <DragPreview />
      </DragOverlay>
    </Body>
  );
};

export default Dashboard;
