/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Scribi } from "../utilities/api/store/store";
import { v4 as uuidv4 } from "uuid";
import {
  ActionButton,
  AutoTextArea,
  CancelButton,
  ConfirmButton,
  FormContainer,
  FormGrid,
  FormLabel,
  FormRow,
  FormSection,
  FormTextArea,
  FormTitle,
  HrDivider,
  IconButton,
  SidebarBody,
  SidebarHeader,
} from "../Scribi-Components/ScribiComponents";
import Icons from "../Icons/Icons";
import {
  deleteScribiDocument,
  updateScribiDocument,
} from "../utilities/firestore/db";
import ReactDatePicker from "react-datepicker";

const TaskSidebar = () => {
  const {
    sidebar,
    setSidebar,
    navigate,
    notes,
    user,
    setModal,
    calendarEvents,
  } = React.useContext(Scribi);
  const location = useLocation();
  const [event, setEvent] = React.useState(location.state?.event || null);
  const [edited, setEdited] = React.useState(false);
  const [isDatePickerOpen, setIsDatePickerOpen] = React.useState(false);

  useEffect(() => {
    if (event) {
      if (
        JSON.stringify(event) !==
        JSON.stringify(calendarEvents.find((e) => e.id === event.id))
      ) {
        setEdited(true);
      } else {
        setEdited(false);
      }
    }
  }, [event]);

  useEffect(() => {
    if (location.state?.event) {
      setEvent(location.state.event);
    } else {
      setEvent(null);
      setSidebar({ width: 0, visible: false });
    }
  }, [location.state]);

  const updateTask = async () => {
    const { isUpdated } = await updateScribiDocument(event);
    if (isUpdated) {
      setEdited(false);
      setModal({
        visible: true,
        title: "Success",
        message: "Your task has been updated.",
        confirm: () => {
          setModal({ visible: false, title: "", message: "", confirm: null });
        },
      });
    } else {
      setModal({
        visible: true,
        title: "Error",
        message: "There was an error updating your task. Please try again.",
        confirm: () => {
          setModal({ visible: false, title: "", message: "", confirm: null });
        },
      });
    }
  };

  const deleteTask = async () => {
    const { isDeleted } = await deleteScribiDocument(event);
    if (isDeleted) {
      setSidebar({ ...sidebar, visible: false });
      navigate(location.path, { state: null });
    } else {
      setModal({
        visible: true,
        title: "Error",
        message: "There was an error deleting your task. Please try again.",
        confirm: () => {
          setModal({ visible: false, title: "", message: "", confirm: null });
        },
      });
    }
  };

  return event ? (
    <>
      <SidebarBody>
        <FormSection>
          <FormTitle>Task: {event.title}</FormTitle>
          <HrDivider />
          <FormLabel>Description:</FormLabel>
          <AutoTextArea
            value={event.description}
            setValue={(value) => {
              setEvent({ ...event, description: value });
            }}
          />
          <HrDivider />
          <FormGrid columns="min-content 1fr">
            <FormLabel>Date:</FormLabel>
            <ReactDatePicker
              selected={new Date(event.start)}
              minDate={new Date()}
              onChange={(date) => {
                if (date) {
                  setEvent({ ...event, start: date.getTime() });
                }
              }}
              open={isDatePickerOpen}
              onInputClick={() => setIsDatePickerOpen(!isDatePickerOpen)}
            />

            <FormLabel>from:</FormLabel>
            <ReactDatePicker
              selected={new Date(event.start)}
              onChange={(date) => {
                const newEvent = { ...event };
                newEvent.start = date.getTime();
                if (newEvent.start > newEvent.end) {
                  newEvent.end = newEvent.start;
                }
                setEvent(newEvent);
              }}
              showTimeSelect
              showTimeSelectOnly
              minTime={
                new Date(event.start).toDateString() ===
                new Date().toDateString()
                  ? new Date()
                  : new Date().setHours(0, 0)
              }
              maxTime={new Date().setHours(23, 59)}
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="h:mm aa"
            />

            <FormLabel>to:</FormLabel>
            <ReactDatePicker
              selected={new Date(event.end)}
              onChange={(date) => {
                const newEvent = { ...event };
                newEvent.end = date.getTime();
                if (newEvent.start > newEvent.end) {
                  newEvent.start = newEvent.end;
                }
                setEvent(newEvent);
              }}
              showTimeSelect
              showTimeSelectOnly
              minTime={
                new Date(event.start).getDate() ===
                new Date(event.end).getDate()
                  ? new Date(event.start + 15 * 60000)
                  : new Date(0, 0, 0, 0, 0, 0)
              }
              maxTime={new Date().setHours(23, 59)}
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="h:mm aa"
            />
          </FormGrid>
          <FormLabel>Task Notes:</FormLabel>
          {event.notes &&
            event.notes.map((note, index) => (
              <TaskNote
                note={note}
                key={index}
                index={index}
                event={event}
                setEvent={setEvent}
              />
            ))}
          <ActionButton
            onClick={() => {
              let newEvent = JSON.parse(JSON.stringify(event));
              if (!newEvent.notes) {
                newEvent.notes = [];
              }
              newEvent.notes.push("");
              setEvent(newEvent);
            }}
          >
            Add Note
          </ActionButton>
          <FormRow style={{ justifyContent: "right" }}>
            <ConfirmButton
              type="button"
              onClick={updateTask}
              disabled={!edited}
              style={{
                opacity: edited ? 1 : 0.5,
                cursor: edited ? "pointer" : "not-allowed",
              }}
            >
              Update Task
            </ConfirmButton>
            <CancelButton type="button" onClick={deleteTask}>
              Delete Task
            </CancelButton>
          </FormRow>
        </FormSection>
      </SidebarBody>
    </>
  ) : null;
};

export default TaskSidebar;

function TaskNote({ note, index, event, setEvent }) {
  const setNote = (value) => {
    let newEvent = JSON.parse(JSON.stringify(event));
    newEvent.notes[index] = value;
    setEvent(newEvent);
  };
  return (
    <div style={{ position: "relative" }}>
      <AutoTextArea
        value={note}
        style={{ paddingTop: "20px" }}
        setValue={setNote}
        button={true}
      />
      <IconButton
        style={{ position: "absolute", top: "5px", right: "5px", zIndex: "1" }}
        size={15}
        color="black"
        onClick={() => {
          let newEvent = JSON.parse(JSON.stringify(event));
          newEvent.notes.splice(index, 1);
          setEvent(newEvent);
        }}
      >
        <Icons.CloseIcon />
      </IconButton>
    </div>
  );
}
