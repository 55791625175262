import React from "react";
import {
  ConfirmButton,
  HeaderLink,
  TextInput,
} from "../Scribi-Components/ScribiComponents";
import {
  resetPassWithEmail,
  signInToScribi,
} from "../utilities/firestore/authentication";
import { useLocation, useNavigate } from "react-router-dom";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth, googleProvider } from "../utilities/firestore/cloud-configs";
import { Scribi } from "../utilities/api/store/store";
import ScribiRestService from "../utilities/firestore/ScribiRestService";

const provider = new GoogleAuthProvider();
provider.setCustomParameters({ prompt: "select_account" });

const AuthBlock = () => {
  const { setModal } = React.useContext(Scribi);
  const navigate = useNavigate();
  const [userName, setUserName] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [loginState, setLoginState] = React.useState("login");
  const [errorMessage, setErrorMessage] = React.useState(null);
  const location = useLocation();
  const loginCode = location.search.split("=")[1];

  const handleLogInWithGoogle = async () => {
    try {
      await ScribiRestService.checkLoginCode(loginCode);
      signInWithPopup(auth, provider)
        .then((result) => {
          navigate("/");
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
      setModal({
        visible: true,
        message:
          "There was an error logging in. Currently Scribi is in closed testing and development. If you have a login link, please use the link to log in.",
        title: "Error Logging In",
        confirm: () => {
          setModal({ visible: false });
        },
      });
    }
  };

  return loginState === "login" ? (
    <div className="jumbo-auth-block">
      <img
        src="https://firebasestorage.googleapis.com/v0/b/scribi-backend.appspot.com/o/public%2Fsplash-title-graphic.png?alt=media&token=ec72c181-526e-423f-ba79-8fb95d1d1b7f"
        alt="Scribi Logo"
        className={"logoText"}
      />
      {errorMessage && <label style={{ color: "red" }}>{errorMessage}</label>}
      <TextInput
        type="email"
        placeholder="Email"
        value={userName}
        onChange={(e) => setUserName(e.target.value)}
      />
      <TextInput
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <ConfirmButton
        type="button"
        onClick={async () => {
          try {
            await ScribiRestService.checkLoginCode(loginCode);
            signInToScribi(userName, password);
          } catch (error) {
            setModal({
              visible: true,
              message:
                "There was an error logging in. Currently Scribi is in closed testing and development. If you have a login link, please use the link to log in.",
              title: "Error Logging In",
              confirm: () => {
                setModal({ visible: false });
              },
            });
          }
        }}
      >
        Login
      </ConfirmButton>
      <ConfirmButton type="button" onClick={handleLogInWithGoogle}>
        Login with Google
      </ConfirmButton>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <HeaderLink onClick={() => setLoginState("forgot")}>
          Forgot Password?
        </HeaderLink>
        <HeaderLink>Sign Up</HeaderLink>
      </div>
    </div>
  ) : (
    <div className="jumbo-auth-block">
      <img
        src="https://firebasestorage.googleapis.com/v0/b/scribi-backend.appspot.com/o/public%2Fsplash-title-graphic.png?alt=media&token=ec72c181-526e-423f-ba79-8fb95d1d1b7f"
        alt="Scribi Logo"
        className={"logoText"}
      />
      <TextInput
        type="email"
        placeholder="Email"
        value={userName}
        onChange={(e) => setUserName(e.target.value)}
      />
      <ConfirmButton
        onClick={() => {
          resetPassWithEmail(userName);
          setLoginState("login");
          setErrorMessage(
            "If you provided a valid Scribi email, you should receive a password reset email shortly. Please check your inbox."
          );
        }}
      >
        Reset Password
      </ConfirmButton>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <HeaderLink onClick={() => setLoginState("login")}>Sign In</HeaderLink>
        <HeaderLink onClick={() => navigate("/sign-up")}>Sign Up</HeaderLink>
      </div>
    </div>
  );
};

export default AuthBlock;
