/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useRef } from "react";
import { Scribi } from "../../utilities/api/store/store";
import {
  BodyContainer,
  IconButton,
} from "../../Scribi-Components/ScribiComponents";
import styled from "styled-components";
import { ProjectContext } from "../Main/OpenProject";
import { updateScribiDocument } from "../../utilities/firestore/db";
import Icons from "../../Icons/Icons";
import { useDraggable, useDroppable } from "@dnd-kit/core";

const StorybaseContainer = styled.div`
  display: flex;
  flex-direction: column;
  div.header {
    display: flex;
    flex-direction: row;
    background-color: ${({ theme }) => theme.primary};
    align-items: center;
    padding: 5px;
    margin-top: 1px;
    label {
      font-size: 1.3rem;
      font-weight: 600;
      padding: none;
    }
  }
  div.body {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 5px;
  }
`;

const StorybaseContext = React.createContext();

const StorybaseContextProvider = ({ children, filter, setTab, setElement }) => {
  const { project, worlds, contextMenu, setContextMenu, elements, currentDoc } =
    React.useContext(Scribi);

  const contextValue = {
    filter,
    setTab,
    setElement,
  };

  function filteredElements() {
    if (filter === "world") {
      return elements.filter((e) => e.world === project.world);
    }
    if (filter === "book") {
      if (project.standalone && project.standalone === "pen") {
        return elements.filter((e) => e.world === project.world);
      } else {
        return elements.filter((e) => project.elements.includes(e.id));
      }
    }
    if (filter === "chapter") {
      let chapterElements = [];
      function getElements(contents) {
        contents.forEach((c) => {
          if (c.id === currentDoc.id) {
            chapterElements = c.elements;
          } else if (c.children && c.children.length > 0) {
            getElements(c.children);
          }
        });
      }
      getElements(project.contents);
      return elements.filter((e) => chapterElements.includes(e.id));
    }
  }

  const worldElements = filteredElements();
  const world = worlds.find((w) => w.id === project.world);
  const schema = world?.schema;
  return (
    <StorybaseContext.Provider
      value={{
        project,
        worlds,
        world,
        schema,
        contextMenu,
        setContextMenu,
        elements: worldElements,
        setTab,
        contextValue,
      }}
    >
      {children}
    </StorybaseContext.Provider>
  );
};

const StorybaseSidebar = ({ filter, setTab, setElement }) => {
  return (
    <StorybaseContextProvider
      filter={filter}
      setTab={setTab}
      setElement={setElement}
    >
      <Storybase />
    </StorybaseContextProvider>
  );
};

const Storybase = () => {
  const { world } = React.useContext(StorybaseContext);

  const renderWorldTitle = () => {
    if (world.title) {
      return world.title;
    } else {
      return `Story World`;
    }
  };

  return (
    world && (
      <BodyContainer>
        <StorybaseContainer>
          <div className="body">
            <RenderStorybase />
          </div>
        </StorybaseContainer>
      </BodyContainer>
    )
  );
};

export default StorybaseSidebar;

function RenderStorybase() {
  const { project, worlds, world, schema, elements } =
    React.useContext(StorybaseContext);

  return (
    schema &&
    elements && (
      <>
        {elements
          .filter((e) => e.pinned)
          .map((element) => (
            <ElementBlock
              element={element}
              key={element.id}
              indent={0}
              pinned={true}
            />
          ))}
        {schema.types
          .sort((a, b) => a.label.localeCompare(b.label))
          .map((type) => (
            <TypeBlock type={type} key={type.label} />
          ))}
        <QuickElements />
      </>
    )
  );
}

export const ItemContainer = styled.div`
  display: grid;
  grid-template-columns: min-content auto;
  width: auto;
  padding: 5px;
  gap: 5px;
  background-color: ${({ theme }) => theme.secondary};
  align-items: center;
  box-sizing: border-box;
  svg.caret {
    width: 20px;
    height: 20px;
    fill: ${({ theme }) => theme.iconBase};
  }

  img {
    user-select: none;
  }
  label.type {
    grid-column: 2;

    font-size: 1.2em;
    font-weight: bold;
  }
  label.subtype {
    grid-column: 2;

    font-size: 1.1em;
    font-weight: normal;
  }
  label.element {
    grid-column: 2;
    font-size: 1em;
    font-weight: normal;
    user-select: none;
  }
  img.element {
    width: 20px;
    height: 30px;
    object-fit: cover;
  }
  button.pin {
    svg {
      fill: ${({ theme, pinned }) => (pinned ? "red" : theme.iconColor)};
      color: ${({ theme, pinned }) =>
        pinned ? "darkorange" : theme.iconColor};
    }
  }
`;

function TypeBlock({ type }) {
  const { setContextMenu, contextMenu, world, schema, elements } =
    React.useContext(StorybaseContext);
  const [open, setOpen] = React.useState(false);
  const blockRef = useRef(null);

  const { setNodeRef, isOver } = useDroppable({
    id: type.label,
    data: {
      schemaType: type.label,
      indent: null,
      context: "storybase-droppable",
    },
  });

  return (
    <>
      <ItemContainer
        ref={setNodeRef}
        onClick={() => setOpen(!open)}
        onContextMenu={(e) => {
          e.preventDefault();
          if (blockRef.current) {
            const rect = blockRef.current.getBoundingClientRect();
            setContextMenu({
              visible: !contextMenu.visible,
              x: rect.left,
              y: rect.bottom, // top edge positioned immediately below the button
              menu: "storybaseType",
              context: { type, world, schema },
            });
          }
        }}
      >
        <label className="type" ref={blockRef}>
          {type.label}
        </label>
      </ItemContainer>

      {open && (
        <>
          {elements
            .filter((e) => e.type === type.label && !e.subtype)
            .map((element) => (
              <ElementBlock element={element} key={element.name} indent={20} />
            ))}
          {type.subtypes.map((subtype) => (
            <SubtypeBlock
              subtype={subtype}
              key={subtype.label}
              parentType={type.label}
            />
          ))}
        </>
      )}
    </>
  );
}

function QuickElements() {
  const { elements, contextMenu, setContextMenu, world, schema } =
    React.useContext(StorybaseContext);
  const [open, setOpen] = React.useState(false);
  const blockRef = useRef(null);

  return (
    <>
      <ItemContainer
        onClick={() => setOpen(!open)}
        onContextMenu={(e) => {
          e.preventDefault();
          if (blockRef.current) {
            const rect = blockRef.current.getBoundingClientRect();
            setContextMenu({
              visible: !contextMenu.visible,
              x: rect.left,
              y: rect.bottom, // top edge positioned immediately below the button
              menu: "storybaseType",
              context: {
                type: { label: "quick-element", subtypes: [], attributes: [] },
                world,
                schema,
              },
            });
          }
        }}
      >
        <label className="type" ref={blockRef}>
          Quick Elements
        </label>
      </ItemContainer>
      {open && (
        <>
          {elements
            .filter((e) => e.type === "quick-element")
            .map((element) => (
              <ElementBlock element={element} key={element.name} indent={20} />
            ))}
        </>
      )}
    </>
  );
}

function SubtypeBlock({ subtype, parentType }) {
  const { elements } = React.useContext(StorybaseContext);
  const [open, setOpen] = React.useState(false);

  const { setNodeRef, isOver } = useDroppable({
    id: subtype.label,
    data: {
      schemaType: parentType,
      schemaSubType: subtype.label,
      indent: null,
      context: "storybase-droppable",
    },
  });

  return (
    <>
      <ItemContainer
        ref={setNodeRef}
        onClick={() => setOpen(!open)}
        style={{ marginLeft: `10px` }}
      >
        <label className="subtype">{subtype.label}</label>
      </ItemContainer>
      {open && (
        <>
          {elements
            .filter((e) => e.subtype === subtype.label)
            .map((element) => (
              <ElementBlock element={element} key={element.name} indent={40} />
            ))}
        </>
      )}
    </>
  );
}

function ElementBlock({ element, indent, pinned }) {
  const { setModal, note, setNote, setTab } = React.useContext(Scribi);

  const { contextValue } = React.useContext(StorybaseContext);

  const { setNodeRef, attributes, listeners, isDragging, transform } =
    useDraggable({
      id: pinned ? `pinned:${element.id}` : element.id,
      data: {
        type: "element",
        indent: null,
        context: "storybase-draggable",
        element: element,
      },
    });

  async function pinElement() {
    console.log("Attempting pin");
    try {
      if (element.pinned) {
        await updateScribiDocument({
          doctype: element.doctype,
          id: element.id,
          ownerId: element.ownerId,
          pinned: false,
          type: element.type,
          subtype: element.subtype,
        });
      } else if (!element.pinned) {
        await updateScribiDocument({
          doctype: element.doctype,
          id: element.id,
          ownerId: element.ownerId,
          pinned: true,
          type: element.type,
          subtype: element.subtype,
        });
      }
    } catch (error) {
      setModal({
        visible: true,
        title: "Error",
        message: `Something went wrong. ${error.message}`,
        confirm: () =>
          setModal({
            visible: false,
            title: "",
            message: "",
            confirm: null,
            cancel: null,
          }),
      });
    }
  }

  function openElement(id) {
    contextValue.setElement(id);
    contextValue.setTab("element");
  }

  return (
    <div ref={setNodeRef} {...listeners} {...attributes}>
      <ItemContainer
        style={{
          marginLeft: `${indent}px`,
          gridTemplateColumns: `min-content auto min-content`,
        }}
        title={element.description}
        pinned={element.pinned}
        onClick={() => openElement(element.id)}
      >
        <img className="element" src={element.image} />
        <label className="element">{element.name}</label>
        <IconButton size="15" className="pin" onClick={pinElement}>
          <Icons.PinIcon />
        </IconButton>
      </ItemContainer>
    </div>
  );
}
