/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { AppContainer } from "./Scribi-Components/ScribiComponents";
import Header from "./Header/Header";
import JumboSplash from "./Splash/JumboSplash";
import { Scribi } from "./utilities/api/store/store";
import { auth } from "./utilities/firestore/cloud-configs";
import Dashboard from "./Dashboard/Dashboard";
import WorkHeader from "./Header/WorkHeader";
import DesktopUserMenu from "./Menus/DesktopUserMenu";
import { ThemeProvider } from "styled-components";
import { Themes } from "./Scribi-Components/ScribiComponents";
import MessageModal from "./MessageModal/MessageModal";
import Policies from "./Policies/policies";
import { Route, Router, Routes } from "react-router-dom";
import TOC from "./Policies/toc";
import Support from "./Support/Support";
import Footer from "./Footer/Footer";
import ContextMenu from "./ContextMenu/ContextMenu";
import FormModal from "./FormModal/FormModal";
import {
  DndContext,
  DragOverlay,
  MouseSensor,
  TouchSensor,
  pointerWithin,
  useDndContext,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import DragPreview from "./utilities/DragPreview/DragPreview";
import DragHandleManager from "./utilities/api/store/DragHandleManager";

const App = () => {
  const {
    user,
    setUser,
    showUserMenu,
    settings,
    clearContext,
    location,
    contextMenu,
    dragging,
    setDragging,
  } = React.useContext(Scribi);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      // Require the mouse to move by 10 pixels before activating
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor, {
      // Press delay of 250ms, with tolerance of 5px of movement
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    })
  );

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
        clearContext();
      }
    });
  }, [user, setUser]);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={pointerWithin}
      onDragStart={() => setDragging(true)}
      onDragEnd={() => setDragging(false)}
    >
      <ThemeProvider theme={Themes[settings.theme] || Themes.scribi}>
        <DragHandleManager>
          <AppContainer>
            {user ? <WorkHeader /> : <Header />}
            <div style={{ height: "100%", width: "100%", overflow: "hidden" }}>
              <Routes>
                <Route path="/privacy" element={<Policies />} />
                <Route path="/terms-and-conditions" element={<TOC />} />
                <Route path="/support" element={<Support />} />
                <Route
                  path="*"
                  element={user ? <Dashboard /> : <JumboSplash />}
                />
              </Routes>
            </div>
            <Footer />
            {showUserMenu && <DesktopUserMenu />}
            {contextMenu.visible && <ContextMenu />}
            <MessageModal />
            <FormModal />
          </AppContainer>
        </DragHandleManager>
      </ThemeProvider>
    </DndContext>
  );
};

export default App;
