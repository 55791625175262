import React, { useContext, useState, useEffect, useRef } from "react";
import { Scribi } from "../utilities/api/store/store";
import { v4 as uuidv4 } from "uuid";
import {
  ActionButton,
  AutoTextArea,
  BodyContainer,
  CancelButton,
  ConfirmButton,
  DropArea,
  FormContainer,
  FormGrid,
  FormGridHeading,
  FormInput,
  FormLabel,
  FormLabeledCheckBox,
  FormRow,
  FormSection,
  FormSectionTitle,
  FormSelect,
  FormTextArea,
  FormTitle,
  HrDivider,
  IconButton,
  Tag,
} from "../Scribi-Components/ScribiComponents";
import Icons from "../Icons/Icons";
import { useImageUpload } from "../utilities/hooks/UseImageUpload";
import {
  createScribiDocument,
  updateScribiDocument,
} from "../utilities/firestore/db";
import ScribiRestService from "../utilities/firestore/ScribiRestService";
import ReactDatePicker from "react-datepicker";

const fullDays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const BookContext = React.createContext();

const BookContextProvider = ({ children }) => {
  const {
    location,
    navigate,
    pens,
    worlds,
    series,
    books,
    setModal,
    deleteBook,
    user,
    settings,
    today,
    calendarEvents,
  } = React.useContext(Scribi);
  const bookId = location.state?.book?.id ? location.state.book.id : null;
  const [newTag, setNewTag] = useState("");
  const [newCover, setNewCover] = useState(null);
  const { getRootProps, getInputProps } = useImageUpload(setNewCover);
  const [useWorkdays, setUseWorkdays] = useState(1);
  const [brainstormDays, setBrainstormDays] = useState(0);
  const [beatsheetDays, setBeatsheetDays] = useState(0);
  const [outlineDays, setOutlineDays] = useState(0);
  const [draftDays, setDraftDays] = useState(0);
  const [editDays, setEditDays] = useState(0);
  const [wordCountEstimate, setWordCountEstimate] = useState(0);
  const [isSchedule, setIsSchedule] = useState(false);
  const [totalDays, setTotalDays] = useState(0);
  const [taskArray, setTaskArray] = useState([]);
  const [brainstormHours, setBrainstormHours] = useState(1);
  const [beatsheetHours, setBeatsheetHours] = useState(1);
  const [outlineHours, setOutlineHours] = useState(1);
  const [draftHours, setDraftHours] = useState(1);
  const [editHours, setEditHours] = useState(1);
  const [hourlyWordCountEstimate, setHourlyWordCountEstimate] = useState(0);
  const initialDueDate = new Date();
  initialDueDate.setDate(initialDueDate.getDate() + 1);
  const [newPen, setNewPen] = useState({
    id: uuidv4(),
    ownerId: user.uid,
    created: Date.now(),
    editors: [],
    viewers: [],
    fullName: "",
    firstName: "",
    lastName: "",
    description: "",
    authorBio: "",
    authorPhoto: "",
    authorLogo: "",
    links: [],
    public: false,
    doctype: "pens",
    notes: [],
    tags: [],
  });
  const [newWorld, setNewWorld] = useState({
    id: uuidv4(),
    ownerId: user.uid,
    title: "",
    plan: "",
    description: "",
    editors: [],
    viewers: [],
    public: false,
    pen: "",
    doctype: "worlds",
    created: Date.now(),
    tags: [],
    schema: {
      title: "",
      description: "",
      types: [
        {
          label: "Characters",
          subtypes: [],
          attributes: [],
        },
        {
          label: "Locations",
          subtypes: [],
          attributes: [],
        },
      ],
    },
    notes: [],
    logo: null,
  });
  const [newSeries, setNewSeries] = useState({
    title: "",
    id: uuidv4(),
    ownerId: user.uid,
    created: Date.now(),
    editors: [],
    viewers: [],
    notes: [],
    public: false,
    logo: "",
    plan: "",
    description: "",
    world: "",
    pen: "",
    tags: [],
    doctype: "series",
  });
  const [standaloneWorld, setStandaloneWorld] = useState({
    id: uuidv4(),
    ownerId: user.uid,
    title: "",
    pen: null,
    notes: [],
    editors: [],
    viewers: [],
    schema: {
      title: "",
      description: "",
      types: [
        {
          label: "Characters",
          subtypes: [],
          attributes: [],
        },
        {
          label: "Locations",
          subtypes: [],
          attributes: [],
        },
      ],
    },
    public: false,
    doctype: "worlds",
  });

  const [book, setBook] = React.useState(
    location.state?.book || {
      id: uuidv4(),
      ownerId: user.uid,
      doctype: "books",
      title: "",
      pen: "",
      world: "",
      series: "",
      standalone: null,
      description: "",
      cover: "",
      tags: [],
      outline: "",
      editors: [],
      viewers: [],
      notes: [],
      contents: [],
      isProject: false,
      targetWords: 0,
      currentWords: 0,
      schedule: {
        startDate: today,
        dueDate: initialDueDate,
        brainstorm: [
          {
            start: null,
            end: null,
          },
        ],
        beatsheet: [
          {
            start: null,
            end: null,
          },
        ],
        outline: [
          {
            start: null,
            end: null,
          },
        ],
        draft: [
          {
            start: null,
            end: null,
            target: 0,
            total: 0,
          },
        ],
        edit: [
          {
            start: null,
            end: null,
          },
        ],
      },
    }
  );

  useEffect(() => {
    setNewWorld({ ...newWorld, id: uuidv4() });
    setStandaloneWorld({ ...standaloneWorld, id: uuidv4() });
  }, [location]);

  const findFreeSlots = (date) => {
    const dayIndex = date.getDay();
    const dayName = fullDays[dayIndex];
    const preferredHours = [];
    settings.hours[dayName].hours.forEach((block) => {
      const [startHour, startMin] = block.start.split(":").map(Number);
      const [endHour, endMin] = block.end.split(":").map(Number);
      const startDate = new Date(date);
      const endDate = new Date(date);
      startDate.setHours(startHour, startMin, 0, 0);
      endDate.setHours(endHour, endMin, 0, 0);

      for (
        let currentTime = startDate.getTime();
        currentTime < endDate.getTime();
        currentTime += 15 * 60 * 1000 // Add 15 minutes in milliseconds
      ) {
        preferredHours.push(currentTime);
      }
    });
  };

  const saveBook = async () => {
    if (book.title.trim().length === 0) {
      setModal({
        visible: true,
        message: "Please enter book a title.",
        title: "Error",
        confirm: () => {
          setModal({ visible: false });
        },
      });
      return;
    }
    if (book.pen.trim().length === 0) {
      setModal({
        visible: true,
        message: "Please select a pen name.",
        title: "Error",
        confirm: () => {
          setModal({ visible: false });
        },
      });
      return;
    }
    let newBook = JSON.parse(JSON.stringify(book));
    let bookNewPen = JSON.parse(JSON.stringify(newPen));
    let bookNewWorld = JSON.parse(JSON.stringify(newWorld));
    let bookNewSeries = JSON.parse(JSON.stringify(newSeries));
    if (book.pen === newPen.id) {
      bookNewPen.fullName = `${bookNewPen.firstName}${
        bookNewPen.lastName.trim().length > 0 ? ` ${bookNewPen.lastName}` : ""
      }`;
      createScribiDocument(bookNewPen);
    }
    if (book.world === newWorld.id) {
      bookNewWorld.pen = newBook.pen;
      createScribiDocument(bookNewWorld);
    }
    if (book.series === newSeries.id) {
      bookNewSeries.pen = newBook.pen;
      bookNewSeries.world = newBook.world;
      createScribiDocument(bookNewSeries);
    }
    if (book.world === standaloneWorld.id) {
      newBook.world = standaloneWorld.id;
      newBook.standalone = "pen";
      newBook.series = null;
      createScribiDocument(standaloneWorld);
    }
    if (book.series === "world-standalone") {
      newBook.series = null;
      newBook.standalone = "world";
    }
    if (newCover) {
      let coverUrl = await ScribiRestService.uploadImage(newCover);
      newBook.cover = coverUrl.downloadURL;
    }
    if (bookId) {
      let res = await updateScribiDocument(newBook);
      if (res.isUpdated) {
        setModal({
          visible: true,
          message: "Book updated successfully.",
          title: "Success",
          confirm: () => {
            navigate("/book", { state: { book: newBook } });
            setModal({ visible: false });
          },
        });
      } else {
        setModal({
          visible: true,
          message: "Error updating book.",
          title: "Error",
          confirm: () => {
            setModal({ visible: false });
          },
        });
      }
    } else {
      if (book.isProject) {
        //Tomorrow TODO: Create a new documents for the book and work out how that fits into the manuscript navigation.
        let newDocId = uuidv4();
        newBook.contents = [
          {
            type: "document",
            versions: [
              {
                id: newDocId,
                description: "Original",
                version: 1,
              },
            ],
            words: 0,
            heading: "",
            subheading: "",
            id: newDocId,
            outline: {
              type: "doc",
              content: [
                {
                  type: "paragraph",
                },
              ],
            },
            elements: [],
            notes: [],
          },
        ];
        let newDocument = {
          id: newDocId,
          ownerId: user.uid,
          doctype: "documents",
          content: {
            type: "doc",
            content: [
              {
                type: "paragraph",
              },
            ],
          },
          words: 0,
          editors: [],
          viewers: [],
          notes: [],
        };

        try {
          await createScribiDocument(newDocument);
          await createScribiDocument(newBook);
          setModal({
            visible: true,
            message: "Book created successfully.",
            title: "Success",
            confirm: () => {
              navigate("/book", { state: { book: newBook } });
              setModal({ visible: false });
            },
          });
        } catch (error) {
          console.log(error);
          setModal({
            visible: true,
            message: "Error creating book.",
            title: "Error",
            confirm: () => {
              setModal({ visible: false });
            },
          });
        }
      } else {
        let res = await createScribiDocument(newBook);
        if (res.isCreated) {
          setModal({
            visible: true,
            message: "Book created successfully.",
            title: "Success",
            confirm: () => {
              navigate("/book", { state: { book: newBook } });
              setModal({ visible: false });
            },
          });
        } else {
          setModal({
            visible: true,
            message: "Error creating book.",
            title: "Error",
            confirm: () => {
              setModal({ visible: false });
            },
          });
        }
      }
    }
  };

  const getTotalDays = (start, end, workDays) => {
    let startDate = new Date(start);
    let endDate = new Date(end);
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(0, 0, 0, 0);
    let startValue = startDate.getTime();
    let endValue = endDate.getTime();
    let days = 0;
    while (startValue <= endValue) {
      if (workDays) {
        if (settings.hours[fullDays[startDate.getDay()]].active) {
          days++;
        }
        //increment startDate by one day, set startValue to new startDate.getTime()
        startDate.setDate(startDate.getDate() + 1);
        startValue = startDate.getTime();
      } else {
        days++;
        //increment startDate by one day, set startValue to new startDate.getTime()
        startDate.setDate(startDate.getDate() + 1);
        startValue = startDate.getTime();
      }
    }
    setTotalDays(days);
  };

  const setDayValues = (
    totalDays,
    brainstormDays,
    beatsheetDays,
    outlineDays,
    editDays
  ) => {
    let totalDraftDays =
      totalDays - brainstormDays - beatsheetDays - outlineDays - editDays;
    setDraftDays(totalDraftDays);
  };

  // useEffect(() => {
  //   if (isSchedule) {
  //     if (useWorkdays > 0) {
  //       getTotalDays(
  //         book.schedule.startDate,
  //         book.schedule.dueDate,
  //         useWorkdays
  //       );
  //     } else {
  //       getTotalDays(book.schedule.startDate, book.schedule.dueDate);
  //     }
  //   }
  // }, [book.schedule.startDate, book.schedule.dueDate, useWorkdays]);

  // useEffect(() => {
  //   setDayValues(
  //     totalDays,
  //     brainstormDays,
  //     beatsheetDays,
  //     outlineDays,
  //     editDays
  //   );
  // }, [totalDays, brainstormDays, beatsheetDays, outlineDays, editDays]);

  // useEffect(() => {
  //   let newStart = new Date(book.schedule.startDate);
  //   let newEnd = new Date(book.schedule.dueDate);
  //   let day = newStart.getDate();
  //   let month = newStart.getMonth();
  //   let year = newStart.getFullYear();
  //   //Check to see if newEnd is before newStart. If so, set book.schedule.dueDate to one day after start.
  //   if (newEnd <= newStart) {
  //     newEnd = new Date(year, month, day + 1);
  //     setBook({
  //       ...book,
  //       schedule: {
  //         ...book.schedule,
  //         dueDate: newEnd,
  //       },
  //     });
  //   }
  // }, [book.schedule.startDate, book.schedule.dueDate]);

  useEffect(() => {
    if (location.state?.book) {
      setBook({
        id: location.state.book.id || uuidv4(),
        ownerId: user.uid,
        doctype: location.state.book.doctype || "books",
        title: location.state.book.title || "",
        pen: location.state.book.pen || "",
        world: location.state.book.world || "",
        series: location.state.book.series || "",
        standalone: location.state.book.standalone || null,
        description: location.state.book.description || "",
        cover: location.state.book.cover || "",
        tags: location.state.book.tags || [],
        outline: location.state.book.outline || "",
        editors: location.state.book.editors || [],
        viewers: location.state.book.viewers || [],
        notes: location.state.book.notes || [],
        contents: location.state.book.contents || [],
        isProject: location.state.book.isProject || false,
        targetWords: location.state.book.targetWords || 0,
        currentWords: location.state.book.currentWords || 0,
        schedule: location.state.book.schedule || {
          startDate: Date.now(),
          dueDate: null,
          brainstorm: [
            {
              start: null,
              end: null,
            },
          ],
          beatsheet: [
            {
              start: null,
              end: null,
            },
          ],
          outline: [
            {
              start: null,
              end: null,
            },
          ],
          draft: [
            {
              start: null,
              end: null,
              target: 0,
              total: 0,
            },
          ],
          edit: [
            {
              start: null,
              end: null,
            },
          ],
        },
      });
    } else {
      setBook({
        id: uuidv4(),
        ownerId: user.uid,
        doctype: "books",
        title: "",
        pen: "",
        world: "",
        series: "",
        standalone: null,
        description: "",
        cover: "",
        tags: [],
        outline: "",
        editors: [],
        viewers: [],
        notes: [],
        contents: [],
        isProject: false,
        targetWords: 0,
        currentWords: 0,
        schedule: {
          startDate: today,
          dueDate: initialDueDate,
          brainstorm: [
            {
              start: null,
              end: null,
            },
          ],
          beatsheet: [
            {
              start: null,
              end: null,
            },
          ],
          outline: [
            {
              start: null,
              end: null,
            },
          ],
          draft: [
            {
              start: null,
              end: null,
              target: 0,
              total: 0,
            },
          ],
          edit: [
            {
              start: null,
              end: null,
            },
          ],
        },
      });
    }
  }, [location.state?.book]);

  useEffect(() => {
    let dailyTarget = Math.round(book.targetWords / draftDays);
    setWordCountEstimate(dailyTarget);
    setHourlyWordCountEstimate(Math.round(dailyTarget / draftHours));
  }, [draftDays, book.targetWords, draftHours]);

  return (
    <BookContext.Provider
      value={{
        hourlyWordCountEstimate,
        findFreeSlots,
        totalDays,
        setTotalDays,
        taskArray,
        setTaskArray,
        bookId,
        saveBook,
        isSchedule,
        setIsSchedule,
        initialDueDate,
        location,
        navigate,
        pens,
        worlds,
        series,
        books,
        setModal,
        deleteBook,
        user,
        settings,
        today,
        book,
        setBook,
        newPen,
        setNewPen,
        newWorld,
        setNewWorld,
        newSeries,
        setNewSeries,
        standaloneWorld,
        setStandaloneWorld,
        newTag,
        setNewTag,
        useWorkdays,
        setUseWorkdays,
        brainstormDays,
        setBrainstormDays,
        beatsheetDays,
        setBeatsheetDays,
        outlineDays,
        setOutlineDays,
        draftDays,
        setDraftDays,
        editDays,
        setEditDays,
        wordCountEstimate,
        setWordCountEstimate,
        brainstormHours,
        setBrainstormHours,
        beatsheetHours,
        setBeatsheetHours,
        outlineHours,
        setOutlineHours,
        draftHours,
        setDraftHours,
        editHours,
        setEditHours,
        getRootProps,
        getInputProps,
        newCover,
        setNewCover,
      }}
    >
      {children}
    </BookContext.Provider>
  );
};

const ManageBook = () => {
  return (
    <BookContextProvider>
      <FormSelection />
    </BookContextProvider>
  );
};

export default ManageBook;

const FormSelection = () => {
  const { location, navigate } = useContext(BookContext);
  return <BookForm />;
  // if (
  //   (location.state?.form === null || !location.state?.form) &&
  //   !location.state?.book
  // ) {
  //   return (
  //     <BodyContainer>
  //       <FormContainer>
  //         <FormTitle>Create a New Book</FormTitle>
  //         <HrDivider />
  //         <p>
  //           Would you like to use the single book creation form or the book
  //           creation wizard? The book creation form is good for creating one
  //           book at a time and allows you to see the entire form at once. The
  //           book creation wizard allows you to create and manage one or multiple
  //           books at once, and is good for scheduling multiple book projects as
  //           it coordinates scheduling across multiple titles.
  //         </p>
  //         <FormRow>
  //           <ActionButton
  //             type="button"
  //             onClick={() =>
  //               navigate("/book", { state: { book: null, form: "manual" } })
  //             }
  //           >
  //             Use Single Book Creation Form
  //           </ActionButton>
  //           <ActionButton
  //             type="button"
  //             onClick={() =>
  //               navigate("/book", { state: { book: null, form: "wizard" } })
  //             }
  //           >
  //             Use Book Creation Wizard
  //           </ActionButton>
  //         </FormRow>
  //       </FormContainer>
  //     </BodyContainer>
  //   );
  // }

  // if (location.state?.form === "manual") {
  //   return <BookForm />;
  // }

  // if (location.state?.form === "wizard") {
  //   return <BookWizard />;
  // }

  // if (location.state?.book) {
  //   return <ManageBookForm />;
  // }
};

const BookForm = () => {
  const {
    initialDueDate,
    bookId,
    saveBook,
    isSchedule,
    setIsSchedule,
    location,
    navigate,
    pens,
    worlds,
    series,
    books,
    setModal,
    deleteBook,
    user,
    settings,
    today,
    book,
    setBook,
    newPen,
    setNewPen,
    newWorld,
    setNewWorld,
    newSeries,
    setNewSeries,
    standaloneWorld,
    setStandaloneWorld,
    newTag,
    setNewTag,
    useWorkdays,
    setUseWorkdays,
    brainstormDays,
    setBrainstormDays,
    beatsheetDays,
    setBeatsheetDays,
    outlineDays,
    setOutlineDays,
    draftDays,
    setDraftDays,
    editDays,
    setEditDays,
    wordCountEstimate,
    setWordCountEstimate,
    totalDays,
    setTotalDays,
    taskArray,
    setTaskArray,
    brainstormHours,
    setBrainstormHours,
    beatsheetHours,
    setBeatsheetHours,
    outlineHours,
    setOutlineHours,
    draftHours,
    setDraftHours,
    editHours,
    setEditHours,
    hourlyWordCountEstimate,
    findFreeSlots,
    getRootProps,
    getInputProps,
    newCover,
  } = useContext(BookContext);

  return (
    book && (
      <BodyContainer>
        <FormContainer>
          <FormRow>
            <FormTitle>
              {bookId ? `Manage ${book?.title}` : "Create Book"}
            </FormTitle>
            {bookId && (
              <CancelButton style={{ marginLeft: "auto" }}>
                Delete Book
              </CancelButton>
            )}
          </FormRow>
          <FormGrid
            columns="min-content 1fr"
            style={{ alignItems: "flex-start" }}
          >
            <FormLabel>Title:</FormLabel>
            <FormInput
              value={book.title}
              onChange={(e) => setBook({ ...book, title: e.target.value })}
            />
            <FormLabel>Description:</FormLabel>
            <AutoTextArea
              placeholder={"Reader facing book description/blurb."}
              value={book.description}
              setValue={(value) => setBook({ ...book, description: value })}
            />
            <FormLabel>Brief Outline:</FormLabel>
            <AutoTextArea
              placeholder={"A brief internal facing outline of the book."}
              value={book.outline}
              setValue={(value) => setBook({ ...book, outline: value })}
            />
            <FormLabel>Tags:</FormLabel>
            <FormInput
              type="text"
              placeholder="Press enter to add a tag"
              value={newTag}
              onChange={(e) => setNewTag(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  const newTags = JSON.parse(JSON.stringify(book.tags));
                  newTags.push(newTag.toLowerCase());
                  setBook({ ...book, tags: newTags });
                  setNewTag("");
                }
              }}
            />
            {book.tags.length > 0 && (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "5px",
                  gridColumn: "2",
                }}
              >
                {book.tags.map((tag, i) => (
                  <Tag
                    key={i}
                    tag={tag}
                    index={i}
                    state={book}
                    setState={setBook}
                  />
                ))}
              </div>
            )}
            <FormLabel>Book Cover:</FormLabel>
            <DropArea
              {...getRootProps()}
              orientation="portrait"
              style={{ justifySelf: "center", alignSelf: "center" }}
            >
              <input {...getInputProps()} />
              {newCover ? (
                <img src={newCover} alt="Book cover" />
              ) : book.cover ? (
                <img src={book.cover} alt="Book cover" />
              ) : (
                "Click to upload, or drop image here"
              )}
            </DropArea>
            <HrDivider style={{ gridColumn: "1 / span 2" }} />
            <FormLabel>Pen Name:</FormLabel>
            <FormSelect
              value={book.pen}
              onChange={(e) => setBook({ ...book, pen: e.target.value })}
            >
              <option value="">Select a Pen</option>
              {pens.map((pen, index) => (
                <option value={pen.id} key={index}>
                  {pen.fullName}
                </option>
              ))}
              <option value={newPen.id}>Create New Pen Name</option>
            </FormSelect>
            {book.pen === newPen.id && (
              <>
                <FormLabel>Pen First Name:</FormLabel>
                <FormInput
                  value={newPen.firstName}
                  onChange={(e) =>
                    setNewPen({ ...newPen, firstName: e.target.value })
                  }
                />
                <FormLabel>Pen Last Name:</FormLabel>
                <FormInput
                  value={newPen.lastName}
                  onChange={(e) =>
                    setNewPen({ ...newPen, lastName: e.target.value })
                  }
                />
              </>
            )}
            <FormLabel>Story World:</FormLabel>
            <FormSelect
              value={book.world}
              onChange={(e) => setBook({ ...book, world: e.target.value })}
            >
              <option value="">Select a World</option>
              <option value={standaloneWorld.id}>Standalone Book</option>
              {worlds
                .filter((world) => world.pen === book.pen)
                .map((world, index) => (
                  <option value={world.id} key={index}>
                    {world.title}
                  </option>
                ))}
              <option value={newWorld.id}>Create New World</option>
            </FormSelect>
            {book.world === newWorld.id && (
              <>
                <FormLabel>Story World Title:</FormLabel>
                <FormInput
                  value={newWorld.title}
                  onChange={(e) =>
                    setNewWorld({ ...newWorld, title: e.target.value })
                  }
                />
              </>
            )}
            <FormLabel>Series:</FormLabel>
            <FormSelect
              value={book.series}
              onChange={(e) => setBook({ ...book, series: e.target.value })}
            >
              <option value="">Select a Series</option>
              {book.world && (
                <option value="world-standalone">
                  Standalone Book in{" "}
                  {worlds.find((world) => world.id === book.world)?.title} story
                  world
                </option>
              )}
              {series
                .filter((series) => series.world === book.world)
                .map((series, index) => (
                  <option value={series.id} key={index}>
                    {series.title}
                  </option>
                ))}
              <option value={newSeries.id}>Create New Series</option>
            </FormSelect>
            {book.series === newSeries.id && (
              <>
                <FormLabel>Series Title:</FormLabel>
                <FormInput
                  value={newSeries.title}
                  onChange={(e) =>
                    setNewSeries({ ...newSeries, title: e.target.value })
                  }
                />
              </>
            )}
            <HrDivider style={{ gridColumn: "1 / span 2" }} />
            <input
              className="grid-checkbox"
              type="checkbox"
              checked={book.isProject}
              onChange={(e) =>
                setBook({ ...book, isProject: e.target.checked })
              }
            />
            <FormLabel>Create Book Project</FormLabel>
            {book.isProject && (
              <>
                <FormLabel>Target Words:</FormLabel>
                <FormInput
                  type="number"
                  min={0}
                  value={book.targetWords}
                  onChange={(e) =>
                    setBook({ ...book, targetWords: parseInt(e.target.value) })
                  }
                />
              </>
            )}
            {/* {book.isProject && (
              <>
                <FormLabel>Target Words:</FormLabel>
                <FormInput
                  type="number"
                  min={0}
                  value={book.schedule.targetWords}
                  onChange={(e) =>
                    setBook({
                      ...book,
                      schedule: {
                        ...book.schedule,
                        targetWords: e.target.value,
                      },
                    })
                  }
                />
                <input
                  className="grid-checkbox"
                  type="checkbox"
                  checked={isSchedule}
                  onChange={(e) => setIsSchedule(e.target.checked)}
                />
                <FormLabel>Use Schedule</FormLabel>
                {isSchedule && (
                  <>
                    <FormLabel>Timeline:</FormLabel>
                    <FormRow>
                      <FormLabel>Start Date:</FormLabel>
                      <ReactDatePicker
                        selected={book.schedule.startDate}
                        minDate={Date.now()}
                        onChange={(date) =>
                          setBook({
                            ...book,
                            schedule: {
                              ...book.schedule,
                              startDate: date,
                            },
                          })
                        }
                      />
                      <FormLabel>Due Date:</FormLabel>
                      <ReactDatePicker
                        selected={book.schedule.dueDate}
                        minDate={book.schedule.startDate}
                        onChange={(date) =>
                          setBook({
                            ...book,
                            schedule: {
                              ...book.schedule,
                              dueDate: date,
                            },
                          })
                        }
                      />
                      <FormSelect
                        value={useWorkdays}
                        onChange={(e) => setUseWorkdays(e.target.value)}
                      >
                        <option value={1}>Use only workdays</option>
                        <option value={0}>Use all days</option>
                      </FormSelect>
                    </FormRow>
                    <FormLabel>Schedule:</FormLabel>
                    <FormGrid columns="min-content min-content 1fr">
                      <FormLabel>Brainstorming:</FormLabel>
                      <FormRow>
                        <input
                          type="number"
                          min={0}
                          value={brainstormDays}
                          onChange={(e) => setBrainstormDays(e.target.value)}
                        />
                        <FormLabel>days</FormLabel>
                      </FormRow>
                      <FormRow>
                        <FormLabel>for</FormLabel>
                        <FormSelect
                          value={brainstormHours}
                          onChange={(e) => setBrainstormHours(e.target.value)}
                        >
                          {Array.from(Array(8).keys()).map((num) => (
                            <option key={num} value={num + 1}>
                              {num + 1} hours per day
                            </option>
                          ))}
                        </FormSelect>
                      </FormRow>
                      <FormLabel>Beatsheet:</FormLabel>
                      <FormRow>
                        <input
                          type="number"
                          min={0}
                          value={beatsheetDays}
                          onChange={(e) => setBeatsheetDays(e.target.value)}
                        />
                        <FormLabel>days</FormLabel>
                      </FormRow>
                      <FormRow>
                        <FormLabel>for</FormLabel>
                        <FormSelect
                          value={beatsheetHours}
                          onChange={(e) => setBeatsheetHours(e.target.value)}
                        >
                          {Array.from(Array(8).keys()).map((num) => (
                            <option key={num} value={num + 1}>
                              {num + 1} hours per day
                            </option>
                          ))}
                        </FormSelect>
                      </FormRow>
                      <FormLabel>Outlining:</FormLabel>
                      <FormRow>
                        <input
                          type="number"
                          min={0}
                          value={outlineDays}
                          onChange={(e) => setOutlineDays(e.target.value)}
                        />
                        <FormLabel>days</FormLabel>
                      </FormRow>
                      <FormRow>
                        <FormLabel>for</FormLabel>
                        <FormSelect
                          value={outlineHours}
                          onChange={(e) => setOutlineHours(e.target.value)}
                        >
                          {Array.from(Array(8).keys()).map((num) => (
                            <option key={num} value={num + 1}>
                              {num + 1} hours per day
                            </option>
                          ))}
                        </FormSelect>
                      </FormRow>
                      <FormLabel>Drafting:</FormLabel>
                      <FormRow>
                        <input
                          type="number"
                          min={0}
                          disabled={true}
                          value={draftDays}
                        />
                        <FormLabel>days</FormLabel>
                      </FormRow>
                      <FormRow>
                        <FormLabel>for</FormLabel>
                        <FormSelect
                          value={draftHours}
                          onChange={(e) => setDraftHours(e.target.value)}
                        >
                          {Array.from(Array(8).keys()).map((num) => (
                            <option key={num} value={num + 1}>
                              {num + 1} hours per day
                            </option>
                          ))}
                        </FormSelect>
                      </FormRow>
                      <FormLabel>Editing:</FormLabel>
                      <FormRow>
                        <input
                          type="number"
                          min={0}
                          value={editDays}
                          onChange={(e) => setEditDays(e.target.value)}
                        />
                        <FormLabel>days</FormLabel>
                      </FormRow>
                      <FormRow>
                        <FormLabel>for</FormLabel>
                        <FormSelect
                          value={editHours}
                          onChange={(e) => setEditHours(e.target.value)}
                        >
                          {Array.from(Array(8).keys()).map((num) => (
                            <option key={num} value={num + 1}>
                              {num + 1} hours per day
                            </option>
                          ))}
                        </FormSelect>
                      </FormRow>
                      <FormLabel>Total Working Days: {totalDays}</FormLabel>
                      <FormLabel>
                        Daily word target estimate: {wordCountEstimate}
                      </FormLabel>
                      <FormLabel>
                        Hourly word target estimate: {hourlyWordCountEstimate}
                      </FormLabel>
                      <ActionButton
                        type="button"
                        onClick={() => findFreeSlots(today)}
                      >
                        Check Schedule
                      </ActionButton>
                    </FormGrid>
                  </>
                )}
              </>
            )} */}
          </FormGrid>
          <FormRow justifyContent="right">
            <ConfirmButton type="button" onClick={saveBook}>
              {bookId ? "Save" : "Create"}
            </ConfirmButton>
            <CancelButton
              type="button"
              onClick={() => navigate("/", { state: null })}
            >
              Cancel
            </CancelButton>
          </FormRow>
        </FormContainer>
      </BodyContainer>
    )
  );
};

const BookWizard = () => {
  const context = useContext(Scribi);

  return (
    <BodyContainer>
      <FormContainer>
        <FormTitle>Book Creation Wizard</FormTitle>
        <HrDivider />
      </FormContainer>
    </BodyContainer>
  );
};

const ManageBookForm = () => {
  const context = useContext(Scribi);

  return (
    <BodyContainer>
      <FormContainer>
        <FormTitle>Manage Book</FormTitle>
      </FormContainer>
    </BodyContainer>
  );
};
