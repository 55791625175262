import React, { useContext, useState, useEffect, useRef } from "react";
import { Scribi } from "../utilities/api/store/store";
import { useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import {
  ActionButton,
  BodyContainer,
  CancelButton,
  ConfirmButton,
  DropArea,
  FormContainer,
  FormGrid,
  FormGridHeading,
  FormInput,
  FormLabel,
  FormRow,
  FormSection,
  FormSectionTitle,
  FormSelect,
  FormTextArea,
  FormTitle,
  HrDivider,
  IconButton,
  Tag,
} from "../Scribi-Components/ScribiComponents";
import Icons from "../Icons/Icons";
import { useImageUpload } from "../utilities/hooks/UseImageUpload";
import {
  createScribiDocument,
  updateScribiDocument,
} from "../utilities/firestore/db";
import ScribiRestService from "../utilities/firestore/ScribiRestService";

const ManageWorld = () => {
  const { pens, user, setModal, series, books, navigate } = useContext(Scribi);
  const location = useLocation();
  const [pen, setPen] = useState(location.state ? location.state.pen : null);
  const worldId = location.state?.world?.id ? location.state.world.id : null;
  const [notes, setNotes] = useState([]);
  const newPenId = useRef(uuidv4());
  const [isNewPen, setIsNewPen] = useState(false);
  const [newPen, setNewPen] = useState({
    firstName: "",
    lastName: "",
    fullName: "",
    tags: [],
    description: "",
    authorBio: "",
    authorPhoto: "",
    authorLogo: "",
    links: [{ text: "", url: "" }],
    public: false,
    id: "",
    ownerId: user.uid,
    editors: [],
    viewers: [],
    doctype: "pens",
    created: Date.now(),
    notes: [],
  });
  const [logo, setLogo] = useState(null);
  const [newTag, setNewTag] = useState("");
  const { getRootProps, getInputProps } = useImageUpload(setLogo);
  const [world, setWorld] = useState(
    location.state?.world
      ? location.state.world
      : {
          id: uuidv4(),
          ownerId: user.uid,
          title: "",
          plan: "",
          description: "",
          editors: [],
          viewers: [],
          public: false,
          pen: pen ? pen.id : "",
          doctype: "worlds",
          created: Date.now(),
          tags: [],
          schema: {
            title: "",
            description: "",
            types: [
              {
                label: "Characters",
                subtypes: [],
                attributes: [],
              },
              {
                label: "Locations",
                subtypes: [],
                attributes: [],
              },
            ],
          },
          notes: [],
          logo: null,
        }
  );

  useEffect(() => {
    if (world.pen === newPenId.current) {
      setIsNewPen(true);
      setNewPen({ ...newPen, id: newPenId.current });
      setWorld({ ...world, pen: newPenId.current });
    } else {
      setIsNewPen(false);
    }
  }, [world.pen]);

  const saveWorld = async () => {
    if (world.title.trim().length === 0) {
      setModal({
        visible: true,
        title: "Missing Title",
        message: "Please enter a title for your world.",
        confirm: () => setModal({ visible: false }),
      });
      return;
    }
    if (world.pen.trim().length === 0) {
      setModal({
        visible: true,
        title: "Missing Pen Name",
        message: "Please select a pen name for your world.",
        confirm: () => setModal({ visible: false }),
      });
      return;
    }
    let worldDoc = JSON.parse(JSON.stringify(world));
    if (isNewPen) {
      try {
        let penFullName = `${newPen.firstName}${
          newPen.lastName.trim().length > 0 ? ` ${newPen.lastName}` : ""
        }`;
        let penDoc = { ...newPen, fullName: penFullName };
        let res = await createScribiDocument(penDoc);
        if (res.isCreated) {
          worldDoc.pen = penDoc.id;
        }
      } catch (error) {
        console.log(error);
      }
    }
    if (logo) {
      let logoUrl = await ScribiRestService.uploadImage(logo);
      worldDoc.logo = logoUrl;
    }
    if (location.state?.world?.id) {
      try {
        let res = await updateScribiDocument(worldDoc);
        if (res.isUpdated) {
          setModal({
            visible: true,
            title: "World Updated",
            message: `${world.title} has been updated!`,
            confirm: () => {
              setModal({ visible: false });
              navigate("/");
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        let res = await createScribiDocument(worldDoc);
        if (res.isCreated) {
          setModal({
            visible: true,
            title: "World Created",
            message: `${world.title} has been created!`,
            confirm: () => {
              setModal({ visible: false });
              navigate("/");
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const addNote = () => {
    let newNotes = JSON.parse(JSON.stringify(notes));
    let newNote = {
      id: uuidv4(),
      ownerId: user.uid,
      content: "",
      doctype: "notes",
      created: Date.now(),
      parent: world.id,
    };
    newNotes.push(newNote);
    setNotes(newNotes);
    let newWorld = JSON.parse(JSON.stringify(world));
    newWorld.notes.push(newNote.id);
    setWorld(newWorld);
  };

  const deleteNote = (noteId) => {
    let newNotes = JSON.parse(JSON.stringify(notes));
    let noteIndex = newNotes.findIndex((note) => note.id === noteId);
    newNotes.splice(noteIndex, 1);
    setNotes(newNotes);
    let newWorld = JSON.parse(JSON.stringify(world));
    let noteIdIndex = newWorld.notes.findIndex((id) => id === noteId);
    newWorld.notes.splice(noteIdIndex, 1);
    setWorld(newWorld);
  };

  return (
    <BodyContainer>
      <FormContainer>
        <FormSection>
          <FormTitle>
            {worldId ? `Manage ${world.title}` : "Create World"}
          </FormTitle>
          <HrDivider />
          <FormGrid
            columns="min-content 1fr"
            style={{ alignItems: "flex-start" }}
          >
            <FormLabel>Story World Title:</FormLabel>
            <FormInput
              type="text"
              value={world.title}
              onChange={(e) => setWorld({ ...world, title: e.target.value })}
            />
            <FormLabel>Story World Plan:</FormLabel>
            <FormTextArea
              value={world.plan}
              onChange={(e) => setWorld({ ...world, plan: e.target.value })}
              placeholder="Your internal plan for this story world."
            />
            <FormLabel>Story World Description:</FormLabel>
            <FormTextArea
              value={world.description}
              onChange={(e) =>
                setWorld({ ...world, description: e.target.value })
              }
              placeholder="A reader-facing description of this story world."
            />
            <FormLabel>Tags:</FormLabel>
            <FormInput
              type="text"
              placeholder="Press enter to add a tag"
              value={newTag}
              onChange={(e) => setNewTag(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  const newTags = JSON.parse(JSON.stringify(world.tags));
                  newTags.push(newTag.toLowerCase());
                  setWorld({ ...world, tags: newTags });
                  setNewTag("");
                }
              }}
            />
            {world.tags.length > 0 && (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "5px",
                  gridColumn: "2",
                }}
              >
                {world.tags.map((tag, i) => (
                  <Tag
                    key={i}
                    tag={tag}
                    index={i}
                    state={world}
                    setState={setWorld}
                  />
                ))}
              </div>
            )}

            <HrDivider style={{ gridColumn: "1 / span 2" }} />
            <FormLabel>Under Pen Name:</FormLabel>
            <FormSelect
              value={world.pen}
              onChange={(e) => setWorld({ ...world, pen: e.target.value })}
            >
              <option value={""}>Select a Pen Name</option>
              {pens.map((pen) => (
                <option key={pen.id} value={pen.id}>
                  {pen.fullName}
                </option>
              ))}
              <option value={newPenId.current}>Create new Pen Name</option>
            </FormSelect>
            {isNewPen && (
              <>
                <FormLabel>Pen First Name:</FormLabel>
                <FormInput
                  type="text"
                  value={newPen.firstName}
                  onChange={(e) =>
                    setNewPen({ ...newPen, firstName: e.target.value })
                  }
                />
                <FormLabel>Pen Last Name:</FormLabel>
                <FormInput
                  type="text"
                  value={newPen.lastName}
                  onChange={(e) =>
                    setNewPen({ ...newPen, lastName: e.target.value })
                  }
                />
              </>
            )}
            <HrDivider style={{ gridColumn: "1 / span 2" }} />
            <FormLabel>World Notes:</FormLabel>
            {notes.map((note, index) => (
              <Note
                note={note}
                key={index}
                index={index}
                notes={notes}
                setNotes={setNotes}
                deleteNote={deleteNote}
              />
            ))}
            <IconButton
              onClick={() => {
                addNote();
              }}
              style={{
                justifySelf: "right",
                alignSelf: "center",
                gridColumn: 2,
              }}
              title="Add note to world"
            >
              <Icons.AddIcon />
            </IconButton>
            <HrDivider style={{ gridColumn: "1 / span 2" }} />
            <FormLabel>World Logo:</FormLabel>
            <DropArea
              {...getRootProps()}
              orientation="portrait"
              style={{ justifySelf: "center", alignSelf: "center" }}
            >
              <input {...getInputProps()} />
              {logo ? (
                <img src={logo} alt="World logo" />
              ) : world.logo ? (
                <img src={world.logo} alt="World Logo" />
              ) : (
                "Click to upload, or drop image here"
              )}
            </DropArea>
            <FormRow
              style={{ gridColumn: "1 / span 2", justifyContent: "right" }}
            >
              <ConfirmButton type="button" onClick={saveWorld}>
                Save
              </ConfirmButton>
              <CancelButton type="button" onClick={() => navigate("/")}>
                Cancel
              </CancelButton>
            </FormRow>
          </FormGrid>
        </FormSection>
      </FormContainer>
    </BodyContainer>
  );
};

export default ManageWorld;

function Note({ note, index, notes, setNotes, deleteNote }) {
  return (
    <div
      style={{
        gridColumn: 2,
        display: "grid",
        gridTemplateColumns: "1fr min-content",
        gap: "10px",
      }}
    >
      <FormTextArea
        value={note.content}
        onChange={(e) => {
          let newNotes = JSON.parse(JSON.stringify(notes));
          newNotes[index].content = e.target.value;
          setNotes(newNotes);
        }}
        placeholder="Note content"
      />
      <IconButton onClick={() => deleteNote(note.id)} title="Delete note">
        <Icons.DeleteIcon />
      </IconButton>
    </div>
  );
}
