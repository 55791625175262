/* eslint-disable no-unused-vars */
import { app, auth, storage, db } from "./cloud-configs";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";

const api = process.env.REACT_APP_CLOUD_FUNCTION_API_URL;

const createDocument = async (document) => {
  let token;
  try {
    token = await auth.currentUser.getIdToken();
  } catch (error) {
    throw error;
  }
  try {
    const res = await fetch(`${api}/createdocument`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ document }),
    });

    if (res.status !== 200) {
      const errorMessage = await res.text();
      const error = { message: errorMessage };

      throw error;
    }

    return res.json();
  } catch (error) {
    throw error;
  }
};

const readDocuments = async ({
  collection,
  queries,
  orderByField,
  orderByDirection,
  perPage,
  pageNumber,
}) => {
  try {
    const url = new URL(`${api}/${collection}`);

    for (const query of queries) {
      url.searchParams.append(query.field, query.value);
    }

    if (orderByField) {
      url.searchParams.append("orderByField", orderByField);
    }
    if (orderByDirection) {
      url.searchParams.append("orderByDirection", orderByDirection);
    }
    if (perPage) {
      url.searchParams.append("perPage", perPage);
    }
    if (pageNumber) {
      url.searchParams.append("pageNumber", pageNumber);
    }

    let token;

    try {
      token = await auth.currentUser.getIdToken();
    } catch (error) {
      //continue
    }

    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (res.status !== 200) {
      const errorMessage = await res.text();
      const error = { message: "I guess this is an error message." };
      return error;
    }

    return res.json();
  } catch (error) {}
};

const updateDocument = async (path, document) => {
  let token;
  try {
    token = await auth.currentUser.getIdToken();
  } catch (error) {
    alert(error.message);
    throw error;
  }

  try {
    const res = await fetch(`${api}/update`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ path: path, document: document }),
    });

    if (res.status !== 200) {
      const errorMessage = await res.text();
      const error = { message: errorMessage };

      throw error;
    }

    return res.json();
  } catch (error) {
    alert(error.data);
    throw error;
  }
};

const deleteDocument = async (collection, document) => {
  let token;

  try {
    token = await auth.currentUser.getIdToken();
  } catch (error) {
    alert(error.message);
    throw error;
  }

  try {
    const res = await fetch(`${api}/${collection}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(document),
    });

    if (res.status !== 200) {
      const errorMessage = await res.text();
      const error = { message: errorMessage };

      throw error;
    }
  } catch (error) {
    alert(error.message);
    throw error;
  }
};

const uploadImage = async (dataUrl) => {
  let token;

  try {
    token = await auth.currentUser.getIdToken();
  } catch (error) {
    alert(error.message);
    throw error;
  }

  try {
    const res = await fetch(`${api}/uploadimage`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ dataURL: dataUrl }),
    });

    if (res.status !== 200) {
      const errorMessage = await res.text();
      const error = { message: errorMessage };

      throw error;
    } else {
      return res.json();
    }
  } catch (error) {
    alert(error.message);
    throw error;
  }
};

const batchWrite = async (batch) => {
  let token;
  try {
    token = await auth.currentUser.getIdToken();
  } catch (error) {
    alert(error.message);
    throw error;
  }

  try {
    const res = await fetch(`${api}/batch-create`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ batch: batch }),
    });

    if (res.status !== 200) {
      const errorMessage = await res.text();
      const error = { message: errorMessage };

      throw error;
    }

    return res.json();
  } catch (error) {
    alert(error.message);
    throw error;
  }
};

const checkDisplayName = async (displayName) => {
  let token;
  try {
    token = await auth.currentUser.getIdToken();
  } catch (error) {
    alert(error.message);
    throw error;
  }

  try {
    const response = await fetch(`${api}/checkDisplayName/${displayName}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = await response.json();
    if (response.status === 200) {
      return data.isAvailable;
    } else {
      alert(`Error: ${data.message}`);
      throw new Error(data.message);
    }
  } catch (error) {
    alert(`Network Error: ${error.message}`);
    throw error;
  }
};

const deletePen = async (pen) => {
  let token;
  try {
    token = await auth.currentUser.getIdToken();
  } catch (error) {
    alert(error.message);
    throw error;
  }

  try {
    const res = await fetch(`${api}/deletepen`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ document: pen }),
    });

    if (res.status !== 200) {
      const errorMessage = await res.text();
      const error = { message: errorMessage };

      throw error;
    }
    return res.json();
  } catch (error) {
    alert(error.message);
    throw error;
  }
};

const checkLoginCode = async (code) => {
  try {
    const res = await fetch(`${api}/checklogincode/${code}`, {
      method: "GET",
    });

    if (res.status !== 200) {
      const errorMessage = await res.text();
      const error = { message: errorMessage };

      throw error;
    }
    return res.json();
  } catch (error) {
    throw error;
  }
};

const ScribiRestService = {
  createDocument,
  readDocuments,
  updateDocument,
  deleteDocument,
  uploadImage,
  batchWrite,
  checkDisplayName,
  deletePen,
  checkLoginCode,
};

export default ScribiRestService;
